import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Select, Button, FileUpload } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";
import { Table } from "antd";

import { IoTrashBin } from "react-icons/io5";

const ExpenseModal = ({ handleClose, inquiry, visible }) => {
  const inputDataStructure = {
    expenseType: {
      key: "expenseType",
      label: "Expense Type",
      data: "",
      optList: [],
      error: null,
    },

    amount: {
      key: "amount",
      label: "Amount (LKR)",
      data: "",
      type: "number",
      error: null,
    },

    date: {
      key: "date",
      label: "date",
      data: "",
      type: "date",
      error: null,
    },

    file: {
      key: "file",
      label: "Document of invoices",
      data: [],
      name: "file",
      error: null,
      required: true,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [clear, setClear] = useState(false);

  const [counter, setCounter] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [expences, setExpences] = useState([]);

  const [filterData, setFilterData] = useState(null);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleClearData = () => {
    inputs.expenseType.data = "";
    inputs.expenseType.placeHolder = "";
    inputs.amount.data = "";
    inputs.date.data = "";

    setClear(!clear);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const expenseTypes = await apiService.get("expense-type");

    setExpences(expenseTypes);

    const optList = expenseTypes.map((item) => {
      return { id: item.id, title: item.title };
    });

    input_list.expenseType.optList = optList;

    if (inquiry) {
      // input_list.title.data = inquiry.title;
    }
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddData = () => {
    let expenceTypeAddData = inputs.expenseType.data;
    let expenceAmountAddData = inputs.amount.data;
    let expenceDate = inputs.date.data;

    if (
      expenceTypeAddData !== "" ||
      expenceAmountAddData !== "" ||
      expenceDate !== ""
    ) {
      const data = {
        count: counter,
        expenseType: expenceTypeAddData,
        amount: expenceAmountAddData,
        date: expenceDate,
      };

      if (filterData != null) {
        setFilterData((prv) => [...prv, data]);
      } else {
        setFilterData([data]);
      }
      // clear inputs now
      handleClearData();
      setCounter((prv) => prv + 1);
    } else {
      throw Error("type Error");
    }
  };

  const handleName = (id) => {
    let expenceType = expences.find((elm) => elm.id === id);
    return expenceType.title;
  };

  const handleAction = (data) => {
    const handleDelete = () => {
      const updatedFilterData = filterData.filter(
        (item) => item.count !== data.count
      );
      setFilterData(updatedFilterData);
    };

    return (
      <button className="btn btn-ghost" onClick={handleDelete}>
        <IoTrashBin className="text-red-700" />
      </button>
    );
  };

  const columns = [
    {
      title: "expenceType",
      key: "expencetype",
      width: "100%",
      render: (arg) => <p>{handleName(arg.expenseType)}</p>,
    },
    {
      title: "Amount(LKR)",
      key: "amount",
      width: "100%",
      render: (arg) => <p>{arg.amount}</p>,
    },
    {
      title: "Date",
      key: "date",
      width: "100%",
      render: (arg) => <p>{arg.date}</p>,
    },
    {
      title: "",
      key: "action",
      render: (arg) => handleAction(arg),
    },
  ];

  const tBody = filterData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = new FormData();

    data.append("id", inquiry.id);
    data.append("expenceSiteData", JSON.stringify(filterData));


    if (inputs.file.data[0]) {
      data.append("file", inputs.file.data[0].data);
    } else {
      toast.error("Invoices Document is required");
      return;
    }

    try {
      setIsLoading(true);
      await apiService.patch("inquiry/expenses", data);
      toast.success("Inquiry updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handlethis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };
  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Add Site Expenses</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="flex flex-col mx-4">
          <form onSubmit={handlethis} encType="multipart/form-data">
            <div className="mt-2 mx-4">
              <Select
                clear={clear}
                input={inputs.expenseType}
                handleChange={handleChange}
              />

              <Input input={inputs.amount} handleChange={handleChange} />

              <Input input={inputs.date} handleChange={handleChange} />

              <Button
                handleClick={handleAddData}
                className="mt-6"
                text={"Import"}
              />
            </div>
            <br />
            <div className="w-full p-6 rounded-md border-2">
              <Table
                columns={columns}
                dataSource={tBody}
                style={{ width: "100%" }}
              />
            </div>
            <FileUpload input={inputs.file} handleChange={handleChange} />
            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Add"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default ExpenseModal;
