import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, ImgUpload } from "../../../../components/ui";
import LoadingAnimation from "../../../../components/Loader";

import { toast } from "react-toastify";
import apiService from "../../../../http";

function ApprovalLetterCeb({ handleClose, inquiry, visible }) {
  const inputDataStructure = {
    file: {
      key: "file",
      name: "file",
      label: "CEB Approval file",
      data: [],
      error: null,
      required: true,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleSubmit = async() => {
    const event = window.event;
    event.preventDefault();

    const data = new FormData();
    data.append("id", inquiry.id);

    if (inputs.file.data[0]) {
      data.append("file", inputs.file.data[0].data);
    } else {
      toast.error("File is required");
      return;
    }

    try {
      setIsLoading(true);
      await apiService.patch("inquiry/approval", data);
      toast.success("Approval updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }


  };

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">CEB Aproval</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        {false && <LoadingAnimation />}
        <div className="mt-2 mx-4">
          <form onSubmit={handleThis} encType="multipart/form-data">
            <ImgUpload
              input={inputs.file}
              handleChange={handleChange}
              multiple
            />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default ApprovalLetterCeb;
