import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import ExpenseTypeModal from "./ExpenseTypeModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import LoadingAnimation from "../../../components/Loader";
import { useSelector } from "react-redux";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
const ExpenseTypes = () => {
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataModalState, setDataModalState] = useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState(inputDataStructure);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";

  const handleEdit = (expenseType) => {
    setSelectedExpenseType(expenseType);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("expense-type/" + selectedExpenseType.id);
      setConfirmModalState(false);
      refreshExpenseTypes();
      setSelectedExpenseType(null);
      toast.success("ExpenseType deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshExpenseTypes();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedExpenseType(null);
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(expenseTypes);
      } else {
        const data = expenseTypes.filter((item) => {
          const lowerCaseTitle = item.title.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [expenseTypes, inputs]
  );

  const refreshExpenseTypes = async () => {
    try {
      setIsLoading(true);
      const expenseTypes = await apiService.get("expense-type");
      setExpenseTypes(expenseTypes);
      setFilteredData(expenseTypes);
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshExpenseTypes();
  }, []);

  //   const status = (item) => {
  //     let status;

  //     if (item.status) {
  //       status = "Active";
  //     } else {
  //       status = "Inactive";
  //     }

  //     return (
  //       <span
  //         onClick={async () => {
  //           try {
  //             await apiService.patch(`expense-type/${item.id}`);
  //             refreshExpenseTypes();
  //           } catch (e) {
  //             console.log(e);
  //           }
  //         }}
  //         className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
  //       >
  //         {status}
  //       </span>
  //     );
  //   };

  const actionBtns = (expenseType) => {
    return (
      <div className="flex">
        <Button
          text="Delete"
          disabled={!(isOffice || isSupervisor) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedExpenseType(expenseType);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          disabled={!(isOffice || isSupervisor) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedExpenseType(expenseType);
            handleEdit(expenseType);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "expenseTypeId",
      key: "expenseTypeId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.expenseTypeId.localeCompare(b.expenseTypeId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "20%",
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <ExpenseTypeModal
          selectedExpenseType={selectedExpenseType}
          handleClose={handleCloseModal}
          visible={dataModalState}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            disabled={!(isOffice || isSupervisor) ? false : true}
            text={"Add New ExpenseType"}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default ExpenseTypes;
