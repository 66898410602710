import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logout, Login, Dashboard } from "../pages";
import { userLoggedOut } from "../redux/actions/userActions";

const Router = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const PrivateRouter = () => {
    if (user.authenticated) {
      const currentTime = Date.now();
      const expTime = user.data.exp * 1000;

      if (expTime < currentTime) {
        dispatch(userLoggedOut());

        return <Navigate to="/login" />;
      }

      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  };
  const PreAuthRouter = () => {
    if (user.authenticated) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PreAuthRouter />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PrivateRouter />}>
          <Route path="/" element={<Dashboard section={"home"} />} />

          <Route
            path="/materials"
            element={<Dashboard section={"materials"} />}
          />
          <Route
            path="/inquiries"
            element={<Dashboard section={"inquiries"} />}
          />
          <Route
            path="/inquiry/:id"
            element={<Dashboard section={"inquiry"} />}
          />
          <Route
            path="/otherjobs"
            element={<Dashboard section={"otherJobs"} />}
          />
          <Route
            path="/otherjobs/:id"
            element={<Dashboard section={"otherJobsData"} />}
          />
          <Route path="/jobs" element={<Dashboard section={"jobs"} />} />
          <Route
            path="/suppliers"
            element={<Dashboard section={"suppliers"} />}
          />
          <Route
            path="/suppliers/:id"
            element={<Dashboard section={"supplierData"} />}
          />
          <Route
            path="/supervisors"
            element={<Dashboard section={"supervisors"} />}
          />
          <Route
            path="/contractors"
            element={<Dashboard section={"contractors"} />}
          />
          <Route
            path="/customers"
            element={<Dashboard section={"customers"} />}
          />
          <Route
            path="/joboutstanding/:id"
            element={<Dashboard section={"joboutstanding"} />}
          />
          <Route
            path="/otherjoboutstanding/:id"
            element={<Dashboard section={"otherjoboutstanding"} />}
          />
          <Route path="/pos" element={<Dashboard section={"pos"} />} />
          <Route path="/sales" element={<Dashboard section={"sales"} />} />
          <Route
            path="/payments"
            element={<Dashboard section={"payments"} />}
          />
          <Route
            path="/expense-types"
            element={<Dashboard section={"expense-types"} />}
          />
          <Route
            path="/expenses"
            element={<Dashboard section={"expenses"} />}
          />
          <Route
            path="/outstanding/:id"
            element={<Dashboard section={"outstanding"} />}
          />
          <Route
            path="/maintance"
            element={<Dashboard section={"maintance"} />}
          />
          <Route
            path="/maintance/:id"
            element={<Dashboard section={"maintanceData"} />}
          />
          <Route
            path="/expensedata/:id"
            element={<Dashboard section={"expencedata"} />}
          />

          <Route
            path="/settings"
            element={<Dashboard section={"settings"} />}
          />
          <Route
            path="/reports/*"
            element={<Dashboard section={"reports"} />}
          />
          <Route path="/userroles" element={<Dashboard section={"users"} />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/*" element={<Navigate to={"/"} replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
