import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";
import { Button, Input, Select } from "../../../../components/ui";

import { IoTrashBin } from "react-icons/io5";

function ReturnMaterialModel({ handleClose, inquiry, visible }) {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      optTitle: "a material",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      data: "",
      type: "number",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [clear, setClear] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [materials, setMaterials] = useState(null);
  const [err, setErr] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleClearData = () => {
    inputs.material.data = "";
    inputs.material.placeHolder = "";
    inputs.quantity.data = "";

    handleChange(inputs.material);
    setClear(!clear);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const materialsData = await apiService.get("material");

    setMaterials(materialsData);

    const matOptList = materialsData.map((material) => {
      return {
        id: material.id,
        title: `${material.materialId} - ${material.title}`,
      };
    });

    input_list.material.optList = matOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifiedData = inquiry.bomMaterials.map((itemOBJ) => {
    const matItemOBJArray = itemOBJ.materials;
    const data = matItemOBJArray.map((itemObj2) => {
      const MaterialID = itemObj2.material;
      const itemQTY = itemObj2.quantity;

      const selectedMat =
        materials &&
        materials?.find((item) => {
          return item.id === MaterialID;
        });

      return {
        materialName: selectedMat?.title,
        matId: selectedMat?.id,
        materialId: selectedMat?.materialId,
        unit: selectedMat?.unit,
        qty: itemQTY,
        totalPrice: parseFloat(selectedMat?.unitPrice) * parseFloat(itemQTY),
      };
    });

    const data2 = [...data];
    return data2;
  });

  const updatedData = modifiedData.flat();

  const result = updatedData.reduce((acc, curr) => {
    const existingItemIndex = acc.findIndex(
      (item) => item.matId === curr.matId
    );

    if (existingItemIndex !== -1) {
      acc[existingItemIndex].qty = (
        parseInt(acc[existingItemIndex].qty) + parseInt(curr.qty)
      ).toString();
      acc[existingItemIndex].totalPrice += curr.totalPrice;
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  // const result = Object.values(
  //   updatedData.reduce((acc, curr) => {
  //     const existingItem = acc.find((item) => item.matId === curr.matId);
  //     if (existingItem) {
  //       existingItem.qty = (
  //         parseInt(existingItem.qty) + parseInt(curr.qty)
  //       ).toString();
  //       existingItem.totalPrice += curr.totalPrice;
  //     } else {
  //       acc.push({ ...curr });
  //     }
  //     return acc;
  //   }, [])
  // );

  const tBody = result?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tFilteredBody = filterData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleName = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material?.title;
  };

  const handleAction = (data) => {
    const handleDelete = () => {
      const updatedFilterData = filterData.filter(
        (item) => item.material !== data.material
      );
      setFilterData(updatedFilterData);
    };

    return (
      <button className="btn btn-ghost" onClick={handleDelete}>
        <IoTrashBin className="text-red-700" />
      </button>
    );
  };

  const columns = [
    {
      title: "Material",
      key: "material",
      dataIndex: "materialName",
      width: "100%",
    },
    {
      title: "Unit",
      key: "unit",
      width: "100%",
      dataIndex: "unit",
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "qty",
      width: "40%",
    },
    {
      title: "Total (LKR)",
      key: "total",
      width: "100%",
      dataIndex: "totalPrice",
    },
  ];
  const ReturnItemColumns = [
    {
      title: "Material",
      key: "material",
      width: "100%",
      render: (arg) => <p>{handleName(arg?.material)}</p>,
    },
    {
      title: "Quantity",
      key: "quantity",
      width: "100%",
      render: (arg) => <p>{arg?.quantity}</p>,
    },
    {
      title: "",
      key: "action",
      render: (arg) => handleAction(arg),
    },
  ];

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      materialData: filterData,
      id: inquiry.id,
    };

    try {
      setIsLoading(true);
      await apiService.patch("inquiry/return-materials", data);
      toast.success("Materials updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleAddData = () => {
    let materialAdd = inputs.material.data;
    let quantityAdd = inputs.quantity.data;

    if (materialAdd !== "" || quantityAdd !== "") {
      const selectedMaterial = result.find(
        (item) => item.matId === materialAdd
      );

      // material is already added, only increase quantity
      // else add with the rest

      if (selectedMaterial) {
        const data = {
          material: materialAdd,
          quantity: quantityAdd,
        };

        if (filterData != null) {
          if (parseFloat(quantityAdd) > parseFloat(selectedMaterial.qty)) {
            setErr(true);
            return;
          } else {
            setErr(false);
          }

          const alreadyAvailableData = filterData.find(
            (materialData) => materialData.material === data.material
          );

          if (alreadyAvailableData) {
            if (
              alreadyAvailableData.quantity > parseFloat(selectedMaterial.qty)
            ) {
              setErr(true);
              return;
            } else {
              setErr(false);
            }
            const newQty =
              parseFloat(alreadyAvailableData.quantity) +
              parseFloat(data.quantity);
            alreadyAvailableData.quantity = newQty;
            setFilterData((prv) => [...prv]);
          } else {
            setFilterData((prv) => [...prv, data]);
          }
        } else {
          setFilterData([data]);
        }

        // clear inputs now
        handleClearData();
      } else {
        setErr(true);
        return;
      }
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading | err ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all w-[60%] mx-auto mt-[100px] rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Return material</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="flex flex-col p-2">
          <div className="mt-2 mx-4 gap-2">
            <div className="flex justify-between items-center">
              <p>Inquery Number</p>
              <div className="text-red-500 font-bold">{inquiry.inquiryId}</div>
            </div>
            <div className="flex items-center font-bold text-xl my-4">
              <p>Materials Data</p>
            </div>
          </div>

          <br />
          <div className="w-full px-6">
            <Table
              columns={columns}
              dataSource={tBody}
              style={{ width: "100%" }}
            />
          </div>
          <br></br>
          <Select clear={clear} input={inputs.material} handleChange={handleChange} />
          <Input input={inputs.quantity} handleChange={handleChange} />
          <Button
            handleClick={handleAddData}
            className="mt-6"
            text={"Import"}
          />
          {err && (
            <div className="text-red-500 font-bold">
              Please choose a valid data to add
            </div>
          )}
          <div className="w-full px-6 py-2">
            <Table
              columns={ReturnItemColumns}
              dataSource={tFilteredBody}
              style={{ width: "100%" }}
            />
          </div>
          <div className="flex items-end w-full">
            <Button
              handleClick={handleSubmit}
              className="mt-6 w-full"
              text={"Return Items"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default ReturnMaterialModel;
