import React from "react";

import CompanyLogo from "../../assets/img/logo2.png";

function Logo() {
  return (
    <div className="cursor-pointer w-full">
      <div className="flex">
        <div className="rounded  p-4">
          <img
            className="object-cover "
            src={CompanyLogo}
            alt="Igrid Holdings"
            width={"240px"}
            height={"240px"}
          />
        </div>
      </div>
    </div>
  );
}

export default Logo;
