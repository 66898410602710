import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";

import { Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import { useNavigate } from "react-router-dom";
import OtherJobModel from "./OtherJobModel";
import LoadingAnimation from "../../../components/Loader";
import { useSelector } from "react-redux";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
const OtherJobs = () => {
  const [otherJobs, setOtherJobs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState(inputDataStructure);

  const [otherJobModalState, setOtherJobModelState] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";


  const handleCloseModal = () => {
    setOtherJobModelState(false);
    refreshInquiries();
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(otherJobs);
      } else {
        const data = otherJobs.filter((item) => {
          const lowerCaseTitle = item.otherJobId.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, otherJobs]
  );

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const otherJobData = await apiService.get("other-jobs");

      setOtherJobs(otherJobData);
      setFilteredData(otherJobData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshInquiries();
  }, []);

  const actionBtns = (otherJob) => {
    const status = otherJob.otherJobStatus;

    const disabled =
      (status === "Canceled") | (status === "Finished") ? true : false;

    return (
      <div className="flex items-center flex-wrap">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            navigate(`/otherjobs/${otherJob._id}`);
          }}
        />
        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(otherJob);
            setOtherJobModelState(true);
          }}
          disabled={!(disabled || isSupervisor || isOffice) ? false : true}
        />
      </div>
    );
  };

  const status = (name) => {
    const className =
      name === "Canceled"
        ? "badge bg-red-500 p-4 textarea-md text-white"
        : name === "stopped"
        ? "badge bg-secondary p-4 textarea-md text-white"
        : name === "Finished"
        ? "badge bg-primary p-4 textarea-md text-white"
        : "badge bg-green-500 p-4 textarea-md text-white";

    return <span className={className}>{name}</span>;
  };

  const columns = [
    {
      title: "OtherJob ID",
      dataIndex: "otherJobId",
      key: "otherJobId",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.otherJobId.localeCompare(b.otherJobId),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      align: "center",
      width: "10%",
      render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },

    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      width: "25%",
      // sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Status",
      dataIndex: "otherJobStatus",
      align: "center",
      width: "25%",
      render: (args) => status(args),
      // sorter: (a, b) => a.stock - b.stock,
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {otherJobModalState && (
        <OtherJobModel
          visible={otherJobModalState}
          selectedOtherJob={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"New OtherJob"}
            disabled={!(isSupervisor || isOffice )? false : true}
            handleClick={() => setOtherJobModelState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default OtherJobs;
