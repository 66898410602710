import React, { useCallback, useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import {
  Button,
  FileUpload,
  ImgUpload,
  Input,
} from "../../../../../../components/ui";
import apiService from "../../../../../../http";

function QutationModel({ handleClose, otherJob, visible }) {
  const inputDataStructure = {
    amount: {
      key: "amount",
      label: "Amount (LKR)",
      data: "",
      type: "number",
      min: 0,
      error: null,
    },
    advance: {
      key: "advance",
      label: "Advance Amount (LKR)",
      data: "",
      type: "number",
      min: 0,
      error: null,
    },

    file: {
      key: "file",
      label: "Quotation",
      data: [],
      name: "file",
      error: null,
    },
    idImages: {
      key: "idImages",
      label: "ID Images",
      data: [],
      multiple: true,
      name: "idImages",
      error: null,
      required: true,
    },
    bankdoc: {
      key: "bankdoc",
      label: "Bank Document",
      data: [],
      name: "bankdoc",
      error: null,
    },
    others: {
      key: "others",
      label: "Others",
      data: [],
      name: "others",
      multiple: true,
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const getData = useCallback(() => {
    let input_list = { ...inputs };

    input_list.amount.data = otherJob.quotationAmount;
    input_list.advance.data = otherJob.discountedAmount ?? 0;
    setInputs(input_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = new FormData();
    data.append("amount", inputs.amount.data - inputs.advance.data);
    data.append("advance", inputs.advance.data);
    data.append("id", otherJob._id);

    if (inputs.file.data[0]) {
      data.append("file", inputs.file.data[0].data);
    } else {
      toast.error("Quotation is required");
      return;
    }

    if (inputs.bankdoc.data[0]) {
      data.append("bankdoc", inputs.bankdoc.data[0].data);
    }

    if (inputs.idImages.data.length > 0) {
      for (const single_file of inputs.idImages.data) {
        data.append("idImages", single_file.data);
      }
    }
    if (inputs.others.data.length > 0) {
      for (const single_file of inputs.others.data) {
        data.append("others", single_file.data);
      }
    }

    try {
      setIsLoading(true);
      await apiService.patch("other-jobs/quotation", data);
      toast.success("Quotation updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Upload quotation</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <form onSubmit={handleThis} encType="multipart/form-data">
            <Input input={inputs.amount} handleChange={handleChange} />
            <Input input={inputs.advance} handleChange={handleChange} />
            <div className="font-bold flex flex-row gap-2">
              Grand Total :{" "}
              <span className="text-blue-900">
                {inputs.amount.data - inputs.advance.data} LKR
              </span>
            </div>

            <FileUpload
              input={inputs.file}
              handleChange={handleChange}
              multiple
            />

            <ImgUpload input={inputs.idImages} handleChange={handleChange} />
            <ImgUpload input={inputs.bankdoc} handleChange={handleChange} />
            <ImgUpload input={inputs.others} handleChange={handleChange} />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default QutationModel;
