import React, { useState } from "react";
import { Input, Button } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";

const JobModal = ({ handleClose, selectedJob }) => {
    const inputDataStructure = {
        quantity: {
            key: "quantity",
            label: "Number of units",
            required: true,
            min: 1,
            data: 1,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            id: selectedJob.id,
            quantity: inputs.quantity.data,
        };

        try {
            await apiService.patch("job/finish", formData);
            toast.success("job exported to the stock successfully!");
            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                <div className="transform shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Export job</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4">
                        <Input
                            input={inputs.quantity}
                            handleChange={handleChange}
                        />
                        <Button
                            className={"w-full"}
                            text={"Export"}
                            handleClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobModal;
