import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import apiService from "../../../../http";
import {  Select } from "../../../../components/ui";
import LoadingAnimation from "../../../../components/Loader";
import Logo from "../../../../components/logo/Logo";

const inputDataStructure = {
  customer: {
    key: "customer",
    label: "Select Customer",
    optTitle: "",
    data: null,
    // optList: [],
    required: true,
    type: "text",
    error: null,
  },
  job: {
    key: "job",
    label: "Select Job",
    optTitle: "",
    data: null,
    // optList: [],
    required: true,
    type: "text",
    error: null,
  },
  otherjob: {
    key: "otherjob",
    label: "Select OtherJob",
    optTitle: "",
    data: null,
    // optList: [],
    required: true,
    type: "text",
    error: null,
  },
  startDate: {
    key: "startDate",
    label: "Start Date",
    data: "",
    type: "date",
    error: null,
  },
  endDate: {
    key: "endDate",
    label: "End Date",
    data: "",
    type: "date",
    error: null,
  },
};
const metadata = {
  CUSTOMER_OUTSTANDING: "Customer Outstanding",
  JOB_OUTSTANDING: "Job Outstanding",
  OTHER_JOB_OUTSTANDING: "OtherJob Outstanding",
  // FILTER_BY_DATE: "Filter By Date",
};

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const radioBtnOptions = [
  metadata.CUSTOMER_OUTSTANDING,
  metadata.JOB_OUTSTANDING,
  metadata.OTHER_JOB_OUTSTANDING,
  // metadata.FILTER_BY_DATE,
];

const TableData = ({ dataSource }) => {
  const TableHeader = () => {
    return (
      <div className=" flex items-center justify-between gap-2">
        <h2 className="font-black text-black text-xl">
          IGRID HOLDINGS (PVT) LTD
        </h2>
        <div className="items-start font-bold">Outstanding Sheet</div>
      </div>
    );
  };

  const HandleTHeaderData = () => {
    return (
      <thead className="bg-slate-200 font-bold">
        <tr>
          <th>Id</th>
          <th>description</th>
          <th></th>
          <th>Amount (LKR)</th>
        </tr>
      </thead>
    );
  };

  const HandleTBodyData = () => {
    return (
      <tbody className="text-wrap items-center">
        {dataSource.map((itemObj) => {
          const poId = itemObj.id;
          const payments = itemObj.outstandingAmount;

          return (
            <Fragment key={itemObj.key}>
              <tr className="bg-yellow-200">
                <td>{poId}</td>
                <td colSpan={4}></td>
              </tr>

              <tr>
                <td></td>
                <td>Outstanding Amount</td>
                <td></td>
                <td>{payments}</td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    );
  };

  const HandleTableBodyData = () => {
    return (
      <div className="content">
        <table className="w-[700px]">
          <HandleTHeaderData />
          <HandleTBodyData />
        </table>
      </div>
    );
  };

  return (
    <table className="table w-[700px]">
      <thead>
        <tr>
          <th>
            <TableHeader />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <HandleTableBodyData />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function OutstandingReport() {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();

  const [filterType, setFilterType] = useState(metadata.CUSTOMER_OUTSTANDING);

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const customerData = await apiService.get("customer");
      const inquiryData = await apiService.get("inquiry");
      const otherJobData = await apiService.get("other-jobs");

      let input_list = { ...inputs };

      const custOptList = customerData.map((sup) => {
        return {
          id: sup.id,
          title: `${sup.title}`,
        };
      });

      const inqOptList = inquiryData.map((inq) => {
        return {
          id: inq.id,
          title: `${inq.inquiryId}`,
        };
      });

      const oJobOptList = otherJobData.map((inq) => {
        return {
          id: inq._id,
          title: `${inq.otherJobId}`,
        };
      });

      input_list.customer.optList = custOptList;
      input_list.job.optList = inqOptList;
      input_list.otherjob.optList = oJobOptList;
      setInputs(input_list);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const calculateOutstandingAmount = (
    qotAmount,
    confAmount,
    totalAdvAmount
  ) => {
    const totalPayable = qotAmount + confAmount - totalAdvAmount;
    return totalPayable;
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      setInputs(input_list);
    },
    [inputs]
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "LKR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    refreshInquiries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handlecusOutstanding = async (cusId) => {
    const inqList = await apiService.get(
      `inquiry/viewoutstandingbysupplier/${cusId}`
    );
    const ojList = await apiService.get(
      `other-jobs/viewoutstandingbysupplier/${cusId}`
    );
    const updatedinqList = ojList.map((ojItemObj) => {
      const quotAmount = ojItemObj.quotationAmount;
      const confAmount = ojItemObj.paidAmount;

      const apaid = ojItemObj.advancedPaidAmount;
      const aestpaid = ojItemObj.advancedestimatedChargesPaidAmount;
      const aclearpaid = ojItemObj.advancedClearancePaidAmount;
      const acebpaid = ojItemObj.advancedClearancePaidAmount;

      const totalaPaid = apaid + aestpaid + aclearpaid + acebpaid;

      return {
        id: ojItemObj.otherJobId,
        outstandingAmount: formatter.format(
          calculateOutstandingAmount(quotAmount, confAmount, totalaPaid)
        ),
      };
    });
    const updatedojList = inqList.map((inqItemObj) => {
      const quotAmount = inqItemObj.quotationAmount;
      const confAmount = inqItemObj.paidAmount;

      const apaid = inqItemObj.advancedPaidAmount;
      const aestpaid = inqItemObj.advancedestimatedChargesPaidAmount;
      const aclearpaid = inqItemObj.advancedClearancePaidAmount;
      const acebpaid = inqItemObj.advancedClearancePaidAmount;

      const totalaPaid = apaid + aestpaid + aclearpaid + acebpaid;

      return {
        id: inqItemObj.inquiryId,
        outstandingAmount: formatter.format(
          calculateOutstandingAmount(quotAmount, confAmount, totalaPaid)
        ),
      };
    });

    setFilteredData([...updatedinqList, ...updatedojList]);
  };

  const handleJobOutstandings = async (jobId) => {
    const inqData = await apiService.get(`inquiry/${jobId}`);

    const quotAmount = inqData.quotationAmount;
    const confAmount = inqData.paidAmount;

    const apaid = inqData.advancedPaidAmount;
    const aestpaid = inqData.advancedestimatedChargesPaidAmount;
    const aclearpaid = inqData.advancedClearancePaidAmount;
    const acebpaid = inqData.advancedClearancePaidAmount;

    const totalaPaid = apaid + aestpaid + aclearpaid + acebpaid;

    const jobData = {
      id: inqData.inquiryId,
      outstandingAmount: formatter.format(
        calculateOutstandingAmount(quotAmount, confAmount, totalaPaid)
      ),
    };
    setFilteredData([jobData]);
  };

  const handleOjPayments = async (ojId) => {
    const ojData = await apiService.get(`other-jobs/${ojId}`);

    const quotAmount = ojData.quotationAmount;
    const confAmount = ojData.paidAmount;

    const apaid = ojData.advancedPaidAmount;
    const aestpaid = ojData.advancedestimatedChargesPaidAmount;
    const aclearpaid = ojData.advancedClearancePaidAmount;
    const acebpaid = ojData.advancedClearancePaidAmount;

    const totalaPaid = apaid + aestpaid + aclearpaid + acebpaid;

    const ojjobData = {
      id: ojData.otherJobId,
      outstandingAmount: formatter.format(
        calculateOutstandingAmount(quotAmount, confAmount, totalaPaid)
      ),
    };

    setFilteredData([ojjobData]);
  };

  const handlepaymentsbyDateRange = async (startDate, endDate) => {
    try {
      const paymentList = await apiService.get(
        `payment/viewoutstandingPayment`
      );
      const poList = await apiService.get(`payment/viewoPOList`);

      const updatedoutPayList = paymentList
        .map((outPayItemOBJ) => {
          const poData = outPayItemOBJ.poData[0];

          const paidDate = new Date(outPayItemOBJ.paiddate);

          const data = {
            id: outPayItemOBJ.outstandingPaymentId,
            paidAmount: outPayItemOBJ.paidamount,
            payType: "CASH",
            outId: outPayItemOBJ.outstandingId,
            date: paidDate,
            poId: poData ? poData.supBasedPOId : null, // Safely handle missing `poData`
          };

          // Correct date comparison
          if (paidDate >= startDate && paidDate <= endDate) {
            return data;
          }

          return null;
        })
        .filter(Boolean); // Filter out null values

      const updatedpoList = poList
        .map((payItemOBJ) => {
          const payData = payItemOBJ.payData[0];

          const paidDate = new Date(payData.createdAt);

          const data = {
            id: payData.paymentId,
            paidAmount: payData.paymentData.paidamount,
            payType: payData.type.toUpperCase(),
            date: paidDate,
            poId: payItemOBJ.supBasedPOId,
          };

          // Correct date comparison
          if (paidDate >= startDate && paidDate <= endDate) {
            return data;
          }

          return null;
        })
        .filter(Boolean); // Filter out null values

      // Merge both lists and filter out any remaining nulls
      const updatedPaymentList = [...updatedoutPayList, ...updatedpoList];

      // Grouping payments by poId
      const groupedPayments = updatedPaymentList.reduce((acc, payment) => {
        const { poId, ...otherData } = payment;

        if (!acc[poId]) {
          acc[poId] = { poId, payments: [] };
        }

        acc[poId].payments.push(otherData);

        return acc;
      }, {});

      const result = Object.values(groupedPayments);

      setFilteredData(result);
    } catch (error) {
      console.error("Error fetching payments data:", error);
    }
  };

  const handleViewData = () => {
    if (filterType === metadata.CUSTOMER_OUTSTANDING) {
      const supId = inputs.customer.data;
      if (supId != null) {
        handlecusOutstanding(supId);
      }
    }
    if (filterType === metadata.JOB_OUTSTANDING) {
      const supId = inputs.job.data;
      if (supId != null) {
        handleJobOutstandings(supId);
      }
    }
    if (filterType === metadata.OTHER_JOB_OUTSTANDING) {
      const supId = inputs.otherjob.data;
      if (supId != null) {
        handleOjPayments(supId);
      }
    }

    if (filterType === metadata.FILTER_BY_DATE) {
      const startDate = new Date(inputs.startDate.data);
      const endDate = new Date(inputs.endDate.data);

      if (startDate && endDate) {
        handlepaymentsbyDateRange(startDate, endDate);
      }
    }
  };

  const HandleFilterOption = () => {
    return (
      <div>
        {filterType === metadata.CUSTOMER_OUTSTANDING ? (
          <div>
            <Select input={inputs.customer} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.JOB_OUTSTANDING ? (
          <div>
            <Select input={inputs.job} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.OTHER_JOB_OUTSTANDING ? (
          <div>
            <Select input={inputs.otherjob} handleChange={handleChange} />
          </div>
        )
        //  : filterType === metadata.FILTER_BY_DATE ? (
        //   <div className="flex flex-col items-center">
        //     <Input input={inputs.startDate} handleChange={handleChange} />
        //     <Input input={inputs.endDate} handleChange={handleChange} />
        //   </div>
        // )
         : (
          <div></div>
        )}
      </div>
    );
  };

  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">OUTSTANDING REPORT</p>
              <div className="mt-4">
                {/* radio buttons goes here */}
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>
          <div
            id="tableData"
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableData dataSource={tBody} />
          </div>
        </div>
      )}
    </section>
  );
}

export default OutstandingReport;
