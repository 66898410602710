import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Input } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";

function PayOutstandingModule({ selectedMaterial, handleClose, visible }) {
  const inputDataStructure = {
    invoicenumber: {
      key: "invoicenumber",
      label: "Supplier Invoice Number",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    paidamount: {
      key: "paidamount",
      label: "Paid Amount",
      data: "",
      required: true,
      type: "number",
      error: null,
    },
    paiddate: {
      key: "paiddate",
      label: "Paid Date",
      data: "",
      required: true,
      type: "Date",
      error: null,
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(inputDataStructure);

  const payOut = selectedMaterial.payout[0];
  const payData = selectedMaterial.payData[0];

  const handleChange = (input) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (parseFloat(~~inputs.paidamount.data) <= 0) {
      toast.error("Please enter a valid amount");
      return;
    }

    try {
      const data = {
        purchsingOrderId: selectedMaterial.purchsingOrderId,
        paymentId: payData.paymentId,
        outstandingId: payOut.outstandingId,
        outstandingAmount: payOut.outstandingAmount,
        paidamount: inputs.paidamount.data,
        paiddate: inputs.paiddate.data,
        invoicenumber: inputs.invoicenumber.data,
      };

      setIsLoading(true);
      await apiService.post("payment/payoutstanding", data);
      toast.success("Outstanding Payment updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (err) {
      setIsLoading(false);
      toast.error(err);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Supplier Outstanding Payment</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <div className="flex flex-col items-start py-2">
            <Input input={inputs.invoicenumber} handleChange={handleChange} />
          </div>
          <div className="flex items-start justify-between p-2 border rounded-lg border-black">
            <div className="font-medium text-lg">Net Total:</div>
            <div>
              {" "}
              {payOut.outstandingAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "LKR",
              })}
            </div>
          </div>
          <div className="flex flex-col items-start justify-center py-2">
            <Input input={inputs.paidamount} handleChange={handleChange} />
            <Input input={inputs.paiddate} handleChange={handleChange} />
          </div>

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={"Submit"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default PayOutstandingModule;
