import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../http";

import { toast } from "react-toastify";
import { Button, TextArea } from "../../../../components/ui";
import { FaCommentDots } from "react-icons/fa";

function JobRemarksModel({
  handleClose,
  selectedJob,
  handleRefresh,
  refresh,
  visible,
}) {
  const inputDataStructure = {
    remark: {
      key: "remark",
      label: "Remarks",
      optTitle: "Remarks",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (input) => {
    let input_list = { ...input };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildData = () => {
    let input_list = { ...inputs };

    if (selectedJob) {
      input_list.remark.optList = selectedJob.remarks;
    }

    setInputs(input_list);
  };

  useEffect(() => {
    buildData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const date = new Date();

    const data = {
      remarks: inputs.remark?.data,
      timeStamp: date.toISOString().split("T")[0],
      id: selectedJob.id,
    };

    try {
      setIsLoading(true);
      await apiService.post("job/remarks", data);
      setIsLoading(false);
      handleRefresh(!refresh);
      handleClose();
    } catch (err) {
      toast.error(err);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">New Remarks</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <div className="max-h-[40vh] gap-2 overflow-y-auto flex flex-col">
            {selectedJob.remarks.map((item, index) => {
              const date = new Date(item.timeStamp);
              return (
                <div
                  key={index}
                  className="flex flex-col gap-2 items-start rounded-sm border-gray-600 bg-slate-300"
                >
                  <div className="p-2 my-2 flex gap-2 text-start font-bold">
                    <FaCommentDots />
                    {item.remarks}
                  </div>
                  {date.toLocaleDateString()}
                </div>
              );
            })}
          </div>
          <div className="flex gap-4 items-center justify-between mx-4">
            <TextArea input={inputs.remark} handleChange={handleChange} />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Add"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default JobRemarksModel;
