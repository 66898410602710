import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import SupplierModal from "./SupplierModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import LoadingAnimation from "../../../components/Loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataModalState, setDataModalState] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";

  const handleEdit = (supplier) => {
    setSelectedSupplier(supplier);
    setDataModalState(true);
  };

  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await apiService.delete("supplier/" + selectedSupplier.id);
      setConfirmModalState(false);
      refreshSuppliers();
      setSelectedSupplier(null);
      toast.success("Supplier deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshSuppliers();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedSupplier(null);
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(suppliers);
      } else {
        const data = suppliers.filter((item) => {
          const lowerCaseTitle = item.title.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, suppliers]
  );

  const refreshSuppliers = async () => {
    try {
      setIsLoading(true);
      const suppliers = await apiService.get("supplier");
      setSuppliers(suppliers);
      setFilteredData(suppliers);
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshSuppliers();
  }, []);

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`supplier/${item.id}`);
            refreshSuppliers();
          } catch (e) {
            console.log(e);
          }
        }}
        className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (supplier) => {
    return (
      <div className="wrap">
        <Button
          text="Delete"
          disabled={!(isSupervisor || isOffice) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          disabled={!(isSupervisor || isOffice) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            handleEdit(supplier);
          }}
        />
        <Button
          text="View PO Data"
          disabled={!(isSupervisor || isOffice) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            navigate(`/suppliers/${supplier.id}`);
          }}
        />
        <Button
          text="Outstandings"
          disabled={!(isSupervisor || isOffice) ? false : true}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            navigate(`/outstanding/${supplier.id}`);
          }}
        />
      </div>
    );
  };

  //   const specifications = (specs) => {
  //     const classes =
  //       "text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg";

  //     return (
  //       <div className="flex flex-col gap-2 items-start">
  //         {specs.thickness && (
  //           <p className={classes}>Thickness: {specs.thickness} mm</p>
  //         )}
  //         {specs.length && <p className={classes}>Length: {specs.length} ft</p>}
  //         {specs.width && <p className={classes}>Width: {specs.width} ft</p>}
  //         {specs.diameter && (
  //           <p className={classes}>Diameter: {specs.diameter} in</p>
  //         )}
  //         {specs.weight && <p className={classes}>Weight: {specs.weight} kg</p>}
  //       </div>
  //     );
  //   };
  //   const stockCount = (supplier) => {
  //     if (supplier.priceCalcMethod === "weight-based") {
  //       return supplier.stock + " kg";
  //     } else if (supplier.priceCalcMethod === "quantity-based") {
  //       return supplier.stock;
  //     } else if (supplier.priceCalcMethod === "length-based") {
  //       const lengthPerQuantity = supplier.specifications.length;
  //       const fullCount = Math.floor(supplier.stock / lengthPerQuantity);
  //       const remainingCount = supplier.stock % lengthPerQuantity;

  //       return (
  //         <div className="flex flex-col items-start">
  //           <p>{"Full : " + fullCount}</p>
  //           <p>{"Remaining : " + remainingCount + " ft"}</p>
  //         </div>
  //       );
  //     }
  //   };

  const columns = [
    {
      title: "ID",
      dataIndex: "supplierId",
      key: "supplierId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.supplierId.localeCompare(b.supplierId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "20%",
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "20%",
    //     dataIndex: "remarks",
    //     key: "remarks",
    // },
    {
      title: "Phone Number",
      align: "center",
      width: "10%",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      align: "center",
      width: "25%",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Status",
      align: "center",
      width: "5%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <SupplierModal
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
          visible={dataModalState}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"Add New Supplier"}
            disabled={!(isSupervisor || isOffice) ? false : true}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default Suppliers;
