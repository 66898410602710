import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import Joi from "joi";

import NavBar from "../../components/NavBar";
import { Input, Button } from "../../components/ui";
import apiService from "../../http";
import { userLoggedIn } from "../../redux/actions/userActions";
import { FaUser, FaLock } from "react-icons/fa";
import Footer from "../../components/Footer/Footer";

const Loginform = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const inputDataStructure = {
    email: {
      key: "email",
      label: "Email",
      data: "",
      type: "email",
      placeholder: "Enter the email",
      icon: <FaUser />,
      validation: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.base": "Please enter a valid email address",
          "string.empty": "Email must not be empty",
          "any.required": "Email is required",
          "string.email": "Please enter a valid email address",
        }),
      error: null,
    },
    password: {
      key: "password",
      label: "Password",
      data: "",
      type: "password",
      placeholder: "Enter the password",
      icon: <FaLock />,
      validation: Joi.string().min(8).max(30).required().messages({
        "string.base": "Please enter a valid password",
        "string.empty": "Password must not be empty",
        "any.required": "Password is required",
        "string.min": "Password must be at least {#limit} characters long",
        "string.max": "Password cannot be longer than {#limit} characters",
      }),
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    let data = {
      email: inputs.email.data,
      password: inputs.password.data,
    };

    try {
      setIsLoading(true);
      const result = await apiService.post("user/login", data);
      setIsLoading(false);

      toast.success("Logged in successfully!");

      var decodedToken = jwt_decode(result.token);

      const userData = {
        token: result.token,
        email: decodedToken.email,
        avatar: decodedToken.avatar,
        role: decodedToken.role,
        exp: decodedToken.exp,
      };

      setTimeout(() => {
        dispatch(userLoggedIn(userData));
      }, 1000);
    } catch (e) {
      console.log(e);
      toast.error(e);
      setIsLoading(false);
    }
  };

  if (user.authenticated) {
    if (user.data.role === "admin") {
      return <Navigate to="/admin" />;
    } else {
      return <Navigate to="/profile" />;
    }
  }
  const handleDisabled = isLoading ? true : false;

  return (
    <>
      <div className="bg-img min-h-screen w-full flex flex-col items-center justify-between">
        <NavBar />
        <ToastContainer />
        <form className="drop-blur-lg bg-white bg-opacity-40 backdrop-blur-lg  w-[80vw] lg:w-[30vw] pb-20 mx-auto   shadow-lg rounded-xl  bg-clip-padding  flex flex-col items-center">
          <h2 className="w-full  text-center bg-primary text-white font-bold text-xl md:text-xl  mb-2 rounded-t-lg py-1">
            Igrid login
          </h2>

          <div className="w-4/5">
            <Input input={inputs.email} handleChange={handleChange} />
            <Input input={inputs.password} handleChange={handleChange} />
          </div>

          <div className="w-4/5">
            <Button
              text="Login"
              handleClick={handleSubmit}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default Loginform;
