import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../../http";
import { Button, Input } from "../../../components/ui";
import LoadingAnimation from "../../../components/Loader";
import { Table } from "antd";
import MaintanceModel from "./models/MaintanceModel";
import ExpenceModel from "./models/ExpenceModel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
function Maintance() {
  const [inquiries, setInquiries] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState(inputDataStructure);
  const [maintanceModel, setMaintanceModel] = useState(false);
  const [expenseModel, setExpenceModel] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";

  const handleCloseModel = () => {
    setSelectedProduct(null);
    setMaintanceModel(false);
    setExpenceModel(false);
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(inquiries);
      } else {
        const data = inquiries.filter((item) => {
          const lowerCaseTitle = item.inquiryId.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, inquiries]
  );

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const inquiries = await apiService.get("inquiry");
      //   const otherjobs = await apiService.get("other-jobs");

      //   const dataArray = [...inquiries, ...otherjobs]

      const updatedInqs = inquiries.filter((inqItemObj) => {
        const warrantyDataArray = inqItemObj.warranty;
        const warrantyIssuedDate = new Date(inqItemObj.warrantyIssuedDate);
        const currDate = new Date();

        if (warrantyDataArray && warrantyDataArray.length > 0) {
          for (var warrantyItemObj of warrantyDataArray) {
            const yearCount = parseFloat(warrantyItemObj.timePeriod);
            const warantyEndDate = new Date(
              warrantyIssuedDate.getFullYear() + yearCount,
              warrantyIssuedDate.getMonth(),
              warrantyIssuedDate.getDate()
            );

            if (
              warantyEndDate > currDate &&
              inqItemObj.inqueryStatus !== "Canceled"
            ) {
              return true;
            }
          }
        }

        return false;
      });
      setInquiries(updatedInqs);
      setFilteredData(updatedInqs);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshInquiries();
  }, []);

  const actionBtns = (inquiry) => {
    const status = inquiry.inqueryStatus;

    const disabled = status === "Canceled" ? true : false;
    return (
      <div className="flex items-center flex-wrap">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            navigate(`/maintance/${inquiry.id}`);
          }}
        />
        <Button
          text="Expence"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(inquiry);
            setExpenceModel(true);
          }}
          disabled={!(disabled || isOffice) ? false : true}
        />
        <Button
          text="Maintance"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(inquiry);
            setMaintanceModel(true);
          }}
          disabled={!(disabled || isSupervisor) ? false : true}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "inquiryId",
      key: "inquiryId",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      align: "center",
      width: "10%",
      render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },

    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      width: "25%",
      // sorter: (a, b) => a.stock - b.stock,
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {maintanceModel && (
        <MaintanceModel
          inquiry={selectedProduct}
          handleClose={handleCloseModel}
          visible={maintanceModel}
        />
      )}
      {expenseModel && (
        <ExpenceModel
          inquiry={selectedProduct}
          handleClose={handleCloseModel}
          visible={expenseModel}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <h1 className="text-2xl font-bold uppercase">
          Active Jobs that have Warranty
        </h1>

        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
}

export default Maintance;
