import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Select, Button, ImgUpload } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const SiteDataModal = ({ handleClose, inquiry, visible }) => {
  const inputDataStructure = {
    dcLength: {
      key: "dcLength",
      required: true,
      label: "DC Length",
      data: "",
      type: "number",
      error: null,
    },

    acLength: {
      key: "acLength",
      label: "AC Length",
      data: "",
      type: "number",
      error: null,
    },

    status: {
      key: "status",
      label: "Site status",
      data: "on-going",
      optList: [
        { id: "on-going", title: "On going" },
        { id: "completed", title: "Completed" },
      ],
      error: null,
    },
    file: {
      key: "file",
      label: "Site images",
      name: "file",
      data: [],
      min: 0,
      multiple: true,
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      acLength: inputs.acLength.data,
      dcLength: inputs.dcLength.data,
      status: inputs.status.data,
    };
    const formdata = new FormData();
    formdata.append("id", inquiry.id);
    formdata.append("status", data.status);
    formdata.append("acLength", data.acLength);
    formdata.append("dcLength", data.dcLength);

    if (inputs.file.data.length > 0) {
      for (const single_file of inputs.file.data) {
        formdata.append("file", single_file.data);
      }
    } else {
      toast.error("image is required");
      return;
    }

    try {
      setIsLoading(true);
      await apiService.post("inquiry/site-data", formdata);
      toast.success("Inquiry updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handlethis = (e) => {
    e.preventDefault();
  };
  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };
  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Update Site Details</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <form onSubmit={handlethis} encType="multipart/form-data">
            <Input input={inputs.dcLength} handleChange={handleChange} />
            <Input input={inputs.acLength} handleChange={handleChange} />
            <Select input={inputs.status} handleChange={handleChange} />

            <ImgUpload input={inputs.file} handleChange={handleChange} />
            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Update"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default SiteDataModal;
