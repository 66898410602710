import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import InquiryModal from "./InquiryModal";
import ProductMaterialModal from "./ProductMaterialModal";
import ProductStockModal from "./ProductStockModal";

import { Button, Confirm, Input } from "../../../components/ui";
import apiService from "../../../http";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../../components/Loader";
import { useSelector } from "react-redux";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
const Inquiries = () => {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [inquiries, setInquiries] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [inquiryModalState, setInquiryModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [inquiryStockModal, setProductStockModalState] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setInquiryModalState(false);
    setConfirmModalState(false);
    setMaterialModalState(false);
    setProductStockModalState(false);
    refreshInquiries();
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(inquiries);
      } else {
        const data = inquiries.filter((item) => {
          const lowerCaseTitle = item.inquiryId.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, inquiries]
  );

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const inquiries = await apiService.get("inquiry");

      setInquiries(inquiries);
      setFilteredData(inquiries);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshInquiries();
  }, []);

  const actionBtns = (inquiry) => {
    const status = inquiry.inqueryStatus;

    const disabled =
      (status === "Canceled") | (status === "Finished") ? true : false;
    return (
      <div className="flex items-center flex-wrap">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            navigate(`/inquiry/${inquiry.id}`);
          }}
        />
        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedProduct(inquiry);
            setInquiryModalState(true);
          }}
          disabled={!(disabled || isSupervisor || isOffice) ? false : true}
        />
      </div>
    );
  };

  const status = (name) => {
    const className =
      name === "Canceled"
        ? "badge bg-red-500 p-4 textarea-md text-white"
        : name === "stopped"
        ? "badge bg-secondary p-4 textarea-md text-white"
        : name === "Finished"
        ? "badge bg-primary p-4 textarea-md text-white"
        : "badge bg-green-500 p-4 textarea-md text-white";

    return <span className={className}>{name}</span>;
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "inquiryId",
      key: "inquiryId",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      key: "supervisor",
      align: "center",
      width: "10%",
      render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },

    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      width: "25%",
      // sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Status",
      dataIndex: "inqueryStatus",
      align: "center",
      width: "25%",
      render: (args) => status(args),
      // sorter: (a, b) => a.stock - b.stock,
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {inquiryModalState && (
        <InquiryModal
          selectedInquiry={selectedProduct}
          handleClose={handleCloseModal}
          visible={inquiryModalState}
        />
      )}
      {materialModalState && (
        <ProductMaterialModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {inquiryStockModal && (
        <ProductStockModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}

      {confirmModalState && (
        <Confirm
          title="Finish Job"
          confirmMsg="Do you want to finish the inquiry job?"
          actionLabel="OK"
          cancelHandler={handleCloseModal}
          confirmHandler={async () => {
            await apiService.patch("inquiry/status/" + selectedProduct.id);
            toast.success("Inquiry status changed successfully!");
            handleCloseModal();
            setSelectedProduct(null);
          }}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"New Job"}
            disabled={!(isSupervisor || isOffice) ? false : true}
            handleClick={() => setInquiryModalState(true)}
            className={"hidden"}
          />
          <div className="font-bold text-xl uppercase">Jobs</div>
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default Inquiries;
