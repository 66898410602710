import React from "react";

const LoadingAnimation = () => {
    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
            <div className="animate-spin items-center rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-50"></div>
        </div>
    );
};

export default LoadingAnimation;
