import { Input, Button } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import React, { useCallback, useEffect, useRef, useState } from "react";

function ExpenceAddModel({ handleClose, selectedExpance, visible }) {
  const inputDataStucture = {
    title: {
      key: "title",
      label: "Title",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    startDate: {
      key: "startDate",
      label: "Start Date",
      data: "",
      required: false,
      type: "date",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStucture);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  useEffect(() => {
    const handleGetData = async () => {
      if (selectedExpance) {
        let input_list = { ...inputs };

        const date = new Date(selectedExpance.startDate);

        input_list.title.data = selectedExpance.expenceTitle;
        input_list.startDate.data = date;

        setInputs(input_list);
      }
    };
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      startDate: inputs.startDate.data,
    };

    try {
      if (selectedExpance) {
        const date = new Date();
        formData.id = selectedExpance._id;
        formData.startDate = date.toISOString().split("T")[0];
        await apiService.patch("expence", formData);
        toast.success("Expence Timeline created successfully!");
      } else {
        await apiService.post("expence", formData);
        toast.success("Expence Timeline created successfully!");
      }

      handleClose();
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {selectedExpance ? "Update " : "Create "} TimeLine
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Input input={inputs.title} handleChange={handleChange} />

          {!selectedExpance && (
            <Input input={inputs.startDate} handleChange={handleChange} />
          )}

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={selectedExpance ? "Update" : "Create"}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default ExpenceAddModel;
