import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../http";
import { Table } from "antd";

function ViewPaidOutstandingModule({
  selectedMaterial,
  supplierId,
  handleClose,
  visible,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [payData, setPayData] = useState([]);

  const modalRef = useRef(null);

  const outstandingobj = selectedMaterial.payout[0];

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(
        `payment/viewoutstandingPaymentbysupplier/${supplierId}`
      );
      setPayData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [supplierId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const columnsPO = [
    {
      title: "ID",
      key: "outstandingPaymentId",
      align: "center",
      width: "10%",
      sorter: (a, b) =>
        a.outstandingPaymentId.localeCompare(b.outstandingPaymentId),
      render: (args) => {
        const poID = args.outstandingPaymentId;

        return <div>{poID}</div>;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (args) => {
        const date = new Date(args.paiddate);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Invoice No",
      align: "center",
      width: "20%",
      render: (arg) => {
        const invNo = arg.invoicenumber;

        return (
          <div
            className={
              "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {invNo}
          </div>
        );
      },
    },
    {
      title: "Paid Amount(LKR)",
      align: "center",
      width: "20%",
      render: (arg) => {
        const payData = arg.paidamount;

        var paid = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const paidAmount = payData;
          paid = formatter.format(paidAmount);
        }

        return (
          <div
            className={
              paid !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {paid}
          </div>
        );
      },
    },
    {
      title: "Outstanding Amount",
      align: "center",
      width: "20%",
      render: (arg) => {
        // const poPaymentArray = arg.payData;
        const outstandingAmount = arg.outstandingAmount;

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return (
          <div
            className={
              "badge badge-error gap-2 p-4 text-md font-bold text-white"
            }
          >
            {formatter.format(~~outstandingAmount)}
          </div>
        );
      },
    },
  ];

  const tBody = payData
    .map((item, index) => {
      if (item.outstandingId === outstandingobj.outstandingId) {
        return {
          key: index,
          ...item,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl lg:w-11/12  lg:max-w-none transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Paid Outstanding Payments</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <div className="flex flex-col items-start justify-center py-2">
            <Table
              columns={columnsPO}
              dataSource={tBody}
              style={{ width: "100%" }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default ViewPaidOutstandingModule;
