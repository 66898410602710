import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import apiService from "../../../../http";
import { Input, Select } from "../../../../components/ui";
import LoadingAnimation from "../../../../components/Loader";
import Logo from "../../../../components/logo/Logo";
import { useReactToPrint } from "react-to-print";

const metadata = {
  EXPENCEPERIOD: "Expence Period",
  FILTERBYDATE: "Filter By Date",
  FILTERBYJOB: "Filter By Job",
  FILTERBYOTHERJOB: "Filter by OtherJob",
};
const inputDataStructure = {
  expence: {
    key: "expence",
    label: "Select Expence Period",
    optTitle: "",
    data: "",
    optList: [],
    required: true,
    type: "text",
    error: null,
  },
  jobs: {
    key: "jobs",
    label: "Select a Job",
    optTitle: "",
    data: "",
    optList: [],
    required: true,
    type: "text",
    error: null,
  },
  otherjobs: {
    key: "otherjobs",
    label: "Select a Other Job",
    optTitle: "",
    data: "",
    optList: [],
    required: true,
    type: "text",
    error: null,
  },
  startDate: {
    key: "startDate",
    label: "Start Date",
    data: "",
    type: "date",
    error: null,
  },
  endDate: {
    key: "endDate",
    label: "End Date",
    data: "",
    type: "date",
    error: null,
  },
};
const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};
const radioBtnOptions = [
  metadata.EXPENCEPERIOD,
  metadata.FILTERBYJOB,
  metadata.FILTERBYOTHERJOB,
  metadata.FILTERBYDATE,
];
function ExpenceReport() {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [expences, setExpences] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [otherJobs, setOtherJobs] = useState(null);

  const ref = useRef();

  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [filterType, setFilterType] = useState(metadata.EXPENCEPERIOD);

  const reFreshData = async () => {
    try {
      setIsLoading(true);

      let input_list = { ...inputs };

      const expences = await apiService.get("expence");
      const jobs = await apiService.get("inquiry");
      const otherjobs = await apiService.get("other-jobs");

      const expOptList = expences.map((exp) => {
        return {
          id: exp.expenceId,
          title: exp.expenceTitle,
        };
      });
      const jobOptList = jobs.map((exp) => {
        return {
          id: exp.id,
          title: exp.inquiryId,
        };
      });
      const otherJobsOptList = otherjobs.map((exp) => {
        return {
          id: exp._id,
          title: exp.otherJobId,
        };
      });

      input_list.expence.optList = expOptList;
      input_list.jobs.optList = jobOptList;
      input_list.otherjobs.optList = otherJobsOptList;
      setInputs(input_list);

      setExpences(expences);
      setJobs(jobs);
      setOtherJobs(otherjobs);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      setInputs(input_list);
    },
    [inputs]
  );

  useEffect(() => {
    reFreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handleViewData = () => {
    if (filterType === metadata.EXPENCEPERIOD) {
      const expenceId = inputs.expence.data;
      if (expenceId !== "") {
        const SelectedExpence = expences.find(
          (expOBJ) => expOBJ.expenceId === expenceId
        );
        setFilteredData([SelectedExpence]);
      }
    }
    if (filterType === metadata.FILTERBYJOB) {
      const jobId = inputs.jobs.data;
      if (jobId !== "") {
        const SelectedExpence = jobs.find((jobOBJ) => jobOBJ.id === jobId);
        setFilteredData([SelectedExpence]);
      }
    }
    if (filterType === metadata.FILTERBYOTHERJOB) {
      const otherJobId = inputs.otherjobs.data;
      if (otherJobId !== "") {
        const SelectedExpence = otherJobs.find(
          (ojOBJ) => ojOBJ._id === otherJobId
        );
        setFilteredData([SelectedExpence]);
      }
    }

    if (filterType === metadata.FILTERBYDATE) {
      const startDate = new Date(inputs.startDate.data);
      const endDate = new Date(inputs.endDate.data);

      if (startDate && endDate) {
        const filteredInqData = expences.filter((expObj) => {
          var createdDate = new Date(expObj.startDate);
          return createdDate >= startDate && createdDate <= endDate;
        });
        setFilteredData(filteredInqData);
      }
    }
  };

  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  const HandleFilterOption = () => {
    return (
      <div>
        {filterType === metadata.EXPENCEPERIOD ? (
          <div>
            <Select input={inputs.expence} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.FILTERBYDATE ? (
          <div className="flex flex-col items-center">
            <Input input={inputs.startDate} handleChange={handleChange} />
            <Input input={inputs.endDate} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.FILTERBYJOB ? (
          <div className="flex flex-col items-center">
            <Select input={inputs.jobs} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.FILTERBYOTHERJOB ? (
          <div className="flex flex-col items-center">
            <Select input={inputs.otherjobs} handleChange={handleChange} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const TableCustom = ({ dataSource }) => {
    const TableHeader = () => {
      return (
        <div className=" flex items-center justify-between gap-2">
          <h2 className="font-black text-black text-xl">
            IGRID HOLDINGS (PVT) LTD
          </h2>
          <div className="items-start font-bold">Expence Sheet</div>
        </div>
      );
    };

    const HandleTHeaderData = () => {
      return (
        <thead className="bg-slate-200 font-bold">
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
      );
    };

    const HandleTBodyData = () => {
      return (
        <tbody className="text-wrap items-center">
          {filterType === metadata.EXPENCEPERIOD ? (
            dataSource.map((itemOBJ) => {
              const startDate = new Date(itemOBJ.startDate);

              const generalExpenceList = itemOBJ.generalExpences;
              const labourChargesList = itemOBJ.LabourExpences;
              const salaryCharges = itemOBJ.SalaryExpences;

              const data = [];
              const ojdata = [];

              for (var JobData of jobs) {
                const expencesList = JobData.expenses;
                if (expencesList.length > 0) {
                  var expenceList = [];
                  for (var expence of expencesList) {
                    const expenceDate = new Date(expence.date);

                    var isYear =
                      expenceDate.getFullYear() === startDate.getFullYear();
                    var isMonth =
                      expenceDate.getMonth() === startDate.getMonth();
                    if (isYear && isMonth) {
                      expenceList.push(expence);
                    }
                  }
                  if (expenceList.length > 0) {
                    data.push({
                      id: JobData.inquiryId,
                      expences: expenceList,
                    });
                  }
                }
              }
              for (var otherJobData of otherJobs) {
                const ojexpencesList = otherJobData.expenses;
                if (ojexpencesList.length > 0) {
                  var ojexpenceList = [];
                  for (var ojexpence of ojexpencesList) {
                    const expenceDate = new Date(ojexpence.date);
                    if (
                      expenceDate.getFullYear() === startDate.getFullYear() &&
                      expenceDate.getMonth() === startDate.getMonth()
                    ) {
                      ojexpenceList.push(ojexpence);
                    }
                  }
                  if (ojexpenceList.length > 0) {
                    ojdata.push({
                      id: otherJobData.otherJobId,
                      expences: ojexpenceList,
                    });
                  }
                }
              }

              return (
                <Fragment key={itemOBJ.key}>
                  {generalExpenceList && generalExpenceList.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>General Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        {generalExpenceList.map((genItemObj, index) => {
                          const id = genItemObj.generalExpenceId;
                          const desc = genItemObj.invoiceby;
                          const amount = genItemObj.expenceAmount;
                          return (
                            <Fragment key={index}>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </Fragment>
                          );
                        })}
                      </tr>
                    </>
                  )}
                  {labourChargesList && labourChargesList.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Labour Expences</td>
                        <td colSpan={2}></td>
                      </tr>

                      {labourChargesList.map((lebItemObj, index) => {
                        const id = lebItemObj.InquiryNumber;
                        const desc = lebItemObj.labourType;
                        const amount = lebItemObj.expenceAmount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {salaryCharges && salaryCharges.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Salary Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      <tr>
                        {salaryCharges.map((salItemObj, index) => {
                          const id = salItemObj.employeeId;
                          const desc = "";
                          const amount = parseFloat(salItemObj.totalSalary);
                          const commision = parseFloat(
                            salItemObj.totalCommision
                          );
                          const deduction = parseFloat(
                            salItemObj.totalDeductions
                          );
                          return (
                            <Fragment key={index}>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount + commision - deduction}</td>
                            </Fragment>
                          );
                        })}
                      </tr>
                    </>
                  )}
                  {data && data.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Job Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {data.map((jobExpObj, index) => {
                        const id = jobExpObj.id;
                        const listOfExp = jobExpObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{id}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {listOfExp.map((genItemObj, index) => {
                              const desc = genItemObj.expenseType;
                              const amount = genItemObj.amount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {ojdata && ojdata.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>OtherJob Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {ojdata.map((jobExpObj, index) => {
                        const id = jobExpObj.id;
                        const listOfExp = jobExpObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{id}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {listOfExp.map((genItemObj, index) => {
                              const desc = genItemObj.expenseType;
                              const amount = genItemObj.amount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })
          ) : filterType === metadata.FILTERBYJOB ? (
            dataSource.map((itemObj, index) => {
              const expencesData = itemObj.expenses;
              const maintanceData = itemObj.maintanceExpences;
              const labExpences = [];

              const InqId = itemObj.inquiryId;

              for (var expenceObj of expences) {
                const labChargeList = expenceObj.LabourExpences;
                if (labChargeList.length > 0) {
                  let dataArray = [];
                  for (var labChargeObj of labChargeList) {
                    if (labChargeObj.InquiryNumber === InqId) {
                      dataArray.push(labChargeObj);
                    }
                  }
                  if (dataArray.length > 0) {
                    labExpences.push({
                      id: expenceObj.expenceTitle,
                      expences: dataArray,
                    });
                  }
                }
              }

              return (
                <Fragment key={index}>
                  {labExpences && labExpences.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Labour Expences</td>
                        <td colSpan={2}></td>
                      </tr>

                      {labExpences.map((lebItemObj, index) => {
                        const title = lebItemObj.id;
                        const expArray = lebItemObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{title}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {expArray.map((expObj, index) => {
                              const id = expObj.InquiryNumber;
                              const desc = expObj.labourType;
                              const amount = expObj.expenceAmount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td>{id}</td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {expencesData && expencesData.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Site Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {expencesData.map((expItemObj, index) => {
                        const id = "";
                        const desc = expItemObj.expenseType;
                        const amount = expItemObj.amount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {maintanceData && maintanceData.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Maintance Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {maintanceData.map((expItemObj, index) => {
                        const id = "";
                        const desc = expItemObj.expenseType;
                        const amount = expItemObj.amount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })
          ) : filterType === metadata.FILTERBYOTHERJOB ? (
            dataSource.map((itemObj) => {
              const expencesData = itemObj.expenses;
              const labExpences = [];

              const InqId = itemObj.otherJobId;

              for (var expenceObj of expences) {
                const labChargeList = expenceObj.LabourExpences;
                if (labChargeList.length > 0) {
                  let dataArray = [];
                  for (var labChargeObj of labChargeList) {
                    if (labChargeObj.InquiryNumber === InqId) {
                      dataArray.push(labChargeObj);
                    }
                  }
                  if (dataArray.length > 0) {
                    labExpences.push({
                      id: expenceObj.expenceTitle,
                      expences: dataArray,
                    });
                  }
                }
              }

              return (
                <Fragment key={itemObj.key}>
                  {labExpences && labExpences.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Labour Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {labExpences.map((lebItemObj, index) => {
                        const title = lebItemObj.id;
                        const expenceArray = lebItemObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{title}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {expenceArray.map((expObj, index) => {
                              const id = expObj.InquiryNumber;
                              const desc = expObj.labourType;
                              const amount = expObj.expenceAmount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td>{id}</td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {expencesData && expencesData.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Site Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {expencesData.map((expItemObj, index) => {
                        const id = "";
                        const desc = expItemObj.expenseType;
                        const amount = expItemObj.amount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })
          ) : filterType === metadata.FILTERBYDATE ? (
            dataSource.map((itemOBJ) => {
              const startDate = new Date(itemOBJ.startDate);

              const generalExpenceList = itemOBJ.generalExpences;
              const labourChargesList = itemOBJ.LabourExpences;
              const salaryCharges = itemOBJ.SalaryExpences;

              const data = [];
              const ojdata = [];

              for (var JobData of jobs) {
                const expencesList = JobData.expenses;
                if (expencesList.length > 0) {
                  var expenceList = [];
                  for (var expence of expencesList) {
                    const expenceDate = new Date(expence.date);
                    if (
                      expenceDate.getFullYear() === startDate.getFullYear() &&
                      expenceDate.getMonth() === startDate.getMonth()
                    ) {
                      expenceList.push(expence);
                    }
                  }
                  if (expenceList.length > 0) {
                    data.push({
                      id: JobData.inquiryId,
                      expences: expenceList,
                    });
                  }
                }
              }
              for (var otherJobData of otherJobs) {
                const ojexpencesList = otherJobData.expenses;
                if (ojexpencesList.length > 0) {
                  var ojexpenceList = [];
                  for (var ojexpence of ojexpencesList) {
                    const expenceDate = new Date(ojexpence.date);
                    if (
                      expenceDate.getFullYear() === startDate.getFullYear() &&
                      expenceDate.getMonth() === startDate.getMonth()
                    ) {
                      ojexpenceList.push(ojexpence);
                    }
                  }
                  if (ojexpenceList.length > 0) {
                    ojdata.push({
                      id: otherJobData.otherJobId,
                      expences: ojexpenceList,
                    });
                  }
                }
              }
              return (
                <Fragment key={itemOBJ.key}>
                  <tr className="bg-orange-200">
                    <td>{itemOBJ.expenceTitle}</td>
                    <td></td>
                    <td colSpan={2}></td>
                  </tr>
                  {generalExpenceList && generalExpenceList.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>General Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {generalExpenceList.map((genItemObj, index) => {
                        const id = genItemObj.generalExpenceId;
                        const desc = genItemObj.invoiceby;
                        const amount = genItemObj.expenceAmount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {labourChargesList && labourChargesList.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Labour Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {labourChargesList.map((lebItemObj, index) => {
                        const id = lebItemObj.InquiryNumber;
                        const desc = lebItemObj.labourType;
                        const amount = lebItemObj.expenceAmount;
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {salaryCharges && salaryCharges.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Salary Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {salaryCharges.map((salItemObj, index) => {
                        const id = salItemObj.employeeId;
                        const desc = "";
                        const amount = parseFloat(salItemObj.totalSalary);
                        const commision = parseFloat(salItemObj.totalCommision);
                        const deduction = parseFloat(
                          salItemObj.totalDeductions
                        );
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td></td>
                              <td>{id}</td>
                              <td>{desc}</td>
                              <td>{amount + commision - deduction}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {data && data.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>Job Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {data.map((jobExpObj, index) => {
                        const id = jobExpObj.id;
                        const listOfExp = jobExpObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{id}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {listOfExp.map((genItemObj, index) => {
                              const desc = genItemObj.expenseType;
                              const amount = genItemObj.amount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {ojdata && ojdata.length > 0 && (
                    <>
                      <tr className="bg-slate-400">
                        <td></td>
                        <td>OtherJob Expences</td>
                        <td colSpan={2}></td>
                      </tr>
                      {ojdata.map((jobExpObj, index) => {
                        const id = jobExpObj.id;
                        const listOfExp = jobExpObj.expences;
                        return (
                          <Fragment key={index}>
                            <tr className="bg-yellow-200">
                              <td></td>
                              <td>{id}</td>
                              <td colSpan={2}></td>
                            </tr>
                            {listOfExp.map((genItemObj, index) => {
                              const desc = genItemObj.expenseType;
                              const amount = genItemObj.amount;
                              return (
                                <Fragment key={index}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>{desc}</td>
                                    <td>{amount}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      );
    };

    const HandleTableBodyData = () => {
      return (
        <div className="content">
          <table className="w-[700px]">
            <HandleTHeaderData />
            <HandleTBodyData />
          </table>
        </div>
      );
    };

    const HandleTableFooterData = () => {
      var totalAmount = 0;

      if (filterType === metadata.EXPENCEPERIOD) {
        for (var expenceObj of dataSource) {
          const startDate = new Date(expenceObj.startDate);

          const generalExpenceList = expenceObj.generalExpences;
          const labourChargesList = expenceObj.LabourExpences;
          const salaryCharges = expenceObj.SalaryExpences;

          var data = [];
          var ojdata = [];

          var genExpenceTotal = 0;
          var lebExpenceTotal = 0;
          var salExpenceTotal = 0;
          var jobexpTotal = 0;
          var ojjobexpTotal = 0;

          for (var JobData of jobs) {
            const expencesList = JobData.expenses;
            if (expencesList.length > 0) {
              var expenceList = [];
              for (var expence of expencesList) {
                const expenceDate = new Date(expence.date);
                if (
                  expenceDate.getFullYear() === startDate.getFullYear() &&
                  expenceDate.getMonth() === startDate.getMonth()
                ) {
                  expenceList.push(expence);
                }
              }
              if (expenceList.length > 0) {
                data.push({
                  id: JobData.inquiryId,
                  expences: expenceList,
                });
              }
            }
          }
          for (var ojJobData of otherJobs) {
            const ojexpencesList = ojJobData.expenses;
            if (ojexpencesList.length > 0) {
              var ojexpenceList = [];
              for (var ojexpence of ojexpencesList) {
                const expenceDate = new Date(ojexpence.date);
                if (
                  expenceDate.getFullYear() === startDate.getFullYear() &&
                  expenceDate.getMonth() === startDate.getMonth()
                ) {
                  ojexpenceList.push(ojexpence);
                }
              }
              if (ojexpenceList.length > 0) {
                ojdata.push({
                  id: ojJobData.otherJobId,
                  expences: ojexpenceList,
                });
              }
            }
          }

          if (generalExpenceList && generalExpenceList.length > 0) {
            for (var genItemObj of generalExpenceList) {
              const amount1 = genItemObj.expenceAmount;
              genExpenceTotal += parseFloat(amount1);
            }
          }
          if (labourChargesList && labourChargesList.length > 0) {
            for (var lebItemObj of labourChargesList) {
              const amount2 = lebItemObj.expenceAmount;
              lebExpenceTotal += parseFloat(amount2);
            }
          }
          if (salaryCharges && salaryCharges.length > 0) {
            for (var salItemObj of salaryCharges) {
              const amount3 = salItemObj.totalSalary;
              const amount4 = salItemObj.totalCommision;
              const amount5 = salItemObj.totalDeductions;
              const realAmount =
                parseFloat(amount3) + parseFloat(amount4) - parseFloat(amount5);
              salExpenceTotal += realAmount;
            }
          }

          if (data && data.length > 0) {
            for (var expObj of data) {
              var expArray = expObj.expences;
              for (var objexp of expArray) {
                const amount = objexp.amount;
                jobexpTotal += parseFloat(amount);
              }
            }
          }
          if (ojdata && ojdata.length > 0) {
            for (var ojexpObj of ojdata) {
              var ojexpArray = ojexpObj.expences;
              for (var ojobjexp of ojexpArray) {
                const ojamount = ojobjexp.amount;
                ojjobexpTotal += parseFloat(ojamount);
              }
            }
          }

          totalAmount +=
            salExpenceTotal +
            lebExpenceTotal +
            genExpenceTotal +
            jobexpTotal +
            ojjobexpTotal;
        }
      } else if (filterType === metadata.FILTERBYJOB) {
        for (var expencesObj of dataSource) {
          var expAmount = 0;
          var maintanceAmount = 0;
          var lebAmount = 0;

          const expencesData = expencesObj.expenses;
          const maintanceData = expencesObj.maintanceExpences;
          const labExpences = [];

          const InqId = expencesObj.inquiryId;

          for (var expenceObj1 of expences) {
            const labChargeList = expenceObj1.LabourExpences;
            for (var labChargeObj of labChargeList) {
              if (labChargeObj.InquiryNumber === InqId) {
                labExpences.push(labChargeObj);
              }
            }
          }

          if (labExpences && labExpences.length > 0) {
            for (var lebItemObj1 of labExpences) {
              const amount = lebItemObj1.expenceAmount;
              lebAmount += parseFloat(amount);
            }
          }
          if (expencesData && expencesData.length > 0) {
            for (var expItemObj of expencesData) {
              const amount2 = expItemObj.amount;
              expAmount += parseFloat(amount2);
            }
          }
          if (maintanceData && maintanceData.length > 0) {
            for (var expItemObj2 of maintanceData) {
              const amount2 = expItemObj2.amount;
              maintanceAmount += parseFloat(amount2);
            }
          }

          totalAmount += lebAmount + expAmount + maintanceAmount;
        }
      } else if (filterType === metadata.FILTERBYOTHERJOB) {
        for (var ojexpencesObj of dataSource) {
          var ojexpAmount = 0;
          var ojlebAmount = 0;

          const expencesData = ojexpencesObj.expenses;
          const labExpences = [];

          const InqId = ojexpencesObj.otherJobId;

          for (var expenceObj2 of expences) {
            const labChargeList = expenceObj2.LabourExpences;
            for (var labChargeObj2 of labChargeList) {
              if (labChargeObj2.InquiryNumber === InqId) {
                labExpences.push(labChargeObj2);
              }
            }
          }

          if (labExpences && labExpences.length > 0) {
            for (var lebItemObj2 of labExpences) {
              const amount = lebItemObj2.expenceAmount;
              ojlebAmount += parseFloat(amount);
            }
          }
          if (expencesData && expencesData.length > 0) {
            for (var expItemObj3 of expencesData) {
              const amount2 = expItemObj3.amount;
              ojexpAmount += parseFloat(amount2);
            }
          }

          totalAmount += ojlebAmount + ojexpAmount;
        }
      } else if (filterType === metadata.FILTERBYDATE) {
        for (var expenceObjDate of dataSource) {
          const startDate = new Date(expenceObjDate.startDate);

          const generalExpenceList = expenceObjDate.generalExpences;
          const labourChargesList = expenceObjDate.LabourExpences;
          const salaryCharges = expenceObjDate.SalaryExpences;

          var Datedata = [];
          var Dateojdata = [];

          var DategenExpenceTotal = 0;
          var DatelebExpenceTotal = 0;
          var DatesalExpenceTotal = 0;
          var DatejobexpTotal = 0;
          var DateojjobexpTotal = 0;

          for (var JobDataDate of jobs) {
            const expencesList = JobDataDate.expenses;
            if (expencesList.length > 0) {
              var expenceListDate = [];
              for (var expenceDataDate of expencesList) {
                const expenceDate = new Date(expenceDataDate.date);
                if (
                  expenceDate.getFullYear() === startDate.getFullYear() &&
                  expenceDate.getMonth() === startDate.getMonth()
                ) {
                  expenceListDate.push(expenceDataDate);
                }
              }
              if (expenceListDate.length > 0) {
                Datedata.push({
                  id: JobDataDate.inquiryId,
                  expences: expenceListDate,
                });
              }
            }
          }
          for (var ojJobDataDate of otherJobs) {
            const ojexpencesList = ojJobDataDate.expenses;
            if (ojexpencesList.length > 0) {
              var ojexpenceListDate = [];
              for (var ojexpenceDateDate of ojexpencesList) {
                const expenceDate = new Date(ojexpenceDateDate.date);
                if (
                  expenceDate.getFullYear() === startDate.getFullYear() &&
                  expenceDate.getMonth() === startDate.getMonth()
                ) {
                  ojexpenceListDate.push(ojexpenceDateDate);
                }
              }
              if (ojexpenceListDate.length > 0) {
                Dateojdata.push({
                  id: ojJobDataDate.otherJobId,
                  expences: ojexpenceListDate,
                });
              }
            }
          }

          if (generalExpenceList && generalExpenceList.length > 0) {
            for (var genItemObjDate of generalExpenceList) {
              const amount1 = genItemObjDate.expenceAmount;
              DategenExpenceTotal += parseFloat(amount1);
            }
          }
          if (labourChargesList && labourChargesList.length > 0) {
            for (var lebItemObjDate of labourChargesList) {
              const amount2 = lebItemObjDate.expenceAmount;
              DatelebExpenceTotal += parseFloat(amount2);
            }
          }
          if (salaryCharges && salaryCharges.length > 0) {
            for (var salItemObjDate of salaryCharges) {
              const amount3 = salItemObjDate.totalSalary;
              const amount4 = salItemObjDate.totalCommision;
              const amount5 = salItemObjDate.totalDeductions;
              const realAmount =
                parseFloat(amount3) + parseFloat(amount4) - parseFloat(amount5);
              DatesalExpenceTotal += realAmount;
            }
          }

          if (Datedata && Datedata.length > 0) {
            for (var expObjDate of Datedata) {
              var expArrayDate = expObjDate.expences;
              for (var objexpDate of expArrayDate) {
                const amount = objexpDate.amount;
                DatejobexpTotal += parseFloat(amount);
              }
            }
          }
          if (Dateojdata && Dateojdata.length > 0) {
            for (var ojexpObjDate of Dateojdata) {
              var ojexpArrayDate = ojexpObjDate.expences;
              for (var ojobjexpDate of ojexpArrayDate) {
                const ojamount = ojobjexpDate.amount;
                DateojjobexpTotal += parseFloat(ojamount);
              }
            }
          }

          totalAmount +=
            DatesalExpenceTotal +
            DatelebExpenceTotal +
            DategenExpenceTotal +
            DatejobexpTotal +
            DateojjobexpTotal;
        }
      } else {
        totalAmount = 0;
      }

      return (
        <div className="content">
          <div className="flex flex-row items-end justify-end">
            Total Amount : LKR{" "}
            {totalAmount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
      );
    };

    return (
      <table className="table w-[700px]">
        <thead>
          <tr>
            <th>
              <TableHeader />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <HandleTableBodyData />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-content flex flex-col items-end mx-5 text-lg">
                <HandleTableFooterData />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">EXPENCES REPORT</p>
              <div className="mt-4">
                {/* radio buttons goes here */}
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>
          <div
            id="tableData"
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableCustom dataSource={tBody} style={{ width: "100%" }} />
          </div>
        </div>
      )}
    </section>
  );
}

export default ExpenceReport;
