import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import apiService from "../../../../http";
import { useReactToPrint } from "react-to-print";
import { Input, Select } from "../../../../components/ui";
import LoadingAnimation from "../../../../components/Loader";
import Logo from "../../../../components/logo/Logo";

const inputDataStructure = {
  otherjob: {
    key: "otherjob",
    label: "Select Other Job",
    optTitle: "",
    data: null,
    // optList: [],
    required: true,
    type: "text",
    error: null,
  },
  startDate: {
    key: "startDate",
    label: "Start Date",
    data: "",
    type: "date",
    error: null,
  },
  endDate: {
    key: "endDate",
    label: "End Date",
    data: "",
    type: "date",
    error: null,
  },
};

const metadata = {
  ALL: "All",
  FILTERBYOTHERJOB: "Filter By Other Job",
  FILTERBYDATE: "Filter By Date",
};

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const radioBtnOptions = [
  metadata.ALL,
  metadata.FILTERBYOTHERJOB,
  metadata.FILTERBYDATE,
];
function OtherJobReport() {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [inquries, setInquries] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [materials, setMaterials] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();

  const [filterType, setFilterType] = useState("All");

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const inquiries = await apiService.get("other-jobs");

      const materialsData = await apiService.get("material");

      let input_list = { ...inputs };

      const matOptList = inquiries.map((inq) => {
        return {
          id: inq._id,
          title: `${inq.otherJobId}`,
        };
      });

      input_list.otherjob.optList = matOptList;
      setInputs(input_list);

      setMaterials(materialsData);
      setInquries(inquiries);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      setInputs(input_list);
    },
    [inputs]
  );

  useEffect(() => {
    refreshInquiries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomFooter = () => {
    // let totalQty = 0;
    let TotalNetPrice = 0;

    // for (let i = 0; i < tableData?.length; i++) {
    //   totalQty += tableData[i]["stockCount"];
    //   TotalNetPrice += tableData[i]["stockCount"] * tableData[i]["unitPrice"];
    // }

    if (filteredData && filteredData.length > 0) {
      for (const job of filteredData) {
        const totalcostforInq = handleQuantityInTotal(job);
        TotalNetPrice += totalcostforInq;
      }
    }

    return (
      <div className="flex flex-row w-full gap-16 pr-2 border-y-2 items-end justify-end">
        <div className="flex gap-3">
          <div className="font-bold text-lg">Total Net Price:</div>
          <div className="mr-2 font-bold text-lg">
            {TotalNetPrice.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
    );
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handleViewData = () => {
    if (filterType === metadata.ALL) {
      setFilteredData(inquries);
    }

    if (filterType === metadata.FILTERBYOTHERJOB) {
      const inqId = inputs.otherjob.data;
      if (inqId != null) {
        const selectedInq = inquries.find((inqObj) => inqObj._id === inqId);

        setFilteredData([selectedInq]);
      }
    }

    if (filterType === metadata.FILTERBYDATE) {
      const startDate = new Date(inputs.startDate.data);
      const endDate = new Date(inputs.endDate.data);

      if (startDate && endDate) {
        const filteredInqData = inquries.filter((inqObj) => {
          var createdDate = new Date(inqObj.createdAt);
          return createdDate >= startDate && createdDate <= endDate;
        });
        setFilteredData(filteredInqData);
      }
    }
  };

  const handleQuantityInTotal = (args) => {
    const materialBomList = args.bomMaterials;
    const returnMaterialBomList = args.returnBomMaterials;

    let matbomList = [];
    let retbomList = [];

    const matBomCountArray = materialBomList?.map((materialBomObj) => {
      const matItemObjArray = materialBomObj?.materials;
      const data = matItemObjArray?.map((matitemObj) => {
        const matQty = matitemObj?.quantity;
        const matId = matitemObj?.material;

        const selectedMat =
          materials &&
          materials?.find((item) => {
            return item.id === matId;
          });

        return {
          materialName: selectedMat?.title,
          matId: selectedMat?.id,
          materialId: selectedMat?.materialId,
          unit: selectedMat?.unit,
          qty: matQty,
          totalPrice: parseFloat(selectedMat?.unitPrice) * parseFloat(matQty),
        };
      });
      return data;
    });

    if (matBomCountArray && matBomCountArray.length > 0) {
      const flatmatBomCountArray = matBomCountArray.flat();

      const result1 = flatmatBomCountArray.reduce((acc, curr) => {
        const existingItemIndex = acc.findIndex(
          (item) => item.matId === curr.matId
        );

        if (existingItemIndex !== -1) {
          acc[existingItemIndex].qty = (
            parseInt(acc[existingItemIndex].qty) + parseInt(curr.qty)
          ).toString();
          acc[existingItemIndex].totalPrice += curr.totalPrice;
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);

      matbomList = [...result1];
    }

    const returnmatCountArray = returnMaterialBomList?.map((materialBomObj) => {
      const matItemObjArray = materialBomObj?.materials;
      const data = matItemObjArray?.map((matitemObj) => {
        const matQty = matitemObj?.quantity;
        const matId = matitemObj?.material;

        const selectedMat =
          materials &&
          materials?.find((item) => {
            return item.id === matId;
          });

        return {
          materialName: selectedMat?.title,
          matId: selectedMat?.id,
          materialId: selectedMat?.materialId,
          unit: selectedMat?.unit,
          qty: matQty,
          totalPrice: parseFloat(selectedMat?.unitPrice) * parseFloat(matQty),
        };
      });
      return data;
    });

    if (returnmatCountArray && returnmatCountArray.length > 0) {
      const flatreturnmatCountArray = returnmatCountArray.flat();

      const result2 = flatreturnmatCountArray.reduce((acc, curr) => {
        const existingItemIndex = acc.findIndex(
          (item) => item.matId === curr.matId
        );

        if (existingItemIndex !== -1) {
          acc[existingItemIndex].qty = (
            parseInt(acc[existingItemIndex].qty) + parseInt(curr.qty)
          ).toString();
          acc[existingItemIndex].totalPrice += curr.totalPrice;
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
      retbomList = [...result2];
    }

    const data2 = matbomList.map((item) => {
      let dataArray = [];
      if (retbomList.length > 0) {
        let retItem = retbomList.find(
          (retItem) => retItem.matId === item.matId
        );
        if (retItem) {
          let updatedValue = {
            materialName: item.materialName,
            matId: item.matId,
            materialId: item.materialId,
            unit: item.unit,
            qty: parseInt(item.qty) - parseInt(retItem.qty),
            totalPrice:
              parseFloat(item.totalPrice) - parseFloat(retItem.totalPrice),
          };
          dataArray.push(updatedValue);
        } else {
          let value_passed_without_changing = {
            materialName: item.materialName,
            matId: item.matId,
            materialId: item.materialId,
            unit: item.unit,
            qty: item.qty,
            totalPrice: parseFloat(item.totalPrice),
          };
          dataArray.push(value_passed_without_changing);
        }
      } else {
        let updatedData = {
          materialName: item.materialName,
          matId: item.matId,
          materialId: item.materialId,
          unit: item.unit,
          qty: item.qty,
          totalPrice: parseFloat(item.totalPrice),
        };
        dataArray.push(updatedData);
      }

      return dataArray;
    });

    let data3 = data2.flat();

    if (data3 && data3.length > 0) {
      const data4 = data3.map((itemObj, index) => {
        return itemObj.totalPrice;
      });
      const sum = data4.reduce((partialSum, a) => partialSum + a, 0);
      return sum;
    }

    return 0;
  };

  // const handleFilterChange = () => {};

  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  const HandleFilterOption = () => {
    return (
      <div>
        {filterType === metadata.FILTERBYOTHERJOB ? (
          <div>
            <Select input={inputs.otherjob} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.FILTERBYDATE ? (
          <div className="flex flex-col items-center">
            <Input input={inputs.startDate} handleChange={handleChange} />
            <Input input={inputs.endDate} handleChange={handleChange} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const TableData = ({ dataSource }) => {
    const TableHeader = () => {
      return (
        <div className=" flex items-center justify-between gap-2">
          <h2 className="font-black text-black text-xl">
            IGRID HOLDINGS (PVT) LTD
          </h2>
          <div className="items-start font-bold">OtherJob Costing Sheet</div>
        </div>
      );
    };

    const HandleTHeaderData = () => {
      return (
        <thead className="bg-slate-200 font-bold">
          <tr>
            <th>Id</th>
            <th>description</th>
            <th>Unit</th>
            <th>Quantity</th>
            <th>Total Amount (LKR)</th>
          </tr>
        </thead>
      );
    };

    const HandleTBodyData = () => {
      return (
        <tbody className="text-wrap items-center">
          {dataSource.map((itemObj) => {
            const materialBomList = itemObj.bomMaterials;
            const returnMaterialBomList = itemObj.returnBomMaterials;

            let matbomList = [];
            let retbomList = [];

            const matBomCountArray = materialBomList?.map((materialBomObj) => {
              const matItemObjArray = materialBomObj?.materials;
              const data = matItemObjArray?.map((matitemObj) => {
                const matQty = matitemObj?.quantity;
                const matId = matitemObj?.material;

                const selectedMat =
                  materials &&
                  materials?.find((item) => {
                    return item.id === matId;
                  });

                return {
                  materialName: selectedMat?.title,
                  matId: selectedMat?.id,
                  materialId: selectedMat?.materialId,
                  unit: selectedMat?.unit,
                  qty: matQty,
                  totalPrice:
                    parseFloat(selectedMat?.unitPrice) * parseFloat(matQty),
                };
              });
              return data;
            });

            if (matBomCountArray && matBomCountArray.length > 0) {
              const flatmatBomCountArray = matBomCountArray.flat();

              const result1 = flatmatBomCountArray.reduce((acc, curr) => {
                const existingItemIndex = acc.findIndex(
                  (item) => item.matId === curr.matId
                );

                if (existingItemIndex !== -1) {
                  acc[existingItemIndex].qty = (
                    parseInt(acc[existingItemIndex].qty) + parseInt(curr.qty)
                  ).toString();
                  acc[existingItemIndex].totalPrice += curr.totalPrice;
                } else {
                  acc.push(curr);
                }
                return acc;
              }, []);

              matbomList = [...result1];
            }

            const returnmatCountArray = returnMaterialBomList?.map(
              (materialBomObj) => {
                const matItemObjArray = materialBomObj?.materials;
                const data = matItemObjArray?.map((matitemObj) => {
                  const matQty = matitemObj?.quantity;
                  const matId = matitemObj?.material;

                  const selectedMat =
                    materials &&
                    materials?.find((item) => {
                      return item.id === matId;
                    });

                  return {
                    materialName: selectedMat?.title,
                    matId: selectedMat?.id,
                    materialId: selectedMat?.materialId,
                    unit: selectedMat?.unit,
                    qty: matQty,
                    totalPrice:
                      parseFloat(selectedMat?.unitPrice) * parseFloat(matQty),
                  };
                });
                return data;
              }
            );

            if (returnmatCountArray && returnmatCountArray.length > 0) {
              const flatreturnmatCountArray = returnmatCountArray.flat();

              const result2 = flatreturnmatCountArray.reduce((acc, curr) => {
                const existingItemIndex = acc.findIndex(
                  (item) => item.matId === curr.matId
                );

                if (existingItemIndex !== -1) {
                  acc[existingItemIndex].qty = (
                    parseInt(acc[existingItemIndex].qty) + parseInt(curr.qty)
                  ).toString();
                  acc[existingItemIndex].totalPrice += curr.totalPrice;
                } else {
                  acc.push(curr);
                }
                return acc;
              }, []);
              retbomList = [...result2];
            }

            const data2 = matbomList.map((item) => {
              let dataArray = [];
              if (retbomList.length > 0) {
                let retItem = retbomList.find(
                  (retItem) => retItem.matId === item.matId
                );
                if (retItem) {
                  let updatedValue = {
                    materialName: item.materialName,
                    matId: item.matId,
                    materialId: item.materialId,
                    unit: item.unit,
                    qty: parseInt(item.qty) - parseInt(retItem.qty),
                    totalPrice:
                      parseFloat(item.totalPrice) -
                      parseFloat(retItem.totalPrice),
                  };
                  dataArray.push(updatedValue);
                } else {
                  let value_passed_without_changing = {
                    materialName: item.materialName,
                    matId: item.matId,
                    materialId: item.materialId,
                    unit: item.unit,
                    qty: item.qty,
                    totalPrice: parseFloat(item.totalPrice),
                  };
                  dataArray.push(value_passed_without_changing);
                }
              } else {
                let updatedData = {
                  materialName: item.materialName,
                  matId: item.matId,
                  materialId: item.materialId,
                  unit: item.unit,
                  qty: item.qty,
                  totalPrice: parseFloat(item.totalPrice),
                };
                dataArray.push(updatedData);
              }

              return dataArray;
            });

            let data3 = data2.flat();

            if (data3 && data3.length > 0) {
              return (
                <Fragment key={itemObj.key}>
                  <tr className="bg-yellow-200">
                    <td>{itemObj.otherJobId}</td>
                    <td></td>
                    <td colSpan={3}></td>
                  </tr>
                  <tr>
                    {data3.map((itemObj, index) => {
                      return (
                        <Fragment key={index}>
                          <td></td>
                          <td>{itemObj.materialName}</td>
                          <td>{itemObj.unit}</td>
                          <td>{itemObj.qty}</td>
                          <td>{itemObj.totalPrice.toFixed(2)}</td>
                        </Fragment>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            }

            return (
              <Fragment key={itemObj.key}>
                <tr className="bg-red-200">
                  <td>{itemObj.otherJobId}</td>
                  <td></td>
                  <td colSpan={3}></td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={4}>No Data Available</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      );
    };

    const HandleTableBodyData = () => {
      return (
        <div className="content">
          <table className="w-[700px]">
            <HandleTHeaderData />
            <HandleTBodyData />
          </table>
        </div>
      );
    };

    return (
      <table className="table w-[700px]">
        <thead>
          <tr>
            <th>
              <TableHeader />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <HandleTableBodyData />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-content flex flex-col items-end mx-5 text-lg">
                <CustomFooter />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">OTHERJOB COSTING REPORT</p>
              <div className="mt-4">
                {/* radio buttons goes here */}
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>
          <div
            id="tableData"
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableData dataSource={tBody} />
          </div>
        </div>
      )}
    </section>
  );
}

export default OtherJobReport;
