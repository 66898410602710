import React from "react";
import { Route, Routes } from "react-router-dom";
import StockReport from "./sub-reports/StockReport";
import MaterialReport from "./sub-reports/MaterialReport";
import ExpenceReport from "./sub-reports/ExpenceReport";
import OtherJobReport from "./sub-reports/OtherJobReport";
import InqueryReport from "./sub-reports/InqueryReport";
import Home from "../Home";
import Sales from "../Sales";
import BalanceReport from "./sub-reports/BalanceReport";
import PaymentsReport from "./sub-reports/PaymentsReport";
import OutstandingReport from "./sub-reports/OutstandingReport";

function index() {
  return (
    <Routes>
      {/* add different routes athat are needed for each Report type */}
      <Route path="/stock" element={<StockReport />} />
      <Route path="/payments" element={<PaymentsReport />} />
      <Route path="/outstandings" element={<OutstandingReport />} />
      <Route path="/materials" element={<MaterialReport />} />
      <Route path="/expence" element={<ExpenceReport />} />
      <Route path="/other-jobs" element={<OtherJobReport />} />
      <Route path="/job" element={<InqueryReport />} />
      <Route path="/balance" element={<BalanceReport />} />
      <Route path="/" element={<Sales />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

export default index;
