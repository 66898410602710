import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../http";
import { toast } from "react-toastify";

import { Table } from "antd";
import { Button, Select } from "../../../components/ui";
import Loading from "../../../components/ui/Loading";

function PurchsingOrderImportModel({
  handleClose,
  purchasingOrderId,
  isSingle,
  visible,
}) {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      optTitle: "a material",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    supplier: {
      key: "supplier",
      optTitle: "a supplier",
      label: "Supplier",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
  };
  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [refreshData, setRefreshData] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [poDetails, setPoDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    const oldData = input_list[input.key];
    input.data = [...oldData.data, input.data];
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleRefresh = () => {
    setRefreshData(!refreshData);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const materials = await apiService.get("material");
    const suppliers = await apiService.get("supplier");
    setMaterials(materials);
    setSuppliers(suppliers);

    const matOptList = materials.map((material) => {
      return {
        id: material.id,
        title: `${material.materialId} - ${material.title}`,
      };
    });
    const supOptList = suppliers.map((supplier) => {
      return {
        id: supplier.id,
        title: `${supplier.supplierId} - ${supplier.title}`,
      };
    });

    input_list.material.optList = matOptList;
    input_list.supplier.optList = supOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleData = async () => {
    const pdData = await apiService.post("purchsingOrder/podetails", {
      id: purchasingOrderId,
    });

    setPoDetails(pdData);
  };
  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const handleId = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material?.materialId;
  };
  const handleName = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material?.title;
  };
  const handleUnit = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material?.unit;
  };

  const handleInput = (args) => {
    const supplierId = args.supplierId;

    // get selected suplier based on id
    const selectedSupplier = suppliers.find(
      (supObj) => supObj.id === supplierId
    );

    return (
      <div className="w-full items-center">
        {" "}
        {!isSingle ? (
          <Select
            input={inputs.supplier}
            handleChange={handleChange}
            pod={args.poDetailsId}
          />
        ) : (
          <div className="font-bold items-center justify-center flex p-2">
            {selectedSupplier?.title}
          </div>
        )}
      </div>
    );
  };

  const actionBtns = (poData) => {
    // const disabled = poData.supplierId == null ? false : true;
    const supplierIdOBJ = !isSingle
      ? inputs.supplier.data
      : { sup: poData.supplierId, pod: poData.poDetailsId };
    return (
      <div className="flex">
        <Button
          text="Submit"
          //   implement conditional way tohandle button state
          // disabled={disabled}
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            // handle logic on submit supplier
            await handleSubmit([supplierIdOBJ], poData.poDetailsId);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "MaterialID",
      key: "materialId",
      width: "20%",
      render: (arg) => <p>{handleId(arg?.materialId)}</p>,
    },
    {
      title: "Material",
      key: "material",
      width: "10%",
      render: (arg) => <p>{handleName(arg?.materialId)}</p>,
    },
    {
      title: "Unit",
      key: "material",
      width: "10%",
      render: (arg) => <p>{handleUnit(arg?.materialId)}</p>,
    },
    {
      title: "Quantity",
      key: "quantity",
      width: "10%",
      render: (arg) => <p>{arg?.qty}</p>,
    },
    {
      title: "Supplier",
      key: "supplier",
      width: "100%",
      render: (arg) => handleInput(arg),
    },
    {
      title: "Action",
      key: "supplier",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const tBody = poDetails?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleSubmit = async (suppliersList, poDetailsId) => {
    const event = window.event;
    event.preventDefault();
    try {
      const selectedSupplier = suppliersList.find((item) => {
        return item.pod === poDetailsId;
      });

      if (!selectedSupplier) {
        throw new Error("Supplier not found");
      }

      const data = {
        suplierId: selectedSupplier.sup,
        poDetailsId: poDetailsId,
      };

      setIsLoading(true);

      await apiService.patch("purchsingOrder/addpotoMaterial", data);
      toast.success("Materials updated successfully!");
      setIsLoading(false);
      handleRefresh();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_4"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl  lg:w-11/12  lg:max-w-none rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            Import materials from Purchsing Order
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="flex flex-col">
          <div className="w-full p-6">
            {isLoading ? (
              <Loading />
            ) : (
              <Table
                columns={columns}
                dataSource={tBody}
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default PurchsingOrderImportModel;
