import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const SupervisorModal = ({ handleClose, selectedSupervisor, visible }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      label: "Title",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    phoneNumber: {
      key: "phoneNumber",
      label: "Phone Number",
      data: "",
      required: true,
      type: "number",
      error: null,
    },
    address: {
      key: "address",
      label: "Address",
      data: "",
      required: true,
      type: "text",
      error: null,
    },

    type: {
      key: "type",
      label: "Supervisor Type",
      data: "",
      optList: [
        { id: "site", title: "Site" },
        { id: "office", title: "Office" },
      ],
      error: null,
    },

    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    if (selectedSupervisor) {
      let input_list = { ...inputs };

      input_list.title.data = selectedSupervisor.title;
      input_list.remarks.data = selectedSupervisor.remarks;
      input_list.phoneNumber.data = selectedSupervisor.phoneNumber;
      input_list.address.data = selectedSupervisor.address;
      input_list.type.data = selectedSupervisor.address;

      if (input_list.type.data === "office") {
        input_list.type.placeHolder = "Office";
      } else {
        input_list.type.placeHolder = "Site";
      }

      setInputs(input_list);
    }
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      phoneNumber: inputs.phoneNumber.data,
      address: inputs.address.data,
      type: inputs.type.data,
      remarks: inputs.remarks.data,
    };

    try {
      setIsLoading(true);
      if (selectedSupervisor) {
        formData.id = selectedSupervisor.id;
        await apiService.patch("supervisor", formData);
        toast.success("Supervisor updated successfully!");
      } else {
        await apiService.post("supervisor", formData);
        toast.success("Supervisor added successfully!");
      }
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {" "}
            {!selectedSupervisor && "Add new "}
            {selectedSupervisor && "Edit "}
            Supervisor
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Input input={inputs.title} handleChange={handleChange} />

          <Input input={inputs.phoneNumber} handleChange={handleChange} />
          <Input input={inputs.address} handleChange={handleChange} />
          <Select input={inputs.type} handleChange={handleChange} />

          {/* <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        /> */}
          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={selectedSupervisor ? "Edit" : "Create"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default SupervisorModal;
