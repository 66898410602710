import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../http";
import { toast } from "react-toastify";
import { Button, Input, Select } from "../../../components/ui";

function OtherJobModel({ handleClose, selectedOtherJob, visible }) {
  const inputDataStructure = {
    customerName: {
      key: "customerName",
      label: "Customer Name",
      optTitle: "Select customer",
      data: "",
      namedata: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    supervisor: {
      key: "supervisor",
      required: true,
      label: "Supervisor",
      data: null,
      type: "text",
      error: null,
    },
    customerNic: {
      key: "customerNic",
      required: true,
      label: "Customer NIC",
      data: "",
      type: "text",
      error: null,
    },
    customerPhoneNumber: {
      key: "customerPhoneNumber",
      required: true,
      label: "Customer Phone Number",
      data: "",
      type: "number",
      error: null,
    },

    description: {
      key: "description",
      label: "Description",
      data: "",
      type: "text",
      error: null,
    },
    location: {
      key: "location",
      label: "Location",
      data: "",
      type: "text",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  useEffect(() => {
    const handleDataFill = () => {
      const customerid = inputs.customerName.data;

      if (customerid) {
        const selectdCustomer = customers.find(
          (customerData) => customerData.id === customerid
        );

        if (selectdCustomer) {
          let input_list = { ...inputs };
          input_list.customerPhoneNumber.data = selectdCustomer.phoneNumber;
          input_list.location.data = selectdCustomer.address;
          input_list.customerNic.data = selectdCustomer.customerNIC;
          input_list.customerName.namedata = selectdCustomer.title;
          setInputs(input_list);
        }
      }
    };
    handleDataFill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.customerName.data]);

  const buildForm = async () => {
    let input_list = { ...inputs };
    const supervisors = await apiService.get("supervisor");
    const customersList = await apiService.get("customer");
    input_list.supervisor.optList = supervisors;
    input_list.customerName.optList = customersList;

    if (selectedOtherJob) {
      const selectedCustomer = customersList.find(
        (customer) =>
          customer.title === selectedOtherJob.customerName &&
          customer.phoneNumber === selectedOtherJob.customerPhoneNumber
      );

      input_list.supervisor.data = selectedOtherJob.supervisor.id;
      input_list.supervisor.placeHolder = selectedOtherJob.supervisor.name;
      input_list.customerName.data = selectedCustomer.id;
      input_list.customerName.namedata = selectedCustomer.title;
      input_list.customerName.placeHolder = selectedOtherJob.customerName;
      input_list.customerNic.data = selectedOtherJob.customerNic;
      input_list.customerPhoneNumber.data =
        selectedOtherJob.customerPhoneNumber;
      input_list.description.data = selectedOtherJob.description;
      input_list.location.data = selectedOtherJob.location;
    }
    setInputs(input_list);
    setCustomers(customersList);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      supervisor: inputs.supervisor.data,
      customerName: inputs.customerName.namedata,
      customerNic: inputs.customerNic.data,
      customerPhoneNumber: inputs.customerPhoneNumber.data,
      description: inputs.description.data,
      location: inputs.location.data,
    };

    try {
      setIsLoading(true);
      await apiService.post("other-jobs", data);
      toast.success("OtherJOb added successfully!");
      setIsLoading(false);
      handleClose();
    } catch (err) {
      toast.error(err);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {!selectedOtherJob && "Add new "}
            {selectedOtherJob && "Edit "}Job
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Select input={inputs.supervisor} handleChange={handleChange} />
          <Select
            input={inputs.customerName}
            isaddNewNeeded={true}
            handleChange={handleChange}
          />

          <Input input={inputs.customerNic} handleChange={handleChange} />
          <Input
            input={inputs.customerPhoneNumber}
            handleChange={handleChange}
          />
          <Input input={inputs.description} handleChange={handleChange} />
          <Input input={inputs.location} handleChange={handleChange} />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={selectedOtherJob ? "Edit" : "Create"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default OtherJobModel;
