import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../../http";
import LoadingAnimation from "../../../components/Loader";
import { Table } from "antd";
import { Button } from "../../../components/ui";
import PayOutstandingModule from "./models/PayOutstandingModule";
import ViewPaidOutstandingModule from "./models/ViewPaidOutstandingModule";

function Outstanding() {
  const { id } = useParams();

  const [outstandingData, setOutStandingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPo, setSelectedPO] = useState(null);
  const [paymentModule, setPaymentModule] = useState(false);
  const [paidoutstandingModule, setPaidoutstandingModule] = useState(false);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(
        `payment/viewoutstandingbysupplier/${id}`
      );
      setOutStandingData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const outstandingActionBtns = (material) => {
    return (
      <div className="wrap">
        <Button
          text="Pay"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedPO(material);
            setPaymentModule(!paymentModule);
          }}
        />
        <Button
          text="View Paid Outstandings"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedPO(material);
            setPaidoutstandingModule(!paidoutstandingModule);
          }}
        />
      </div>
    );
  };

  const handleCloseModal = () => {
    setPaymentModule(false);
    setPaidoutstandingModule(false);
    setSelectedPO(null);
    getData();
  };

  const columnsPO = [
    {
      title: "ID",
      key: "purchsingOrderId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.purchsingOrderId.localeCompare(b.purchsingOrderId),
      render: (args) => {
        const poID = args.purchsingOrderId;
        const singlepoId = args.supBasedPOId;
        return <div>{singlepoId && singlepoId !== "" ? singlepoId : poID}</div>;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (args) => {
        const date = new Date(args.PoCreatedDate);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Purchsing Order Status",
      align: "center",
      width: "10%",
      render: (arg) => {
        const status = arg.poStatus;

        return (
          <div
            className={
              status === "pending"
                ? "badge badge-warning gap-2 p-4 text-md font-bold text-white"
                : "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {arg.poStatus.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Total Amount(LKR)",
      align: "center",
      width: "20%",
      render: (arg) => {
        const payData = arg.payData[0];

        var totalValue = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const totalAmount = payData.paymentData.totalAmount;
          totalValue = totalAmount;
        }

        return (
          <div
            className={
              totalValue !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {formatter.format(totalValue)}
          </div>
        );
      },
    },
    {
      title: "Initial Paid Amount(LKR)",
      align: "center",
      width: "20%",
      render: (arg) => {
        const payData = arg.payData[0];

        var paid = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const paidAmount = payData.paymentData.paidamount;
          paid = formatter.format(paidAmount);
        }

        return (
          <div
            className={
              paid !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {paid}
          </div>
        );
      },
    },
    {
      title: "Outstanding Amount",
      align: "center",
      width: "20%",
      render: (arg) => {
        // const poPaymentArray = arg.payData;
        const poOutstandingArray = arg.payout;

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return (
          <div
            className={
              "badge badge-error gap-2 p-4 text-md font-bold text-white"
            }
          >
            {formatter.format(~~poOutstandingArray[0]?.outstandingAmount)}
          </div>
        );
      },
    },
    {
      title: "Operations",
      align: "center",
      width: "30%",
      render: (arg) => outstandingActionBtns(arg),
    },
  ];

  const tBody = outstandingData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {paymentModule && (
        <PayOutstandingModule
          selectedMaterial={selectedPo}
          handleClose={handleCloseModal}
          visible={paymentModule}
        />
      )}
      {paidoutstandingModule && (
        <ViewPaidOutstandingModule
          selectedMaterial={selectedPo}
          supplierId={id}
          handleClose={handleCloseModal}
          visible={paidoutstandingModule}
        />
      )}
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="p-2 gap-2 items-center">
            {outstandingData && outstandingData.length > 0 ? (
              <div className="flex flex-col items-start justify-between">
                <div className="text-xl font-medium py-2">
                  Supplier Outstanding Details
                </div>
                <Table
                  columns={columnsPO}
                  dataSource={tBody}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-xl font-medium">
                No data Available
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default Outstanding;
