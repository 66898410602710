import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import ProductModal from "./ProductModal";

import { Input, Button, Confirm, Select } from "../../../components/ui";
import SingleItem from "./SingleItem";
import HeadingRow from "./HeadingRow";
import apiService from "../../../http";

const Projects = () => {
    const [items, setItems] = useState([]);
    const [customers, setCustomers] = useState([]);

    const [productModalState, setProductModalState] = useState(false);
    const [confirmModalState, setConfirmModalState] = useState(false);

    const inputDataStructure = {
        customerType: {
            key: "customerType",
            required: true,
            label: "Select customer type",
            optList: [
                { id: "existing", title: "Existing customer" },
                { id: "new", title: "New customer" },
            ],
            data: "new",
            type: "select",
            error: null,
        },
        customer: {
            key: "customer",
            required: true,
            label: "Select a customer",
            optList: [],
            data: null,
            type: "text",
            error: null,
        },
        customerName: {
            key: "customerName",
            required: true,
            label: "Customer Name",
            data: "",
            type: "text",
            error: null,
        },
        customerPhone: {
            key: "customerPhone",
            required: true,
            label: "Customer Phone",
            data: "",
            type: "text",
            error: null,
        },
        customerAddress: {
            key: "customerAddress",
            label: "Customer Address",
            required: true,
            data: "",
            type: "text",
            error: null,
        },
        total: {
            key: "total",
            label: "Total",
            readOnly: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
        discount: {
            key: "discount",
            label: "Discount",
            required: true,
            min: 0,
            max: 100,
            data: 0,
            type: "number",
            error: null,
        },
        netTotal: {
            key: "netTotal",
            label: "Net Total",
            readOnly: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleCloseModal = () => {
        setProductModalState(false);
        setConfirmModalState(false);
    };

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.key === "discount") {
            calcTotal();
        }

        if (input.key === "customer") {
            const customer = customers.find((item) => {
                return item.id === input.data;
            });

            input_list.customerName.data = customer.title;
            input_list.customerPhone.data = customer.phoneNumber;
            input_list.customerAddress.data = customer.address;
        }

        setInputs(input_list);
    };

    const calcTotal = () => {
        let total = 0;

        items.forEach((item) => {
            total += item.quantity * item.price;
        });

        let input_list = { ...inputs };
        const netTotal = total - (total * inputs.discount.data) / 100;
        input_list.total.data = total;
        input_list.netTotal.data = netTotal;
        setInputs(input_list);
    };

    const handleAdd = (item) => {
        let itemList = items;

        item.posId = uuidv4();
        items.push(item);
        setItems(itemList);

        toast.success("Item added to the sale");
        handleCloseModal();
        calcTotal();
    };
    const handleRemove = (id) => {
        let itemList = items;

        itemList = itemList.filter((item) => {
            return item.posId !== id;
        });

        setItems(itemList);
        toast.success("Item removed from the sale");
        handleCloseModal();
        calcTotal();
    };
    const submitSale = async () => {
        const formData = {
            items,
            discount: inputs.discount.data,
            customerName: inputs.customerName.data,
            customerPhone: inputs.customerPhone.data,
            customerAddress: inputs.customerAddress.data,
        };

        if (inputs.customerType.data === "existing") {
            if (!inputs.customer.data) {
                toast.error("please select a customer!");
                handleCloseModal();
                return;
            }

            const customer = customers.find((item) => {
                return item.id === inputs.customer.data;
            });

            formData.customerId = customer.customerId;
        } else {
            formData.customerId = "N/A";
        }

        if (!inputs.customerType.data) {
            toast.error("please select a customer type!");
            handleCloseModal();
            return;
        }
        try {
            await apiService.post("sale", formData);
            toast.success("Order placed successfully!");
            setItems([]);
        } catch (e) {
            toast.error(e);
        }
        handleCloseModal();
    };

    const getCustomers = async () => {
        let customers = await apiService.get("customer");

        const optList = customers.map((customer) => {
            return { id: customer.id, title: customer.title };
        });

        let input_list = { ...inputs };

        input_list.customer.optList = optList;

        setInputs(input_list);
        setCustomers(customers);
    };

    useEffect(() => {
        getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="w-full mt-6">
            {productModalState && (
                <ProductModal
                    handleAdd={handleAdd}
                    handleClose={handleCloseModal}
                />
            )}

            {confirmModalState && (
                <Confirm
                    actionLabel="OK"
                    title="Place Order"
                    confirmMsg="Are you sure you want to place the order?"
                    handleAdd={handleAdd}
                    cancelHandler={handleCloseModal}
                    confirmHandler={submitSale}
                />
            )}

            <div className="flex w-full">
                <div className="w-[80%]">
                    <div className="flex gap-3 bg-white px-2 py-2 mb-3 rounded-md">
                        <Button
                            text={"Add Inquiry"}
                            handleClick={() => setProductModalState(true)}
                        />
                    </div>
                    <div>
                        <table className="w-[96%] mx-auto">
                            <HeadingRow />
                            {items.map((item, index) => {
                                return (
                                    <SingleItem
                                        item={item}
                                        number={index + 1}
                                        key={item.posId}
                                        handleRemove={handleRemove}
                                    />
                                );
                            })}
                        </table>
                    </div>
                </div>
                <div className="w-[20%] mr-6">
                    <Select
                        handleChange={handleChange}
                        input={inputs.customerType}
                    />
                    {inputs.customerType.data === "existing" && (
                        <Select
                            handleChange={handleChange}
                            input={inputs.customer}
                        />
                    )}
                    <Input
                        handleChange={handleChange}
                        input={inputs.customerName}
                    />
                    <Input
                        handleChange={handleChange}
                        input={inputs.customerPhone}
                    />
                    <Input
                        handleChange={handleChange}
                        input={inputs.customerAddress}
                    />
                    <Input handleChange={handleChange} input={inputs.total} />
                    <Input
                        handleChange={handleChange}
                        input={inputs.discount}
                    />
                    <Input
                        handleChange={handleChange}
                        input={inputs.netTotal}
                    />
                    <Button
                        handleClick={() => {
                            if (inputs.total.data === 0) {
                                toast.error("Item list is empty!");
                                return;
                            }
                            setConfirmModalState(true);
                        }}
                        className={"w-full mt-4"}
                        text={"Place Order"}
                    />
                </div>
            </div>
        </section>
    );
};

export default Projects;
