import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ExpenseTypeModal = ({ handleClose, selectedExpenseType, visible }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      label: "Title",
      data: "",
      required: true,
      type: "text",
      error: null,
    },
    expenceType: {
      key: "expenceType",
      label: "Expence Type",
      data: "direct",
      optList: [
        { id: "direct", title: "Direct Expences" },
        { id: "indirect", title: "Indirect Expences" },
      ],
      error: null,
      required: true,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    if (selectedExpenseType) {
      let input_list = { ...inputs };
      var selectedPlace = "choose";
      var placeHolderNames = ["Direct Expences, Indirect Expence"];

      if (selectedExpenseType.type === "direct") {
        selectedPlace = placeHolderNames[0];
      }
      if (selectedExpenseType.type === "indirect") {
        selectedPlace = placeHolderNames[1];
      }

      input_list.title.data = selectedExpenseType.title;
      input_list.expenceType.data = selectedExpenseType.type;
      input_list.expenceType.placeHolder = selectedPlace;

      setInputs(input_list);
    }
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      type: inputs.expenceType.data,
    };

    try {
      setIsLoading(true);
      if (selectedExpenseType) {
        formData.id = selectedExpenseType.id;
        await apiService.patch("expense-type", formData);
        toast.success("ExpenseType updated successfully!");
      } else {
        await apiService.post("expense-type", formData);
        toast.success("ExpenseType added successfully!");
      }
      setIsLoading(false);

      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {!selectedExpenseType && "Add new "}
            {selectedExpenseType && "Edit "}
            Expense Type
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Input input={inputs.title} handleChange={handleChange} />
          <Select input={inputs.expenceType} handleChange={handleChange} />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={selectedExpenseType ? "Edit" : "Create"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleCloseModel}>close</button> */}
      </form>
    </dialog>
  );
};

export default ExpenseTypeModal;
