import React, { useEffect, useState } from "react";
import apiService from "../../../http";
import AddUserModel from "./adduserModel/AddUserModel";
import LoadingAnimation from "../../../components/Loader";
import { Button, Input } from "../../../components/ui";
import { Table } from "antd";

function Users() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };
  const [inputs, setInputs] = useState(inputDataStructure);

  const [userState, setUserState] = useState(false);

  const actionBtns = (job) => {
    return (
      <div className="flex flex-col items-center justify-between lg:flex-row">
        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedUser(job);
            setUserState(true);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "user email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "30%",
      sorter: (a, b) => a.jobId.localeCompare(b.jobId),
    },
    {
      title: "user Type",
      align: "center",
      dataIndex: "userType",
      width: "30%",
      render: (arg) => {
        return arg;
      },
    },
    {
      title: "Actions",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleCloseModal = () => {
    setUserState(false);
    refreshInquiries();
  };

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const usersData = await apiService.get("user/users");

      setFilteredData(usersData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshInquiries();
  }, []);

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {userState && (
        <AddUserModel
          selectedUser={selectedUser}
          handleClose={handleCloseModal}
        />
      )}

      <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button text={"New User"} handleClick={() => setUserState(true)} />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
}

export default Users;
