import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../../../http";

import { toast } from "react-toastify";
import { Button, Input, Select } from "../../../../../../components/ui";

function AdvanceModel({ handleClose, otherJob, visible }) {
  const inputDataStructure = {
    amount: {
      key: "amount",
      label: "Amount (LKR)",
      data: "",
      type: "number",
      min: 0,
      error: null,
    },
    advanceType: {
      key: "advanceType",
      label: "Invoice Type",
      data: "advAmount",
      optList: [
        { id: "advAmount", title: "Advance Amount" },
        { id: "confFee", title: "Confirmation Fee" },
        { id: "estCharges", title: "Estimated Charges" },
        { id: "clearCharges", title: "LECO/CEB Clearances" },
      ],
      error: null,
      required: true,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  useEffect(() => {
    if (0) {
      let input_list = { ...inputs };
      input_list.amount.data = otherJob.advancedAmount;
    }

    // setInputs(input_list);
  }, [inputs, otherJob.advancedAmount]);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      amount: inputs.amount.data,
      id: otherJob._id,
      advanceType: inputs.advanceType.data,
    };
    // data.append("amount", inputs.amount.data);
    // data.append("id", otherJob._id);

    // console.log(data);

    try {
      setIsLoading(true);
      await apiService.patch("other-jobs/advance", data);
      toast.success("Quotation updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Advance Payment</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <Select input={inputs.advanceType} handleChange={handleChange} />
          <Input input={inputs.amount} handleChange={handleChange} />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={"Submit"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default AdvanceModel;
