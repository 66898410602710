import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../components/logo/Logo";
import { Button, Input, Select } from "../../../../components/ui";

import { v4 as uuidv4 } from "uuid";
import apiService from "../../../../http";
import LoadingAnimation from "../../../../components/Loader";

const inputDataStructure = {
  unit: {
    key: "unit",
    label: "Select Unit",
    optTitle: "",
    data: "",
    optList: [],
    required: true,
    type: "text",
    error: null,
  },
  amount: {
    key: "amount",
    label: "Amount (LKR)",
    data: "",
    type: "number",
    min: 0,
    error: null,
    required: true,
  },
  title: {
    key: "title",
    label: "Add Title",
    data: "",
    type: "text",
    error: null,
    required: true,
  },
  description: {
    key: "description",
    label: "Add description",
    data: "",
    type: "text",
    error: null,
    required: true,
  },
};
const metadata = {
  ASSET: "Assets",
  LIABILITIES: "Liabilities and Owner's Equity",
};
const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const HandleFilterOption = ({
  inputs,
  handleChange,
  handleAddData,
  handleImport,
  handleStock,
  isAdded,
}) => {
  return (
    <div className="flex p-2 flex-col items-center space-y-2 divide-y-2">
      <div className="flex items-center justify-between gap-2">
        <Input input={inputs.title} handleChange={handleChange} />
        <Button handleClick={handleAddData} className="mt-6" text={"Add"} />
        <div className="divider divider-horizontal"></div>
        <Button
          handleClick={handleStock}
          disabled={isAdded ? true : false}
          className="mt-6"
          text={"Get Remaining Asset Values From Stock"}
        />
      </div>
      <div className="flex items-center justify-between gap-2">
        <Select input={inputs.unit} handleChange={handleChange} />
        <Input input={inputs.description} handleChange={handleChange} />
        <Input input={inputs.amount} handleChange={handleChange} />
        <Button handleClick={handleImport} className="mt-6" text={"import"} />
      </div>
    </div>
  );
};
const TableData = ({ dataSource }) => {
  const assetsArray = dataSource.assets;
  const libArray = dataSource.lib;
  const TableHeader = () => {
    return (
      <div className=" flex items-center justify-between gap-2">
        <h2 className="font-black text-black text-xl">
          IGRID HOLDINGS (PVT) LTD
        </h2>
        <div className="items-start font-bold">Balance Sheet</div>
      </div>
    );
  };

  const HandleTHeaderData = () => {
    return (
      <thead className="bg-slate-200 font-bold">
        <tr>
          <th>Id</th>
          <th>description</th>
          <th>Amount</th>
        </tr>
      </thead>
    );
  };

  const HandleTBodyData = () => {
    let totalAssetsAmount = 0;
    let totalLibsAmount = 0;

    return (
      <tbody className="text-wrap items-center">
        {assetsArray && assetsArray.length > 0 && (
          <>
            <tr className="bg-gray-200">
              <td>{metadata.ASSET}</td>
              <td colSpan={2}></td>
            </tr>
            {assetsArray.map((assetItemObj, index) => {
              const unit = assetItemObj.unit;
              const total = assetItemObj.total;
              const data = assetItemObj.data;

              totalAssetsAmount += parseFloat(total);

              return (
                <Fragment key={index}>
                  <tr className="bg-yellow-200">
                    <td></td>
                    <td colSpan={2}>{unit}</td>
                  </tr>
                  {data.map((dataobj, ind) => {
                    const amount = dataobj.amount;
                    const description = dataobj.description;

                    return (
                      <Fragment key={ind}>
                        <tr>
                          <td></td>
                          <td>{description}</td>
                          <td>{amount}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            <tr>
              <td colSpan={2}></td>
              <td>
                <span className="font-bold text-left">
                  LKR{" "}
                  {totalAssetsAmount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </td>
            </tr>
          </>
        )}
        {libArray && libArray.length > 0 && (
          <>
            <tr className="bg-gray-200">
              <td>{metadata.LIABILITIES}</td>
              <td colSpan={2}></td>
            </tr>
            {libArray.map((libItemObj, index) => {
              const unit = libItemObj.unit;
              const total = libItemObj.total;
              const data = libItemObj.data;

              totalLibsAmount += parseFloat(total);

              return (
                <Fragment key={index}>
                  <tr className="bg-yellow-200">
                    <td></td>
                    <td colSpan={2}>{unit}</td>
                  </tr>
                  {data.map((dataobj, ind) => {
                    const amount = dataobj.amount;
                    const description = dataobj.description;

                    return (
                      <Fragment key={ind}>
                        <tr>
                          <td></td>
                          <td>{description}</td>
                          <td>{amount}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            <tr>
              <td colSpan={2}></td>
              <td>
                <span className="font-bold text-left">
                  LKR{" "}
                  {totalLibsAmount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </td>
            </tr>
          </>
        )}
      </tbody>
    );
  };

  const HandleTableBodyData = () => {
    return (
      <div className="content">
        <table className="w-[700px]">
          <HandleTHeaderData />
          <HandleTBodyData />
        </table>
      </div>
    );
  };

  return (
    <table className="table w-[700px]">
      <thead>
        <tr>
          <th>
            <TableHeader />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <HandleTableBodyData />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function BalanceReport() {
  const [inputs, setInputs] = useState(inputDataStructure);
  const [filteredData, setFilteredData] = useState([]);

  const [assetData, setAssetData] = useState([]);
  const [liabilitiesData, setLiabilitiesData] = useState([]);

  const [materials, setMaterials] = useState([]);

  const ref = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isadded, setIsAdded] = useState(false);

  const [filterType, setFilterType] = useState(metadata.ASSET);

  const handleChange = useCallback((input) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [input.key]: input,
    }));
  }, []);

  const getMaterialsData = async () => {
    try {
      setIsLoading(true);
      const materialsData = await apiService.get("material");
      setMaterials(materialsData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaterialsData();
  }, []);

  const tBody = filteredData;

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handleViewData = () => {
    const dataObj = {
      assets: assetData,
      lib: liabilitiesData,
    };
    setFilteredData(dataObj);
  };

  const radioBtnOptions = [metadata.ASSET, metadata.LIABILITIES];

  const handleRadioClick = useCallback((selectedOption) => {
    setFilterType(selectedOption);
  }, []);

  const handleAddData = useCallback(() => {
    setInputs((prevInputs) => {
      const titleData = prevInputs.title.data;

      const uid = uuidv4();
      const dataObj = { id: uid, title: titleData };

      return {
        ...prevInputs,
        title: {
          ...prevInputs.title,
          data: "",
        },
        unit: {
          ...prevInputs.unit,
          optList: [...prevInputs.unit.optList, dataObj],
        },
      };
    });
  }, []);

  const handleImport = useCallback(() => {
    const descriptionData = inputs.description.data;
    const amountData = inputs.amount.data;
    const unitData = inputs.unit.data;
    const unitObjList = inputs.unit.optList;

    const dataObj = {
      unit: unitData,
      description: descriptionData,
      amount: parseFloat(amountData),
    };

    if (filterType === metadata.ASSET) {
      setAssetData((prev) => {
        const isAvailableUnitTitle = unitObjList.find(
          (item) => item.id === dataObj.unit
        );

        if (!isAvailableUnitTitle) return prev;

        const isavailUnitData = prev.find(
          (item) => item.unitid === dataObj.unit
        );

        if (!isavailUnitData) {
          return [
            ...prev,
            {
              unit: isAvailableUnitTitle.title,
              unitid: isAvailableUnitTitle.id,
              total: dataObj.amount,
              data: [
                {
                  amount: dataObj.amount,
                  description: dataObj.description,
                },
              ],
            },
          ];
        }

        return prev.map((item) => {
          if (item.unitid === dataObj.unit) {
            return {
              ...item,
              total: item.total + dataObj.amount,
              data: [
                ...item.data,
                {
                  amount: dataObj.amount,
                  description: dataObj.description,
                },
              ],
            };
          }
          return item;
        });
      });
    } else if (filterType === metadata.LIABILITIES) {
      setLiabilitiesData((prev) => {
        const isAvailableUnitTitle = unitObjList.find(
          (item) => item.id === dataObj.unit
        );

        if (!isAvailableUnitTitle) return prev;

        const isavailUnitData = prev.find(
          (item) => item.unitid === dataObj.unit
        );

        if (!isavailUnitData) {
          return [
            ...prev,
            {
              unit: isAvailableUnitTitle.title,
              unitid: isAvailableUnitTitle.id,
              total: dataObj.amount,
              data: [
                {
                  amount: dataObj.amount,
                  description: dataObj.description,
                },
              ],
            },
          ];
        }

        return prev.map((item) => {
          if (item.unit === dataObj.unit) {
            return {
              ...item,
              total: item.total + dataObj.amount,
              data: [
                ...item.data,
                {
                  amount: dataObj.amount,
                  description: dataObj.description,
                },
              ],
            };
          }
          return item;
        });
      });
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      description: {
        ...prevInputs.description,
        data: "",
      },
      amount: {
        ...prevInputs.amount,
        data: "",
      },
    }));
  }, [
    filterType,
    inputs.amount.data,
    inputs.description.data,
    inputs.unit.data,
    inputs.unit.optList,
  ]);

  const handleStock = useCallback(() => {
    const uid = uuidv4();
    const availableMaterials = materials.filter(
      (mat) => parseFloat(mat.stockCount) > 0
    );

    var totalMaterialBuyingCost = 0;

    const materialDataList = availableMaterials.map((materialObj) => {
      const val1 =
        parseFloat(materialObj.amount) * parseFloat(materialObj.stockCount);
      totalMaterialBuyingCost +=
        parseFloat(materialObj.amount) * parseFloat(materialObj.stockCount);
      return {
        amount: val1,
        description: materialObj.title,
      };
    });

    setAssetData((prv) => {
      return [
        ...prv,
        {
          unit: "Stock",
          unitid: uid,
          total: totalMaterialBuyingCost,
          data: materialDataList,
        },
      ];
    });
    setIsAdded(true);
  }, [materials]);

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">BALANCE REPORT</p>
              <div className="mt-4">
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption
                    inputs={inputs}
                    handleChange={handleChange}
                    handleAddData={handleAddData}
                    handleImport={handleImport}
                    handleStock={handleStock}
                    isAdded={isadded}
                  />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>

          <div
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableData dataSource={tBody} />
          </div>
        </div>
      )}
    </section>
  );
}

export default BalanceReport;
