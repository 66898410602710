import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../http";
import { Button, Input, TextArea } from "../../../../components/ui";
import { toast } from "react-toastify";
import StarRating from "../../../../components/ui/StarRating";

function FeedbackModel({ handleClose, inquiry, visible }) {
  const inputDataStructure = {
    remarks: {
      key: "remarks",
      label: "Remarks",
      placeholder: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
    rating: {
      key: "rating",
      label: "Rating",
      data: "",
      type: "number",
      error: null,
    },
  };
  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if(parseFloat(~~inputs.rating.data) < 0){
      toast.error("Enter a Valid Data");
    }

    const data = {
      rating: inputs.rating.data,
      remarks: inputs.remarks.data,
      id: inquiry.id,
    };

    try {
      setIsLoading(true);
      await apiService.post("inquiry/feedback", data);
      toast.success("Feedback added successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Customer Feedback</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="flex flex-col p-2">
          <Input input={inputs.rating} handleChange={handleChange} />
          <div className="flex items-center justify-center gap-2">
            <StarRating rating={inputs.rating.data} />
          </div>
          <TextArea input={inputs.remarks} handleChange={handleChange} />
          <div className="flex items-end w-full">
            <Button
              handleClick={handleSubmit}
              className="mt-6 w-full"
              text={"Submit"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  );
}

export default FeedbackModel;
