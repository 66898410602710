import React, { useEffect, useState } from "react";
import apiService from "../../../../http";
import { Button } from "../../../../components/ui";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProductMaterialModel from "./models/productMaterialModel/ProductMaterialModel";
import QutationModel from "./models/qutationModel/QutationModel";
import AdvanceModel from "./models/advanceModel/AdvanceModel";
import SiteDataModel from "./models/siteDataModel/SiteDataModel";
import ExpenceModel from "./models/expenceModel/ExpenceModel";
import WarrantyModel from "./models/warrantyModel/WarrantyModel";
import MaterialModel from "./models/materialsModel/MaterialModel";
import ContractorModel from "./models/contractormodel/ContractorModel";
import ReturningMaterialMoel from "./models/returnMaterialModel/ReturningMaterialMoel";
import ImageCarousal from "../../../../components/ui/carosal/ImageCarousal";
import FeedbackModel from "../../Inquiry/Feedback/FeedbackModel";
import { Table } from "antd";
import LoadingAnimation from "../../../../components/Loader";
import { useSelector } from "react-redux";
const axios = require("axios");

function OtherJobData() {
  const { id } = useParams();

  const [materialModalState, setMaterialModalState] = useState(false);
  const [expenseModalState, setExpenseModalState] = useState(false);
  const [quotationModalState, setQuotationModalState] = useState(false);
  const [advanceModalState, setAdvanceModalState] = useState(false);
  const [siteDataModalState, setSiteDataModalState] = useState(false);
  const [warrantyModalState, setWarrantyModelState] = useState(false);
  const [materialModelState, setMaterialModelState] = useState(false);
  const [contractorModelState, setContractorModelState] = useState(false);
  const [returnMaterialModelState, setReturnMaterialModelState] =
    useState(false);
  const [customerFeedbackModelState, setCustomerFeedbackModelState] =
    useState(false);

  const [otherJobData, setOtherJobData] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const [statusList] = useState(["ongoing", "stopped", "Finished", "Canceled"]);

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";

  const handleCloseModal = () => {
    setOtherJobData(null);
    setAdvanceModalState(false);
    setConfirmModalState(false);
    setMaterialModalState(false);
    setSiteDataModalState(false);
    setQuotationModalState(false);
    setExpenseModalState(false);
    setWarrantyModelState(false);
    setMaterialModelState(false);
    setContractorModelState(false);
    setReturnMaterialModelState(false);
    setCustomerFeedbackModelState(false);
    getData();
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(`other-jobs/${id}`);
      setIsLoading(false);
      setOtherJobData(data);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePDFLoad = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      const blob = response.data;

      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const statData = {
        updatedStatus: status,
        inqueryId: id,
      };

      await apiService.post("other-jobs/status", statData);
      toast.success("Status updated successfully!");
      handleCloseModal();
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  const tBody = otherJobData?.expenses.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const columns = [
    {
      title: "Expacnce Type",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "10%",
      // render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
  ];

  if (!otherJobData) return null;

  const total =
    parseFloat(~~otherJobData?.advancedPaidAmount) +
    parseFloat(~~otherJobData?.advancedestimatedChargesPaidAmount) +
    parseFloat(~~otherJobData?.advancedClearancePaidAmount) +
    parseFloat(~~otherJobData?.advancedCEBPaidAmount);

  const handleStatusClassname =
    otherJobData?.otherJobStatus === "stopped"
      ? "badge p-4 text-white font-bold bg-secondary my-2"
      : otherJobData?.otherJobStatus === "Finished"
      ? "badge p-4 text-white font-bold bg-primary my-2"
      : otherJobData?.otherJobStatus === "Canceled"
      ? "badge p-4 text-white font-bold bg-red-500 my-2"
      : "badge p-4 text-white font-bold bg-green-500 my-2";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "LKR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const isOutstanding = otherJobData.quotationAmount > total;

  const handleOnClick = (id, status) => {
    if ((otherJobData?.otherJobStatus === "Canceled") | isOutstanding) {
      return;
    }
    handleChangeStatus(id, status);
  };
  const handledisabled =
    (otherJobData?.otherJobStatus === "Canceled") |
    (otherJobData?.otherJobStatus === "stopped") |
    (otherJobData?.otherJobStatus === "Finished")
      ? true
      : false;
  return (
    <section className="w-full mt-6">
      {materialModalState && <ProductMaterialModel />}
      {quotationModalState && (
        <QutationModel
          visible={quotationModalState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {advanceModalState && (
        <AdvanceModel
          visible={advanceModalState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {siteDataModalState && (
        <SiteDataModel
          visible={siteDataModalState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {expenseModalState && (
        <ExpenceModel
          visible={expenseModalState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {warrantyModalState && (
        <WarrantyModel
          visible={warrantyModalState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {materialModelState && (
        <MaterialModel
          visible={materialModelState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {contractorModelState && (
        <ContractorModel
          visible={contractorModelState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {returnMaterialModelState && (
        <ReturningMaterialMoel
          visible={returnMaterialModelState}
          otherJob={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {customerFeedbackModelState && (
        <FeedbackModel
          visible={customerFeedbackModelState}
          inquiry={otherJobData}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        // add logic here
        <></>
      )}
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="flex flex-wrap gap-3 gap-y-0 bg-white px-2 py-2 mb-3 rounded-md">
            <Button
              text={"Upload quotation"}
              handleClick={() => setQuotationModalState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Site details"}
              handleClick={() => setSiteDataModalState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Advanced invoice"}
              handleClick={() => setAdvanceModalState(true)}
              disabled={
                !(handledisabled || isOffice || isSupervisor) ? false : true
              }
            />
            <Button
              text={"Manage materials"}
              handleClick={() => setMaterialModelState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Manage contracts"}
              handleClick={() => setContractorModelState(true)}
              disabled={
                !(handledisabled || isOffice || isSupervisor) ? false : true
              }
            />
            <Button
              text={"Site expenses"}
              handleClick={() => setExpenseModalState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Warranty Period"}
              handleClick={() => setWarrantyModelState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Return Material"}
              handleClick={() => setReturnMaterialModelState(true)}
              disabled={!(handledisabled || isOffice) ? false : true}
            />
            <Button
              text={"Customer Feedback"}
              handleClick={() => setCustomerFeedbackModelState(true)}
              disabled={
                !(handledisabled || isOffice || isSupervisor) ? false : true
              }
            />
          </div>
          <div>
            <div className="w-full">
              <div className="flex justify-center flex-row items-center md:justify-end gap-2 px-2">
                <div className={handleStatusClassname}>
                  {otherJobData.otherJobStatus}
                </div>
                <div className="dropdown dropdown-top dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className={
                      (otherJobData?.otherJobStatus === "Finished") |
                      (otherJobData?.otherJobStatus === "Canceled")
                        ? "btn bg-primary text-white hover:bg-slate-600 pointer-events-none"
                        : "btn bg-primary text-white hover:bg-slate-600"
                    }
                  >
                    Change Status
                  </div>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 "
                  >
                    {statusList.map((item, index) => {
                      const itemStatus = item === otherJobData.otherJobStatus;

                      if (!itemStatus) {
                        return (
                          <li
                            key={index}
                            className="font-medium gap-2 cursor-pointer border-y-2 p-2"
                            onClick={() => {
                              handleOnClick(otherJobData._id, item);
                            }}
                          >
                            {item}
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Customer details
                  </h3>
                  <img
                    src={require("../../../../assets/img/profile.png")}
                    alt=""
                    className="rounded-full w-[80px] mx-auto"
                  />
                  <ul className="mx-4 mt-4">
                    <li>Name : {otherJobData.customerName}</li>
                    <li>Phone number : {otherJobData.customerPhoneNumber}</li>
                  </ul>
                </div>
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Supervisor details
                  </h3>
                  <img
                    src={require("../../../../assets/img/profile.png")}
                    alt=""
                    className="rounded-full w-[80px] mx-auto"
                  />
                  <ul className="mx-4 mt-4">
                    <li>Name : {otherJobData.supervisor.name}</li>
                    <li>
                      Phone number : {otherJobData.supervisor.data.phoneNumber}
                    </li>
                  </ul>
                </div>
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Site details
                  </h3>
                  <ul className="mx-4">
                    <li>DC length : {otherJobData.dcLength}</li>
                    <li>AC length : {otherJobData.acLength}</li>
                    <li>Location : {otherJobData.location}</li>
                    {otherJobData.siteDataImage && (
                      <li>
                        Preview:{" "}
                        <ImageCarousal
                          imageArray={otherJobData.siteDataImage}
                        />
                      </li>
                    )}
                  </ul>
                </div>
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Download documents
                  </h3>
                  <ul className="mx-4 mt-4">
                    {otherJobData.confirmationInvFile && (
                      <li className="mt-2">
                        Confirmation invoice :
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/invoice/${otherJobData.confirmationInvFile}`
                            )
                          }
                          href={otherJobData.confirmationInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.advanceInvFile && (
                      <li className="mt-2">
                        Advance invoice :
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/invoice/${otherJobData.advanceInvFile}`
                            )
                          }
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.advanceEstInvFile && (
                      <li className="mt-2">
                        Advance Estimated invoice :
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/invoice/${otherJobData.advanceEstInvFile}`
                            )
                          }
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.advanceClearInvFile && (
                      <li className="mt-2">
                        Advance Clearance invoice :
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/invoice/${otherJobData.advanceClearInvFile}`
                            )
                          }
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.advanceConffInvFile && (
                      <li className="mt-2">
                        Conformation invoice :
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/invoice/${otherJobData.advanceConffInvFile}`
                            )
                          }
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.quotationFile && (
                      <li className="mt-2">
                        Quotation :{" "}
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/quotation/${otherJobData.quotationFile}`
                            )
                          }
                          href={otherJobData.quotationFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                    {otherJobData.warrantyFile && (
                      <li className="mt-2">
                        Warranty Document :{" "}
                        <button
                          disabled={!isSupervisor ? false : true}
                          onClick={() =>
                            handlePDFLoad(
                              `${process.env.REACT_APP_BACKEND_URL}public/warranty/${otherJobData.warrantyFile}`
                            )
                          }
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <br />
              <div className="px-2 py-4 gap-2 flex flex-col">
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Bill of Materials Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    {otherJobData.bomMaterials &&
                      otherJobData.bomMaterials.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between p-4 rounded-md bg-slate-200"
                          >
                            <p className="badge p-3 items-center">
                              {item.BOMID}
                            </p>
                            <button
                              onClick={
                                () =>
                                  handlePDFLoad(
                                    `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.BOMInvoiceID}.pdf`
                                  )
                                // printJS({
                                //   printable: `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.BOMInvoiceID}.pdf`,
                                //   type: "pdf",
                                //   showModal: true,
                                // })
                              }
                              type="button"
                              className="btn"
                            >
                              View
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Return Of Materials Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    {otherJobData.returnBomMaterials &&
                      otherJobData.returnBomMaterials.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between p-4 rounded-md bg-slate-200"
                          >
                            <p className="badge p-3 items-center">
                              {item.ReturnBOMID}
                            </p>
                            <button
                              onClick={
                                () =>
                                  handlePDFLoad(
                                    `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.ReturnBOMInvoiceID}.pdf`
                                  )
                                // printJS({
                                //   printable: `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.ReturnBOMInvoiceID}.pdf`,
                                //   type: "pdf",
                                //   showModal: true,
                                // })
                              }
                              href={`${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.ReturnBOMInvoiceID}.pdf`}
                              type="button"
                              className="btn"
                            >
                              View
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Site Expances Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    <Table
                      columns={columns}
                      dataSource={tBody}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Payment Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                      <div>Quotation Payment Amount</div>
                      <div>
                        {formatter.format(otherJobData.quotationAmount)}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div>Confirmation Payment</div>
                      <div>{formatter.format(~~otherJobData.paidAmount)}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div>Advance Payment</div>
                      <div>{formatter.format(~~total)}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="font-bold text-red-600">
                        Total Outstanding
                      </div>
                      <div className="text-red-600">
                        {formatter.format(otherJobData.quotationAmount - total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default OtherJobData;
