import React from "react";

const SingleItem = ({ item, number, handleRemove }) => {
    return (
        <tr className="mt-6 h-9 py-6 border-b-2 border-slate-300">
            <td className="text-left"># {number}</td>
            <td className="text-left">{item.title}</td>
            <td className="text-right">{item.quantity}</td>
            <td className="text-right">
                {item.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                })}
            </td>
            <td className="text-right">
                {(item.price * item.quantity).toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                })}
            </td>

            <td className="flex flex-col items-center py-2">
                <span
                    onClick={() => {
                        handleRemove(item.posId);
                    }}
                    className="bg-red-500 hover:bg-red-600 text-white text-sm px-3 py-1 rounded-lg cursor-pointer"
                >
                    Remove
                </span>
            </td>
        </tr>
    );
};

export default SingleItem;
