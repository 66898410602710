import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../../http";
import { toast } from "react-toastify";

import { Button, Input, Select } from "../../../../../components/ui";

function SalaryDataModel({ handleClose, id, visible }) {
  const inputDataStructure = {
    employData: {
      key: "employData",
      label: "Select Employee",
      optTitle: "Please Select",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    amount: {
      key: "amount",
      label: "Monthly Salary (LKR)",
      data: "",
      required: true,
      type: "number",
      min: 0,
      error: null,
    },
    commision: {
      key: "commision",
      label: "Commision Amount (LKR)",
      data: "",
      required: true,
      type: "number",
      min: 0,
      error: null,
    },
    deductions: {
      key: "deductions",
      label: "Total Deductions (LKR)",
      data: "",
      required: true,
      type: "number",
      min: 0,
      error: null,
    },
    date: {
      key: "date",
      label: "Date",
      data: "",
      type: "date",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const getData = async () => {
    try {
      let input_list = { ...inputs };
      // need to have the employee data list hhere
      const suppervisorList = await apiService.get("supervisor");

      const expOptList = suppervisorList.map((type) => {
        return {
          id: type.supervisorId,
          title: type.title,
        };
      });

      input_list.employData.optList = expOptList;
      setInputs(input_list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      employData: inputs.employData.data,
      salaryAmount: inputs.amount.data,
      commision: inputs.commision.data,
      deductions: inputs.deductions.data,
      date: inputs.date.data,
      id: id,
    };

    try {
      await apiService.patch("expence/salary", data);
      toast.success("Salary Data updated successfully!");

      handleClose();
    } catch (e) {
      toast.error(e);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Add Employee Salary Expence</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <Select input={inputs.employData} handleChange={handleChange} />
          <Input input={inputs.amount} handleChange={handleChange} />
          <Input input={inputs.commision} handleChange={handleChange} />

          <Input input={inputs.deductions} handleChange={handleChange} />
          <Input input={inputs.date} handleChange={handleChange} />

          <Button handleClick={handleSubmit} className="mt-6" text={"Submit"} />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default SalaryDataModel;
