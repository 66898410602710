import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../../../../../http";
import {
  Button,
  FileUpload,
  Input,
  Select,
} from "../../../../../components/ui";

function LaborChargesModel({ handleClose, id, visible }) {
  const inputDataStructure = {
    expenceType: {
      key: "expenceType",
      label: "Expence Type",
      optTitle: "Select Type",
      data: "job",
      optList: [
        { id: "otherjob", title: "Other Job" },
        { id: "job", title: "Job" },
      ],
      required: true,
      type: "text",
      error: null,
    },
    fieldNumber: {
      key: "fieldNumber",
      label: "Inquery/Job Number",
      optTitle: "Select Type",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    amount: {
      key: "amount",
      label: "Total Amount (LKR)",
      data: "",
      required: true,
      type: "number",
      min: 0,
      error: null,
    },
    file: {
      key: "file",
      label: "Attachments",
      data: [],
      error: null,
    },
    date: {
      key: "date",
      label: "Date",
      data: "",
      type: "date",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("expenceType", inputs.expenceType.data);
      data.append("fieldNumber", inputs.fieldNumber.data);
      data.append("amount", inputs.amount.data);
      data.append("date", inputs.date.data);

      if (inputs.file.data[0]) {
        data.append("file", inputs.file.data[0].data);
      } else {
        toast.error("Attachments required");
        return;
      }

      await apiService.patch("expence/labour", data);
      toast.success("Labour Charge updated successfully!");

      handleClose();
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let input_list = { ...inputs };
        // need to have the employee data list hhere
        const inquiryList = await apiService.get("inquiry");
        const otherJobList = await apiService.get("other-jobs");

        const expOptList = inquiryList.map((inquiry) => {
          return {
            id: inquiry.inquiryId,
            title: `${inquiry.inquiryId}`,
          };
        });
        const expOptListoj = otherJobList.map((other) => {
          return {
            id: other.otherJobId,
            title: `${other.otherJobId} - ${other.description}`,
          };
        });

        if (inputs.expenceType.data === "job") {
          input_list.fieldNumber.optList = expOptList;
        } else {
          input_list.fieldNumber.optList = expOptListoj;
        }

        setInputs(input_list);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.expenceType.data]);

  const handleThis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Add Labour charges</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>

        <div className="mt-2 mx-4">
          <form onSubmit={handleThis} encType="multipart/form-data">
            <Select input={inputs.expenceType} handleChange={handleChange} />
            <Select input={inputs.fieldNumber} handleChange={handleChange} />
            <Input input={inputs.amount} handleChange={handleChange} />
            <FileUpload
              input={inputs.file}
              handleChange={handleChange}
              multiple
            />

            <Input input={inputs.date} handleChange={handleChange} />

            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Submit"}
              type="submit"
            />
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default LaborChargesModel;
