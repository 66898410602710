import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ImportMaterialModal = ({ handleClose, visible }) => {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      optTitle: "a material",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    supplier: {
      key: "supplier",
      optTitle: "a supplier",
      label: "Supplier",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },

    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },

    quantity: {
      key: "quantity",
      label: "Quantity",
      data: "",
      type: "number",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const materials = await apiService.get("material");
    const suppliers = await apiService.get("supplier");

    const matOptList = materials.map((material) => {
      return {
        id: material.id,
        title: `${material.materialId} - ${material.title}`,
      };
    });
    const supOptList = suppliers.map((supplier) => {
      return {
        id: supplier.id,
        title: `${supplier.supplierId} - ${supplier.title}`,
      };
    });

    input_list.material.optList = matOptList;
    input_list.supplier.optList = supOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (inputs.quantity.data <= 0) {
      toast.error("Please enter a valid quantity!");
      return;
    }

    const formData = {
      material: inputs.material.data,
      supplier: inputs.supplier.data,
      remarks: inputs.remarks.data,
      quantity: inputs.quantity.data,
    };

    try {
      setIsLoading(true);
      await apiService.post("material/import", formData);
      toast.success("Material imported successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Import material</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Select input={inputs.material} handleChange={handleChange} />
          <Select input={inputs.supplier} handleChange={handleChange} />
          <Input input={inputs.remarks} handleChange={handleChange} />

          <Input input={inputs.quantity} handleChange={handleChange} />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={"Import"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default ImportMaterialModal;
