import React, { useEffect, useState } from "react";
import InquiryModal from "./InquiryModal";
import SiteDataModal from "./SiteDataModal";
import ProductMaterialModal from "./ProductMaterialModal";
import ProductStockModal from "./ProductStockModal";
import QuotationModal from "./QuotationModal";
import ClearanceModal from "./ClearanceModal";
import AdvanceModal from "./AdvanceModal";
import ExpenseModal from "./ExpenseModal";
import ConfirmationModal from "./ConfirmationModal";

import { Button } from "../../../components/ui";
import apiService from "../../../http";
import { useParams } from "react-router-dom";
import WarrantyModel from "./warrantyModal/WarrantyModel";

import cebcare from "../../../assets/img/ceb-care-logo.webp";

import MatrialModalInquery from "./materialsModal/MatrialModal";
import ContractorModal from "./contractorModal/ContractorModal";
import ReturnMaterialModel from "./returnMaterialModel/ReturnMaterialModel";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageCarousal from "../../../components/ui/carosal/ImageCarousal";
import { Table } from "antd";
import { toast } from "react-toastify";
import FeedbackModel from "./Feedback/FeedbackModel";

import LoadingAnimation from "../../../components/Loader";
import { useSelector } from "react-redux";
import ApprovalLetterCeb from "./approvalLetterCeb/ApprovalLetterCeb";

const InquiryData = () => {
  const { id } = useParams();

  const [inquiryModalState, setInquiryModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [inquiryStockModal, setProductStockModalState] = useState(false);
  const [expenseModalState, setExpenseModalState] = useState(false);
  const [clearanceModalState, setClearanceModalState] = useState(false);
  const [approvalModelState, setApprovalModelState] = useState(false);
  const [quotationModalState, setQuotationModalState] = useState(false);
  const [advanceModalState, setAdvanceModalState] = useState(false);
  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [siteDataModalState, setSiteDataModalState] = useState(false);
  const [warrantyModalState, setWarrantyModelState] = useState(false);
  const [materialModelState, setMaterialModelState] = useState(false);
  const [contractorModelState, setContractorModelState] = useState(false);
  const [customerFeedbackModelState, setCustomerFeedbackModelState] =
    useState(false);
  const [returnMaterialModelState, setReturnMaterialModelState] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [inquiry, setInquiry] = useState(null);

  let CEBurl = "https://cebcare.ceb.lk/";

  const [statusList] = useState(["ongoing", "stopped", "Finished", "Canceled"]);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "LKR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const handleCloseModal = () => {
    setInquiry(null);
    setInquiryModalState(false);
    setAdvanceModalState(false);
    setApprovalModelState(false);
    setMaterialModalState(false);
    setSiteDataModalState(false);
    setQuotationModalState(false);
    setClearanceModalState(false);
    setConfirmationModalState(false);
    setExpenseModalState(false);
    setProductStockModalState(false);
    setWarrantyModelState(false);
    setMaterialModelState(false);
    setContractorModelState(false);
    setReturnMaterialModelState(false);
    setCustomerFeedbackModelState(false);
    getData();
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(`inquiry/${id}`);
      setIsLoading(false);
      setInquiry(data);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePDFLoad = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      const blob = response.data;

      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const statData = {
        updatedStatus: status,
        inqueryId: id,
      };

      await apiService.post("inquiry/status", statData);
      toast.success("Status updated successfully!");
      handleCloseModal();
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  const tBody = inquiry?.expenses.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const columns = [
    {
      title: "Expacnce Type",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
    },
    {
      title: "Amount (LKR)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "10%",
      // render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
  ];
  const total =
    parseFloat(~~inquiry?.advancedPaidAmount) +
    parseFloat(~~inquiry?.advancedestimatedChargesPaidAmount) +
    parseFloat(~~inquiry?.advancedClearancePaidAmount) +
    parseFloat(~~inquiry?.advancedCEBPaidAmount);

  const isoutstanding = inquiry?.quotationAmount > inquiry?.paidAmount + total;

  const handleStatusClassname =
    inquiry?.inqueryStatus === "stopped"
      ? "badge p-4 text-white font-bold bg-secondary my-2"
      : inquiry?.inqueryStatus === "Finished"
      ? "badge p-4 text-white font-bold bg-primary my-2"
      : inquiry?.inqueryStatus === "Canceled"
      ? "badge p-4 text-white font-bold bg-red-500 my-2"
      : "badge p-4 text-white font-bold bg-green-500 my-2";

  const handleOnClick = (id, status) => {
    if (
      inquiry.inqueryStatus === "Canceled" ||
      isSupervisor ||
      isOffice ||
      isoutstanding
    ) {
      return;
    }
    handleChangeStatus(id, status);
  };
  const handledisabled =
    (inquiry?.inqueryStatus === "Canceled") |
    (inquiry?.inqueryStatus === "stopped") |
    (inquiry?.inqueryStatus === "Finished")
      ? true
      : false;

  if (!inquiry) return null;

  return (
    <section className="w-full mt-6">
      {inquiryModalState && (
        <InquiryModal inquiry={inquiry} handleClose={handleCloseModal} />
      )}
      {materialModalState && (
        <ProductMaterialModal
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {inquiryStockModal && (
        <ProductStockModal inquiry={inquiry} handleClose={handleCloseModal} />
      )}
      {quotationModalState && (
        <QuotationModal
          visible={quotationModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {clearanceModalState && (
        <ClearanceModal
          visible={clearanceModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {approvalModelState && (
        <ApprovalLetterCeb
          visible={approvalModelState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {advanceModalState && (
        <AdvanceModal
          visible={advanceModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {confirmationModalState && (
        <ConfirmationModal
          visible={confirmationModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {siteDataModalState && (
        <SiteDataModal
          visible={siteDataModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {expenseModalState && (
        <ExpenseModal
          visible={expenseModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {warrantyModalState && (
        <WarrantyModel
          visible={warrantyModalState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {materialModelState && (
        <MatrialModalInquery
          visible={materialModelState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {contractorModelState && (
        <ContractorModal
          visible={contractorModelState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {returnMaterialModelState && (
        <ReturnMaterialModel
          visible={returnMaterialModelState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {customerFeedbackModelState && (
        <FeedbackModel
          visible={customerFeedbackModelState}
          inquiry={inquiry}
          handleClose={handleCloseModal}
        />
      )}
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-start gap-3 gap-y-0 bg-white p-2 mb-3 rounded-md">
            <Button
              text={"Clearance request"}
              handleClick={() => setClearanceModalState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Approval Letter CEB"}
              handleClick={() => setApprovalModelState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Confirmation invoice"}
              handleClick={() => setConfirmationModalState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Upload quotation"}
              handleClick={() => setQuotationModalState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Site details"}
              handleClick={() => setSiteDataModalState(true)}
              disabled={!handledisabled ? false : true}
            />
            <Button
              text={"Advanced invoice"}
              handleClick={() => setAdvanceModalState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Manage materials"}
              handleClick={() => setMaterialModelState(true)}
              disabled={!handledisabled ? false : true}
            />
            <Button
              text={"Manage contracts"}
              handleClick={() => setContractorModelState(true)}
              disabled={!handledisabled ? false : true}
            />
            <Button
              text={"Site expenses"}
              handleClick={() => setExpenseModalState(true)}
              disabled={!handledisabled ? false : true}
            />
            <Button
              text={"Warranty Period"}
              handleClick={() => setWarrantyModelState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
            <Button
              text={"Return Material"}
              handleClick={() => setReturnMaterialModelState(true)}
              disabled={!handledisabled ? false : true}
            />
            <Button
              text={"Customer Feedback"}
              handleClick={() => setCustomerFeedbackModelState(true)}
              disabled={
                !(handledisabled || isSupervisor || isOffice) ? false : true
              }
            />
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-2 mx-6">
            <div className="flex flex-row items-center gap-2">
              <p className="font-bold">Visit</p>
              <div
                className="cursor-pointer"
                type="button"
                onClick={() => window.open(CEBurl)}
              >
                <img
                  src={cebcare}
                  alt="cebcare"
                  className="w-32 h-12 object-contain"
                />
              </div>
            </div>
            {/* status */}
            <div className="flex flex-row items-center justify-center gap-2">
              <div className={handleStatusClassname}>
                {inquiry.inqueryStatus}
              </div>
              <div className="dropdown dropdown-top dropdown-end">
                <div
                  tabIndex={0}
                  role="button"
                  className={
                    (inquiry?.inqueryStatus === "Finished") |
                    (inquiry?.inqueryStatus === "Canceled")
                      ? "btn bg-primary text-white hover:bg-slate-600 pointer-events-none"
                      : "btn bg-primary text-white hover:bg-slate-600"
                  }
                >
                  Change Status
                </div>

                <ul
                  tabIndex={0}
                  className="dropdown-content  z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 "
                >
                  {statusList.map((item, index) => {
                    const itemStatus = item === inquiry.inqueryStatus;

                    if (!itemStatus) {
                      return (
                        <li
                          key={index}
                          className="font-medium gap-2 hover:bg-slate-200 hover:rounded-md cursor-pointer border-y-2 p-2"
                          onClick={() => {
                            handleOnClick(inquiry.id, item);
                          }}
                        >
                          {item}
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {/* customer Details */}
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Customer details
                  </h3>
                  <img
                    src={require("../../../assets/img/profile.png")}
                    alt=""
                    className="rounded-full w-[80px] mx-auto"
                  />
                  <ul className="mx-4 mt-4">
                    <li>Name : {inquiry.customerName}</li>
                    <li>Phone number : {inquiry.customerPhoneNumber}</li>
                  </ul>
                </div>
                {/* supervisor details */}
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Supervisor details
                  </h3>
                  <img
                    src={require("../../../assets/img/profile.png")}
                    alt=""
                    className="rounded-full w-[80px] mx-auto"
                  />
                  <ul className="mx-4 mt-4">
                    <li>Name : {inquiry.supervisor.name}</li>
                    <li>
                      Phone number : {inquiry.supervisor.data.phoneNumber}
                    </li>
                  </ul>
                </div>
                {/* site Details */}
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Site details
                  </h3>
                  <ul className="mx-4">
                    <li>DC length : {inquiry.dcLength}</li>
                    <li>AC length : {inquiry.acLength}</li>
                    <li>Location : {inquiry.location}</li>
                    {inquiry.siteDataImage && (
                      <li className="p-4">
                        Preview:{" "}
                        <ImageCarousal imageArray={inquiry.siteDataImage} />
                      </li>
                    )}
                  </ul>
                </div>
                {/* download documents */}
                <div className="shadow-lg py-4 m-2 border-b border-gray-200">
                  <h3 className="bg-slate-300 text-slate-600 text-center mb-4 uppercase text-bold">
                    Download documents
                  </h3>
                  <ul className="mx-4 mt-4">
                    {/* CEB uploaded file */}
                    {inquiry.cebElectricBillFile && (
                      <li className="mt-2">
                        CEB Uploaded Bill :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.cebElectricBillFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job conformation file */}
                    {inquiry.confirmationInvFile && (
                      <li className="mt-2">
                        Confirmation invoice :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.confirmationInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job advancefile */}
                    {inquiry.advanceInvFile && (
                      <li className="mt-2">
                        Advance invoice :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.advanceInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job advance estimate */}
                    {inquiry.advanceEstInvFile && (
                      <li className="mt-2">
                        Advance Estimate invoice :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.advanceEstInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job advance clearance invoice file */}
                    {inquiry.advanceClearInvFile && (
                      <li className="mt-2">
                        Advance Clearance invoice :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.advanceClearInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job advance ceb invoice file */}
                    {inquiry.advanceCEBInvFile && (
                      <li className="mt-2">
                        Advance CEB invoice :
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.advanceCEBInvFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job quotation file */}
                    {inquiry.quotationFile && (
                      <li className="mt-2">
                        Quotation :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.quotationFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* ceb approval request file */}
                    {inquiry.approvalReqFile && (
                      <li className="mt-2">
                        CEB Approval request :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.approvalReqFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job clearance request file */}
                    {inquiry.clearanceReqFile && (
                      <li className="mt-2">
                        Clearance request :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.clearanceReqFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job wrranty file */}
                    {inquiry.warrantyFile && (
                      <li className="mt-2">
                        Warranty Document :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.warrantyFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job wrranty imported file */}
                    {inquiry.warrantyimportedFile && (
                      <li className="mt-2">
                        Warranty Imported Document :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.warrantyimportedFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job wrranty serial file */}
                    {inquiry.serialNumberFile && (
                      <li className="mt-2">
                        Warranty Serial Document :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.serialNumberFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                    {/* job Expence file */}
                    {inquiry.expenceDocFile && (
                      <li className="mt-2">
                        Expence Document :{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={inquiry.expenceDocFile}
                          className="text-center text-white bg-primary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
                        >
                          view
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <br />
              <div className="px-2 py-4 gap-2 flex flex-col">
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Bill of Materials Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    {inquiry.bomMaterials &&
                      inquiry.bomMaterials.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between p-4 rounded-md bg-slate-200"
                          >
                            <p className="badge p-3 items-center">
                              {item.BOMID}
                            </p>
                            <button
                              onClick={
                                () =>
                                  handlePDFLoad(
                                    `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.BOMInvoiceID}.pdf`
                                  )
                                // printJS({
                                //   printable: `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.BOMInvoiceID}.pdf`,
                                //   type: "pdf",
                                //   showModal: true,
                                // })
                              }
                              rel="noreferrer"
                              target="_blank"
                              type="button"
                              className="btn"
                            >
                              View
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Return Of Materials Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    {inquiry.returnBomMaterials &&
                      inquiry.returnBomMaterials.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center justify-between p-4 rounded-md bg-slate-200"
                          >
                            <p className="badge p-3 items-center">
                              {item.ReturnBOMID}
                            </p>
                            <button
                              onClick={
                                () =>
                                  handlePDFLoad(
                                    `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.ReturnBOMInvoiceID}.pdf`
                                  )
                                // printJS({
                                //   printable: `${process.env.REACT_APP_BACKEND_URL}public/invoice/${item.ReturnBOMInvoiceID}.pdf`,
                                //   type: "pdf",
                                //   showModal: true,
                                // })
                              }
                              type="button"
                              className="btn"
                            >
                              View
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Site Expances Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    <Table
                      columns={columns}
                      dataSource={tBody}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="collapse collapse-arrow bg-blue-200">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    Payment Details
                  </div>
                  <div className="collapse-content flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                      <div>Quotation Payment Amount</div>
                      <div>{formatter.format(inquiry.quotationAmount)}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div>Confirmation Payment</div>
                      <div>{formatter.format(~~inquiry.paidAmount)}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div>Advance Payment</div>
                      <div>{formatter.format(~~total)}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <div className="font-bold text-red-600">
                        Total Outstanding
                      </div>
                      <div className="text-red-600">
                        {formatter.format(
                          inquiry.quotationAmount - inquiry.paidAmount - total
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default InquiryData;
