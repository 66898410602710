import React from "react";

const SingleItem = () => {
    return (
        <thead>
            <tr className="mx-3 bg-slate-400 px-3">
                <th className="text-left">No.</th>
                <th className="text-left">Description</th>
                <th className="text-right">Quantity</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Amount</th>
                <th className="text-center">Action</th>
            </tr>
        </thead>
    );
};

export default SingleItem;
