import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import apiService from "../../../../http";
import { Input, Select } from "../../../../components/ui";
import LoadingAnimation from "../../../../components/Loader";
import Logo from "../../../../components/logo/Logo";

const inputDataStructure = {
  supplier: {
    key: "supplier",
    label: "Select Supplier",
    optTitle: "",
    data: null,
    // optList: [],
    required: true,
    type: "text",
    error: null,
  },
  startDate: {
    key: "startDate",
    label: "Start Date",
    data: "",
    type: "date",
    error: null,
  },
  endDate: {
    key: "endDate",
    label: "End Date",
    data: "",
    type: "date",
    error: null,
  },
};
const metadata = {
  SUPPLIER_OUTSTANDING: "Supplier Outstanding Payments",
  SUPPLIER_PAYMENT: "Supplier Purchsing Order Payments",
  FILTER_BY_DATE: "Filter By Date",
};

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const radioBtnOptions = [
  metadata.SUPPLIER_OUTSTANDING,
  metadata.SUPPLIER_PAYMENT,
  metadata.FILTER_BY_DATE,
];

const TableData = ({ dataSource }) => {
  const TableHeader = () => {
    return (
      <div className=" flex items-center justify-between gap-2">
        <h2 className="font-black text-black text-xl">
          IGRID HOLDINGS (PVT) LTD
        </h2>
        <div className="items-start font-bold">Payment Sheet</div>
      </div>
    );
  };

  const HandleTHeaderData = () => {
    return (
      <thead className="bg-slate-200 font-bold">
        <tr>
          <th></th>
          <th>Id</th>
          <th>description</th>
          <th>Date</th>
          <th>Amount (LKR)</th>
        </tr>
      </thead>
    );
  };

  const HandleTBodyData = () => {
    return (
      <tbody className="text-wrap items-center">
        {dataSource.map((itemObj) => {
          const poId = itemObj.poId;
          const payments = itemObj.payments;

          return (
            <Fragment key={itemObj.key}>
              <tr className="bg-yellow-200">
                <td>{poId}</td>
                <td colSpan={4}></td>
              </tr>

              {payments && payments.length > 0 ? (
                payments.map((paymentObj) => {
                  const date = new Date(paymentObj.date);

                  return (
                    <Fragment key={paymentObj.id}>
                      <tr>
                        <td></td>
                        <td>{paymentObj.id}</td>
                        <td>{paymentObj.payType}</td>
                        <td>{date.toLocaleDateString("en-US")}</td>
                        <td>{paymentObj.paidAmount}</td>
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td>No Data Available</td>
                  <td colSpan={3}></td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    );
  };

  const HandleTableBodyData = () => {
    return (
      <div className="content">
        <table className="w-[700px]">
          <HandleTHeaderData />
          <HandleTBodyData />
        </table>
      </div>
    );
  };

  return (
    <table className="table w-[700px]">
      <thead>
        <tr>
          <th>
            <TableHeader />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <HandleTableBodyData />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function PaymentsReport() {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [filteredData, setFilteredData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();

  const [filterType, setFilterType] = useState(metadata.SUPPLIER_OUTSTANDING);

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const suppliersData = await apiService.get("supplier");

      let input_list = { ...inputs };

      const matOptList = suppliersData.map((sup) => {
        return {
          id: sup.id,
          title: `${sup.title}`,
        };
      });

      input_list.supplier.optList = matOptList;
      setInputs(input_list);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      setInputs(input_list);
    },
    [inputs]
  );

  useEffect(() => {
    refreshInquiries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handleSupOutstanding = async (supId) => {
    const paymentList = await apiService.get(
      `payment/viewoutstandingPaymentbysupplier/${supId}`
    );
    const updatedPaymentList = paymentList.map((outPayItemOBJ) => {
      const poData = outPayItemOBJ.poData[0];

      return {
        id: outPayItemOBJ.outstandingPaymentId,
        paidAmount: outPayItemOBJ.paidamount,
        payType: "CASH",
        outId: outPayItemOBJ.outstandingId,
        date: outPayItemOBJ.paiddate,
        poId: poData.supBasedPOId,
      };
    });

    const groupedPayments = updatedPaymentList.reduce((acc, payment) => {
      const { poId, ...otherData } = payment;

      if (!acc[poId]) {
        acc[poId] = { poId, payments: [] };
      }

      acc[poId].payments.push(otherData);

      return acc;
    }, {});

    const result = Object.values(groupedPayments);

    setFilteredData(result);
  };

  const handlepoPayments = async (supId) => {
    const paymentList = await apiService.get(
      `payment/viewoPOListbysupplier/${supId}`
    );

    const updatedPaymentList = paymentList.map((payItemOBJ) => {
      const payData = payItemOBJ.payData[0];

      return {
        id: payData.paymentId,
        paidAmount: payData.paymentData.paidamount,
        payType: payData.type.toUpperCase(),
        date: payData.createdAt,
        poId: payItemOBJ.supBasedPOId,
      };
    });

    const groupedPayments = updatedPaymentList.reduce((acc, payment) => {
      const { poId, ...otherData } = payment;

      if (!acc[poId]) {
        acc[poId] = { poId, payments: [] };
      }

      acc[poId].payments.push(otherData);

      return acc;
    }, {});

    const result = Object.values(groupedPayments);

    setFilteredData(result);
  };

  const handlepaymentsbyDateRange = async (startDate, endDate) => {
    try {
      const paymentList = await apiService.get(
        `payment/viewoutstandingPayment`
      );
      const poList = await apiService.get(`payment/viewoPOList`);

      const updatedoutPayList = paymentList
        .map((outPayItemOBJ) => {
          const poData = outPayItemOBJ.poData[0];

          const paidDate = new Date(outPayItemOBJ.paiddate);

          const data = {
            id: outPayItemOBJ.outstandingPaymentId,
            paidAmount: outPayItemOBJ.paidamount,
            payType: "CASH",
            outId: outPayItemOBJ.outstandingId,
            date: paidDate,
            poId: poData ? poData.supBasedPOId : null, // Safely handle missing `poData`
          };

          // Correct date comparison
          if (paidDate >= startDate && paidDate <= endDate) {
            return data;
          }

          return null;
        })
        .filter(Boolean); // Filter out null values

      const updatedpoList = poList
        .map((payItemOBJ) => {
          const payData = payItemOBJ.payData[0];

          const paidDate = new Date(payData.createdAt);

          const data = {
            id: payData.paymentId,
            paidAmount: payData.paymentData.paidamount,
            payType: payData.type.toUpperCase(),
            date: paidDate,
            poId: payItemOBJ.supBasedPOId,
          };

          // Correct date comparison
          if (paidDate >= startDate && paidDate <= endDate) {
            return data;
          }

          return null;
        })
        .filter(Boolean); // Filter out null values

      // Merge both lists and filter out any remaining nulls
      const updatedPaymentList = [...updatedoutPayList, ...updatedpoList];

      // Grouping payments by poId
      const groupedPayments = updatedPaymentList.reduce((acc, payment) => {
        const { poId, ...otherData } = payment;

        if (!acc[poId]) {
          acc[poId] = { poId, payments: [] };
        }

        acc[poId].payments.push(otherData);

        return acc;
      }, {});

      const result = Object.values(groupedPayments);

      setFilteredData(result);
    } catch (error) {
      console.error("Error fetching payments data:", error);
    }
  };

  const handleViewData = () => {
    if (filterType === metadata.SUPPLIER_OUTSTANDING) {
      const supId = inputs.supplier.data;
      if (supId != null) {
        // setFilteredData(outstandings);
        handleSupOutstanding(supId);
      }
    }
    if (filterType === metadata.SUPPLIER_PAYMENT) {
      const supId = inputs.supplier.data;
      if (supId != null) {
        // setFilterType(payments);
        handlepoPayments(supId);
      }
    }

    if (filterType === metadata.FILTER_BY_DATE) {
      const startDate = new Date(inputs.startDate.data);
      const endDate = new Date(inputs.endDate.data);

      if (startDate && endDate) {
        handlepaymentsbyDateRange(startDate, endDate);
      }
    }
  };

  const HandleFilterOption = () => {
    return (
      <div>
        {filterType === metadata.SUPPLIER_OUTSTANDING ? (
          <div>
            <Select input={inputs.supplier} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.SUPPLIER_PAYMENT ? (
          <div>
            <Select input={inputs.supplier} handleChange={handleChange} />
          </div>
        ) : filterType === metadata.FILTER_BY_DATE ? (
          <div className="flex flex-col items-center">
            <Input input={inputs.startDate} handleChange={handleChange} />
            <Input input={inputs.endDate} handleChange={handleChange} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">PAYMENT REPORT</p>
              <div className="mt-4">
                {/* radio buttons goes here */}
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>
          <div
            id="tableData"
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableData dataSource={tBody} />
          </div>
        </div>
      )}
    </section>
  );
}

export default PaymentsReport;
