import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Moment from "moment";
import apiService from "../../../http";
import { Input } from "../../../components/ui";

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const inputDataStructure = {
        searchString: {
            key: "searchString",
            label: "",
            data: "",
            type: "text",
            placeholder: "Search an item",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.data === "") {
            setFilteredData(payments);
        } else {
            const data = payments.filter((item) => {
                const lowerCaseTitle = item.contractor.title.toLowerCase();
                const lowerCaseInputData = input.data.toLowerCase();
                return lowerCaseTitle.includes(lowerCaseInputData);
            });

            setFilteredData(data);
        }

        setInputs(input_list);
    };

    const refreshPayments = async () => {
        try {
            const payments = await apiService.get("payment");
            setPayments(payments);
            setFilteredData(payments);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        refreshPayments();
    }, []);

    const columns = [
        {
            title: "Payment Id",
            align: "left",
            width: "15%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        {arg.paymentId}
                    </div>
                );
            },
            sorter: (a, b) => a.paymentId.localeCompare(b.paymentId),
        },
        {
            title: "Contractor Id",
            align: "left",
            width: "15%",
            render: (arg) => {
                return <p>{arg.contractor.contractorId}</p>;
            },
            sorter: (a, b) =>
                a.contractor.contractorId.localeCompare(
                    b.contractor.contractorId
                ),
        },
        {
            title: "Contractor Title",
            align: "left",
            width: "15%",
            render: (arg) => {
                return <p>{arg.contractor.title}</p>;
            },
            sorter: (a, b) =>
                a.contractor.Title.localeCompare(b.contractor.Title),
        },
        {
            title: "Job Id",
            align: "left",
            width: "15%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        {arg.job.jobId}
                    </div>
                );
            },
        },
        {
            title: "Job Title",
            align: "left",
            width: "15%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        {arg.job.title}
                    </div>
                );
            },
        },

        {
            title: "PaySheet",
            align: "center",
            width: "15%",
            render: (arg) => {
                return (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={arg.paySheet}
                        className="bg-primary px-2 text-white py-1 rounded-lg"
                    >
                        view
                    </a>
                );
            },
        },
        {
            title: "Total",
            align: "center",
            width: "20%",
            render: (arg) => {
                const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                });
                return formattedPrice;
            },
            sorter: (a, b) => a.total - b.total,
        },

        {
            title: "Date",
            align: "center",
            width: "20%",
            render: (arg) => {
                const date = Moment(arg.createdAt);

                const formattedDatetime = date.format("YYYY-MM-DD");

                return formattedDatetime;
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
    ];

    return (
        <section className="w-full mt-6">
            <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
                <div></div>
                <div></div>
                <div>
                    <Input
                        input={inputs.searchString}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Payments;
