import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedMaterial, visible }) => {
  const inputDataStructure = {
    title: {
      key: "title",
      label: "Title",
      data: "",
      required: true,
      type: "text",
      error: null,
    },

    remarks: {
      key: "remarks",
      label: "Remarks",
      data: "",
      type: "text",
      error: null,
    },
    amount: {
      key: "amount",
      label: "Buying Price(LKR)",
      data: "",
      type: "number",
      error: null,
    },

    unit: {
      key: "unit",
      optTitle: "units",
      label: "units",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    unitPrice: {
      key: "unitPrice",
      label: "Unit price(LKR)",
      data: "",
      type: "number",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  // const handleunitDescription = (unitId) => {
  //   const data = units.find((elem) => {
  //     return elem.unitData === unitId;
  //   });

  //   return data.unitDescription;
  // };

  const buildForm = async () => {
    if (selectedMaterial) {
      let input_list = { ...inputs };

      const unitTypes = await apiService.get("unit");

      const unitOpList = unitTypes.map((unit) => {
        return {
          id: unit.unitData,
          title: unit.unitDescription,
        };
      });

      const selectedUnit = unitOpList.find(
        (elem) => elem.id === selectedMaterial.unit
      );

      input_list.unit.optList = unitOpList;
      input_list.unit.placeHolder = selectedUnit.title;
      input_list.unit.data = selectedMaterial.unit;
      input_list.title.data = selectedMaterial.title;
      input_list.remarks.data = selectedMaterial.remarks;
      input_list.unitPrice.data = selectedMaterial.unitPrice;
      input_list.amount.data = selectedMaterial.amount;

      setInputs(input_list);
    } else {
      let input_list = { ...inputs };
      const unitTypes = await apiService.get("unit");

      const unitOpList = unitTypes.map((unit, index) => {
        return {
          id: unit.unitData,
          title: unit.unitDescription,
        };
      });
      input_list.unit.optList = unitOpList;
      setInputs(input_list);
    }
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      title: inputs.title.data,
      remarks: inputs.remarks.data,
      unitPrice: inputs.unitPrice.data,
      amount: inputs.amount.data,
      unit: inputs.unit.data,
    };

    if (inputs.unitPrice.data <= 0) {
      toast.error("Please enter a valid price!");
      return;
    }

    try {
      setIsLoading(true);
      if (selectedMaterial) {
        formData.id = selectedMaterial.id;
        await apiService.patch("material", formData);
        toast.success("Material updated successfully!");
      } else {
        await apiService.post("material", formData);
        toast.success("Material added successfully!");
      }
      setIsLoading(false);

      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">
            {!selectedMaterial && "Add new "}
            {selectedMaterial && "Edit "}
            Material
          </h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <Input input={inputs.title} handleChange={handleChange} />
          <Select input={inputs.unit} handleChange={handleChange} />

          <Input input={inputs.unitPrice} handleChange={handleChange} />
          <Input input={inputs.amount} handleChange={handleChange} />

          <Button
            handleClick={handleSubmit}
            className="mt-6"
            text={selectedMaterial ? "Edit" : "Create"}
            isLoading={isLoading}
            disabled={handleDisabled}
          />
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
};

export default MaterialModal;
