import React, { useState, useEffect } from "react";
import ChartSection from "./ChartSection";
import CounterSection from "./CounterSection";

import apiService from "../../../http";

const Home = () => {
  const [countData, setCounts] = useState([]);
  const [inqueryStatData, setInqueryStatData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiService.get("stat");
        setCounts(result);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchinqueryData = async () => {
      try {
        const results = await apiService.get("inquiry/stat");
        setInqueryStatData(results);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    fetchinqueryData();
  }, []);

  const data = inqueryStatData.map((item) => {
    return {
      name: item.month,
      inquiries: item.numberofdocuments,
    };
  });

  return (
    <div className="bg-img-home w-full bg-cover gap-2 flex flex-col px-2">
      <CounterSection countData={countData} />
      <ChartSection data={data} keyX={"name"} keyY={"inquiries"} />
    </div>
  );
};

export default Home;
