import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";

const ProductMaterialModal = ({ handleClose, selectedProduct }) => {
  const inputDataStructure = {
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },

    material: {
      key: "material",
      label: "Material",
      required: true,
      optTitle: "a material",
      data: "",
      optList: [],
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    let materials = await apiService.get("material");

    const optList = materials.map((material) => {
      return {
        id: material.id,
        title: material.title,
      };
    });

    input_list.material.optList = optList;

    setInputs(input_list);
  };
  const refreshMaterials = async () => {
    const materialList = await apiService.get(
      `product/material/${selectedProduct.id}`
    );

    setMaterials(materialList);
  };

  useEffect(() => {
    buildForm();
    refreshMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      id: selectedProduct.id,
      material: inputs.material.data,
      quantity: inputs.quantity.data,
    };

    try {
      setIsLoading(true);
      await apiService.post("product/material/add", formData);
      toast.success("Inquiry materials updated successfully!");
      refreshMaterials();
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const actionBtns = (material) => {
    return (
      <div className="flex items-center flex-wrap">
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            try {
              await apiService.post("product/material/remove", {
                product: selectedProduct.id,
                material: material.id,
              });
              toast.success("Inquiry materials updated successfully!");
              refreshMaterials();
            } catch (e) {
              toast.error(e);
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "Material ID",
      dataIndex: "materialId",
      key: "materialId",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },
    {
      title: "Material",
      dataIndex: "materialTitle",
      key: "materialTitle",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.materialTitle.localeCompare(b.materialTitle),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.quantity.localeCompare(b.quantity),
    },
    {
      title: "Unit price",
      align: "center",
      width: "10%",
      render: (arg) => {
        const formattedPrice = arg.materialUnitPrice.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "Total",
      align: "center",
      width: "10%",
      render: (arg) => {
        const total = arg.materialUnitPrice * arg.quantity;
        const formattedPrice = total.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "Actions",
      align: "center",
      width: "10%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const handleDisabled = isLoading ? true : false;

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
        <div className=" shadow-xl transition-all w-[80%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
            <h3 className="mx-auto py-1">Material center</h3>
            <span
              className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mt-2 mx-4 flex">
            <div className="w-[80%]">
              <div className="w-full">
                <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center">
                  Material list
                </h3>
                <Table columns={columns} dataSource={materials} />
              </div>
            </div>
            <div className="w-[20%]">
              <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center mb-4">
                Add materials
              </h3>

              <Select input={inputs.material} handleChange={handleChange} />

              <Input input={inputs.quantity} handleChange={handleChange} />

              <Button
                handleClick={handleSubmit}
                className="mt-6 w-full"
                text={"Add"}
                isLoading={isLoading}
                disabled={handleDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMaterialModal;
