import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, handleAdd }) => {
    const inputDataStructure = {
        title: {
            key: "title",
            required: true,
            label: "Inquiry title",
            data: "",
            type: "text",
            error: null,
        },
        quantity: {
            key: "quantity",
            label: "Quantity",
            required: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },
        product: {
            key: "product",
            label: "Inquiry",
            required: true,
            optTitle: "a product",
            data: "",
            optList: [],
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [inquiries, setProducts] = useState([]);

    const handleChange = async (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        const inquiries = await apiService.get("product");

        const optList = inquiries.map((product) => {
            return {
                id: product.id,
                title: `${product.productId} - ${product.title}  (${product.stock} items left)`,
            };
        });

        input_list.product.optList = optList;

        setInputs(input_list);
        setProducts(inquiries);
    };

    useEffect(() => {
        buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        if (!inputs.product.data) {
            toast.error("Please input a product!");
            return;
        }

        let product = inquiries.find((item) => {
            return item.id === inputs.product.data;
        });

        if (Number(inputs.quantity.data) === 0) {
            toast.error("Please enter a valid quantity!");
            return;
        }
        if (Number(inputs.quantity.data) > Number(product.stock)) {
            toast.error("Quantity can't be greater than the stock count!");
            return;
        }

        handleAdd({
            id: product.id,
            title: product.title,
            productId: product.productId,
            quantity: Number(inputs.quantity.data),
            price: product.unitPrice,
            type: "product",
        });
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[30%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Add inquiries</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Select
                            input={inputs.product}
                            handleChange={handleChange}
                        />

                        <Input
                            input={inputs.quantity}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={"Add"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
