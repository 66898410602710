import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import JobModal from "./JobModal";
import JobMaterialModal from "./JobMaterialModal";
import JobExportModal from "./JobExportModal";
import JobContractModal from "./JobContractModal";

import { Button, Confirm, Input } from "../../../components/ui";
import apiService from "../../../http";
import axios from "axios";
import JobRemarksModel from "./jobRemarksModel/JobRemarksModel";
import ConfirmJobModel from "./confirmJobModel/ConfirmModel";
import EditQuotationModel from "./editquotationModel/EditQuotationModel";
import { useSelector } from "react-redux";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};

const Jobs = () => {
  const [inputs, setInputs] = useState(inputDataStructure);

  const [jobs, setJobs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [jobModalState, setJobModalState] = useState(false);
  const [jobRemarksModalState, setJobRemarksModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [exportModalState, setExportModalState] = useState(false);
  const [contractModalState, setContractModalState] = useState(false);
  const [editJobState, setEditJobState] = useState(false);
  const [editQuotation, setEditQuotation] = useState(false);
  const [confirmedJobModelState, setConfirmedJobModelState] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [selectedJob, setSelectedJob] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const [statusList] = useState(["ongoing", "Finished", "Canceled"]);

  
  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";

  const isOffice = user.data.role === "office";
 


  const handleCloseModal = () => {
    setJobModalState(false);
    setJobRemarksModalState(false);
    setConfirmModalState(false);
    setMaterialModalState(false);
    setContractModalState(false);
    setExportModalState(false);
    setConfirmModalState(false);
    setEditJobState(false);
    setConfirmedJobModelState(false);
    setSelectedJob(null);
    setEditQuotation(null);
    refreshJobs();
  };

  const refreshJobs = async () => {
    try {
      const jobs = await apiService.get("job");

      setJobs(jobs);
      setFilteredData(jobs);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(jobs);
      } else {
        const data = jobs.filter((item) => {
          const lowerCaseTitle = item.jobId.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, jobs]
  );

  const handledocumentLoad = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      const blob = response.data;

      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemarksLoad = (job) => {
    setSelectedJob(job);
    setJobRemarksModalState(true);
  };

  useEffect(() => {
    refreshJobs();
  }, [refresh]);

  const jobStatus = (job) => {
    let classes =
      "cursor-pointer inline-flex items-center text-center justify-center p-4  py-1 text-xs font-bold leading-none text-red-100 rounded-full";
    let status;
    if (job === "Canceled") {
      status = "Canceled";
      classes += " bg-danger";
    } else if (job === "Finished") {
      status = "Finished";
      classes += " bg-primary";
    } else {
      status = job;
      classes += " bg-success";
    }

    return <span className={classes}>{status}</span>;
  };

  const actionBtns = (job) => {
    const action = job.status === "Canelled" || job.status === "Finished";
    return (
      <div className="flex flex-col items-center justify-between lg:grid  lg:grid-cols-2">
        <Button
          disabled={!(action || isSupervisor || isOffice) ? false : true}
          text="Confirm"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(job);
            setConfirmedJobModelState(true);
          }}
        />
        <Button
          disabled={!(action ||  isOffice) ? false : true}
          text="Edit Quotation"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(job);
            setEditQuotation(true);
          }}
        />
        <Button
         disabled={!(action || isSupervisor || isOffice) ? false : true}
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedJob(job);
            setEditJobState(true);
          }}
        />
        <DropDownElem job={job} />
      </div>
    );
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const statData = {
        updatedStatus: status,
        inqueryId: id,
      };

      await apiService.post("job/status", statData);
      toast.success("Job  updated successfully!");
      handleCloseModal();
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  const DropDownElem = ({ job }) => {
    const handleOnClick = (id, status) => {
      if (job.status === "Canceled" || isSupervisor || isOffice ) {
        return;
      }
      handleChangeStatus(id, status);
    };

    return (
      <div className="dropdown dropdown-top dropdown-end mb-2">
        <div
          tabIndex={0}
          role="button"
          className={
            (job?.status === "Finished") | (job?.status === "Canceled")
              ? "btn bg-primary text-white hover:bg-slate-600 pointer-events-none"
              : "btn bg-primary text-white hover:bg-slate-600"
          }
        >
          Change Status
        </div>

        <ul
          tabIndex={0}
          className="dropdown-content  z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 "
        >
          {statusList.map((item, index) => {
            const itemStatus = item === job.status || item === "Finished";

            if (!itemStatus) {
              return (
                <li
                  key={index}
                  className="font-medium gap-2 hover:bg-slate-200 hover:rounded-md cursor-pointer border-y-2 p-2"
                  onClick={() => {
                    handleOnClick(job.id, item);
                  }}
                >
                  {item}
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    );
  };

  const agentData = (agent) => {
    return (
      <div className="flex p-4 items-center text-center font-bold gap-2 badge badge-primary badge-outline">
        {agent.name}
      </div>
    );
  };

  const handleViewFile = (jobData) => {
    return (
      <div className="flex items-center justify-center">
        <Button
          disabled={jobData.status === "Canelled"}
          text="View"
          className={"flex hover:bg-primary hover:text-white"}
          handleClick={() => {
            handledocumentLoad(
              `${process.env.REACT_APP_BACKEND_URL}public/quotation/${jobData.quotationFile}`
            );
          }}
        />
      </div>
    );
  };
  const handleAddRemarks = (jobData) => {
    return (
      <div className="flex items-center justify-center">
        <div className="indicator">
          <span className="indicator-item badge badge-secondary">
            {jobData.remarks.length}
          </span>
          <Button
            disabled={jobData.status === "Canelled"}
            text="Add"
            className={"flex hover:bg-primary hover:text-white"}
            handleClick={() => {
              handleRemarksLoad(jobData);
            }}
          />
        </div>
      </div>
    );
  };

  const tBody = filteredData.map((row, index) => {
    return {
      key: index,
      ...row,
    };
  });

  const columns = [
    {
      title: "Inquiry ID",
      dataIndex: "jobId",
      key: "jobId",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.jobId.localeCompare(b.jobId),
    },
    {
      title: "Name",
      align: "center",
      dataIndex: "customerName",
      width: "5%",
      render: (arg) => {
        return arg;
      },
    },
    {
      title: "contact",
      align: "center",
      dataIndex: "customerPhoneNumber",
      width: "5%",
      render: (arg) => {
        return arg;
      },
    },
    {
      title: "Sale Agent",
      align: "center",
      dataIndex: "supervisor",
      width: "5%",
      render: (arg) => {
        return agentData(arg);
      },
    },
    {
      title: "Quotation",
      align: "center",
      width: "5%",
      render: (arg) => {
        return handleViewFile(arg);
      },
    },
    {
      title: "Remarks",
      align: "center",
      width: "3%",
      key: "remarks",
      render: (arg) => {
        return handleAddRemarks(arg);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: "15%",
      render: (arg) => jobStatus(arg),
    },
    {
      title: "Actions",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {jobModalState && (
        <JobModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
          visible={jobModalState}
        />
      )}
      {materialModalState && (
        <JobMaterialModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {exportModalState && (
        <JobExportModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {contractModalState && (
        <JobContractModal
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}
      {jobRemarksModalState && (
        <JobRemarksModel
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
          handleRefresh={setRefresh}
          refresh={refresh}
          visible={jobRemarksModalState}
        />
      )}
      {confirmModalState && (
        <Confirm
          title="Finish job"
          confirmMsg="Do you want to finish the job job?"
          actionLabel="OK"
          cancelHandler={handleCloseModal}
          confirmHandler={async () => {
            await apiService.patch("job/status/" + selectedJob.id);
            toast.success("Job status changed successfully!");
            handleCloseModal();
            setSelectedJob(null);
          }}
        />
      )}
      {confirmedJobModelState && (
        <ConfirmJobModel
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
          visible={confirmedJobModelState}
        />
      )}
      {editQuotation && (
        <EditQuotationModel
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
          visible={editQuotation}
        />
      )}
      {editJobState && (
        // <EditJobModel
        //   selectedJob={selectedJob}
        //   handleClose={handleCloseModal}
        // />
        <JobModal
          visible={editJobState}
          selectedJob={selectedJob}
          handleClose={handleCloseModal}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"New Inquiry"}
            disabled={!(isSupervisor || isOffice) ? false : true}
            handleClick={() => setJobModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Jobs;
