import React from "react";
import Loading from "./Loading";

const Button = ({
  handleClick,
  className,
  text,
  type = "button",
  disabled = false,
  isLoading = false,
}) => {
  return (
    <button
      onClick={(e) => handleClick(e.target.value)}
      type={type}
      className={
        "border-2  px-12 py-2 font-semibold bg-primary text-white" +
        "  cursor-pointer " +
        "rounded-md mb-4 " +
        className
      }
      disabled={disabled}
    >
      {isLoading ? <Loading /> : text}
    </button>
  );
};

export default Button;
