import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../../../http";
import LoadingAnimation from "../../../../components/Loader";
import { Button } from "../../../../components/ui";
import axios from "axios";
import { Table } from "antd";

function SupplierPoData() {
  const { id } = useParams();

  const [supplierData, setSupplierData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(`purchsingOrder/supplier/${id}`);
      setSupplierData(data.poList);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);


  const handlePDFLoad = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });

      const blob = response.data;

      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const poactionBtns = (material) => {
    return (
      <div className="flex">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            await handlePDFLoad(
              `${process.env.REACT_APP_BACKEND_URL}public/materialbom/${material.invoiceId}.pdf`
            );
          }}
        />
      </div>
    );
  };

  const columnsPO = [
    {
      title: "ID",
      // dataIndex: "purchsingOrderId",
      key: "purchsingOrderId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.purchsingOrderId.localeCompare(b.purchsingOrderId),
      render: (args) => {
        const poID = args.purchsingOrderId;
        const singlepoId = args.supBasedPOId;
        return <div>{singlepoId && singlepoId !== "" ? singlepoId : poID}</div>;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (args) => {
        const date = new Date(args.PoCreatedDate);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Status",
      align: "center",
      width: "100%",
      render: (arg) => {
        const status = arg.poStatus;

        return (
          <div
            className={
              status === "pending"
                ? "badge badge-warning gap-2 p-4 text-md font-bold text-white"
                : "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {arg.poStatus.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Operations",
      align: "center",
      width: "100%",
      render: (arg) => poactionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="p-2 gap-2 items-center">
            {supplierData && supplierData.length > 0 ? (
              <div className="flex flex-col items-start justify-between">
                <div className="text-xl font-medium py-2">
                  Purchsing Order Content
                </div>
                <Table
                  columns={columnsPO}
                  dataSource={supplierData}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-xl font-medium">
                No data Available
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default SupplierPoData;
