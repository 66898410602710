import React, { useState, useEffect, useRef, useCallback } from "react";
import apiService from "../../../../http";
import { toast } from "react-toastify";
import { Select, Button } from "../../../../components/ui";

function ContractorModal({ handleClose, inquiry, visible }) {
  const inputDataStructure = {
    contractor: {
      key: "contractor",
      label: "Contractor",
      optTitle: "a contractor",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const materials = await apiService.get("contractor");

    const matOptList = materials.map((contractor) => {
      return {
        id: contractor.id,
        title: `${contractor.contractorId} - ${contractor.title}`,
      };
    });

    input_list.contractor.optList = matOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      contractorId: inputs.contractor.data,
      id: inquiry.id,
    };

    try {
      setIsLoading(true);
      await apiService.patch("inquiry/contractors", data);
      toast.success("Contractor updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      toast.error(e);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Add Contractor</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="flex flex-col">
          <div className="mt-2 mx-4">
            <Select input={inputs.contractor} handleChange={handleChange} />
          </div>

          <div className="flex px-4 w-full">
            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Save"}
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default ContractorModal;
