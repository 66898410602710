import React, { useCallback, useEffect, useState } from "react";
import apiService from "../../../../http";
import LoadingAnimation from "../../../../components/Loader";
import { Table } from "antd";
import { useParams } from "react-router-dom";

function Joboutstanding() {
  const { id } = useParams();

  const [jobOutstandingData, setJobOutstandingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(
        `inquiry/viewoutstandingbysupplier/${id}`
      );
      setJobOutstandingData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columnsPO = [
    {
      title: "ID",
      key: "inquiryId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
      render: (args) => {
        const poID = args.inquiryId;
        return <div>{poID}</div>;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (args) => {
        const date = new Date(args.createdAt);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Status",
      align: "center",
      width: "10%",
      render: (arg) => {
        const status = arg.inqueryStatus;

        return (
          <div
            className={
              status === "pending"
                ? "badge badge-warning gap-2 p-4 text-md font-bold text-white"
                : "badge badge-success gap-2 p-4 text-md font-bold text-white"
            }
          >
            {arg.inqueryStatus.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Total Amount(LKR)",
      align: "center",
      width: "20%",
      render: (arg) => {
        const payData = arg.quotationAmount;

        var totalValue = "";

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        if (payData) {
          const totalAmount = payData;
          totalValue = totalAmount;
        }

        return (
          <div
            className={
              totalValue !== ""
                ? "badge badge-success gap-2 p-4 text-md font-bold text-white"
                : "badge badge-ghost gap-2 p-4 text-md font-bold text-white"
            }
          >
            {formatter.format(totalValue)}
          </div>
        );
      },
    },
    {
      title: "Outstanding Amount",
      align: "center",
      width: "20%",
      render: (arg) => {
        // const poPaymentArray = arg.payData;
        const quotAmount = arg.quotationAmount;
        const confAmount = arg.paidAmount;

        const apaid = arg.advancedPaidAmount;
        const aestpaid = arg.advancedestimatedChargesPaidAmount;
        const aclearpaid = arg.advancedClearancePaidAmount;
        const acebpaid = arg.advancedClearancePaidAmount;

        const totalaPaid = apaid + aestpaid + aclearpaid + acebpaid;

        const totalPayable = quotAmount + confAmount - totalaPaid;

        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "LKR",

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return (
          <div
            className={
              "badge badge-error gap-2 p-4 text-md font-bold text-white"
            }
          >
            {formatter.format(totalPayable)}
          </div>
        );
      },
    },
  ];

  const tBody = jobOutstandingData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="p-2 gap-2 items-center">
            {jobOutstandingData && jobOutstandingData.length > 0 ? (
              <div className="flex flex-col items-start justify-between">
                <div className="text-xl font-medium py-2">
                  Job Outstanding Details By Customer
                </div>
                <Table
                  columns={columnsPO}
                  dataSource={tBody}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-xl font-medium">
                No data Available
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default Joboutstanding;
