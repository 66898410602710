import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

import HomeSection from "./Home";

import Materials from "./Materials";
import Contractors from "./Contractors";
import Suppliers from "./Suppliers";
import Supervisors from "./Supervisors";
import Inquiries from "./Inquiries";
import Inquiry from "./Inquiry";
import Jobs from "./Jobs";
import Pos from "./Pos";
import Sales from "./Sales";
import Payments from "./Payments";
import Customers from "./Customers";
import ExpenseTypes from "./ExpenseTypes";
import Expenses from "./Expenses";
import ExpenceData from "./Expenses/expenceData";
import OtherJobs from "./Otherjobs";
import OtherJobsData from "./Otherjobs/otherJobData";
import Footer from "../../components/Footer/Footer";
import Users from "./users";
import Reports from "./Reports";
import Maintance from "./maintance";
import MaintanceData from "./maintance/maintanceData";
import SupplierPoData from "./Suppliers/supplierPOData/SupplierPoData";
import Outstanding from "./outstanding";
import Joboutstanding from "./Customers/outstandings/Joboutstanding";
import OtherJobOutstanding from "./Customers/outstandings/OtherJobOutstanding";

const Dashboard = ({ section }) => {
  return (
    <div className="flex flex-col overflow-hidden h-screen">
      <NavBar section={section} />
      <div className="flex flex-1 overflow-hidden w-screen">
        <SideBar section={section} />

        <div className="w-full flex flex-1 overflow-y-auto gap-2 bg-white">
          {section === "home" && <HomeSection />}
          {section === "materials" && <Materials />}
          {section === "contractors" && <Contractors />}
          {section === "customers" && <Customers />}
          {section === "suppliers" && <Suppliers />}
          {section === "supplierData" && <SupplierPoData />}
          {section === "supervisors" && <Supervisors />}
          {section === "expense-types" && <ExpenseTypes />}
          {section === "outstanding" && <Outstanding />}
          {section === "joboutstanding" && <Joboutstanding />}
          {section === "otherjoboutstanding" && <OtherJobOutstanding />}
          {section === "expenses" && <Expenses />}
          {section === "expencedata" && <ExpenceData />}
          {section === "inquiries" && <Inquiries />}
          {section === "inquiry" && <Inquiry />}
          {section === "otherJobs" && <OtherJobs />}
          {section === "otherJobsData" && <OtherJobsData />}
          {section === "jobs" && <Jobs />}
          {section === "payments" && <Payments />}
          {section === "pos" && <Pos />}
          {section === "sales" && <Sales />}
          {section === "users" && <Users />}
          {section === "reports" && <Reports />}
          {section === "maintance" && <Maintance />}
          {section === "maintanceData" && <MaintanceData />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
