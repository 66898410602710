import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../http";

import { toast } from "react-toastify";
import { Button, Input, Select } from "../../../components/ui";
import { Table } from "antd";

import { IoTrashBin } from "react-icons/io5";

function PurchsingOrderModal({ handleClose, visible }) {
  const inputDataStructure = {
    material: {
      key: "material",
      label: "Material",
      optTitle: "a material",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      data: "",
      type: "number",
      inputmode: "numeric",
      error: null,
    },
    issuedDate: {
      key: "issuedDate",
      label: "Created Date",
      data: "",
      type: "date",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [clear, setClear] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleClearData = () => {
    inputs.material.data = "";
    inputs.material.placeHolder = "";
    inputs.quantity.data = "";

    handleChange(inputs.material);
    setClear(!clear);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    const materials = await apiService.get("material");

    setMaterials(materials);

    const matOptList = materials.map((material) => {
      return {
        id: material.id,
        title: `${material.materialId} - ${material.title}`,
      };
    });

    input_list.material.optList = matOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddData = () => {
    try {
      let materialAdd = inputs.material.data;
      let quantityAdd = inputs.quantity.data;

      if (materialAdd !== "" || quantityAdd !== "") {
        const data = {
          material: materialAdd,
          quantity: quantityAdd,
        };

        if (filterData != null) {
          // material is already added, only increase quantity
          // else add with the rest

          const alreadyAvailableData = filterData.find(
            (materialData) => materialData.material === data.material
          );

          if (alreadyAvailableData) {
            const newQty =
              Number(alreadyAvailableData.quantity) + Number(data.quantity);
            alreadyAvailableData.quantity = newQty;
            setFilterData((prv) => [...prv]);
          } else {
            setFilterData((prv) => [...prv, data]);
          }
        } else {
          setFilterData([data]);
        }

        // clear inputs now
        handleClearData();
      } else {
        throw Error("type Error");
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleName = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material.title;
  };
  const handleUnit = (id) => {
    let material = materials.find((elem) => elem.id === id);
    return material.unit;
  };

  const handleAction = (data) => {
    const handleDelete = () => {
      const updatedFilterData = filterData.filter(
        (item) => item.material !== data.material
      );
      setFilterData(updatedFilterData);
    };

    return (
      <button className="btn btn-ghost" onClick={handleDelete}>
        <IoTrashBin className="text-red-700" />
      </button>
    );
  };

  const columns = [
    {
      title: "Material",
      key: "material",
      width: "100%",
      render: (arg) => <p>{handleName(arg?.material)}</p>,
    },
    {
      title: "Unit",
      key: "material",
      width: "100%",
      render: (arg) => <p>{handleUnit(arg?.material)}</p>,
    },
    {
      title: "Quantity",
      key: "quantity",
      width: "100%",
      render: (arg) => <p>{arg?.quantity}</p>,
    },
    {
      title: "",
      key: "action",
      render: (arg) => handleAction(arg),
    },
  ];

  const tBody = filterData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = {
      poDetailArray: filterData,
      issuedDate: inputs.issuedDate.data,
    };

    try {
      setIsLoading(true);
      await apiService.patch("purchsingOrder", data);
      toast.success("Materials updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };
  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all  mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Create Purchsing Order</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="flex flex-col">
          <div className="mt-2 mx-4">
            <Input input={inputs.issuedDate} handleChange={handleChange} />

            <Select clear={clear} input={inputs.material} handleChange={handleChange} />

            <Input input={inputs.quantity} handleChange={handleChange} />

            <Button
              handleClick={handleAddData}
              className="mt-6"
              text={"Import"}
            />
          </div>
          <br />
          <div className="w-full p-6">
            <Table
              columns={columns}
              dataSource={tBody}
              style={{ width: "100%" }}
            />
          </div>
          <div className="flex items-end w-full">
            <Button
              handleClick={handleSubmit}
              className="mt-6 w-full"
              text={"Save"}
              type="submit"
              isLoading={isLoading}
              disabled={handleDisabled}
            />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default PurchsingOrderModal;
