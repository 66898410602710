import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import SupervisorModal from "./SupervisorModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import LoadingAnimation from "../../../components/Loader";

const inputDataStructure = {
  searchString: {
    key: "searchString",
    label: "",
    data: "",
    type: "text",
    placeholder: "Search an item",
    error: null,
  },
};
const Supervisors = () => {
  const [supervisors, setSupervisors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataModalState, setDataModalState] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (supervisor) => {
    setSelectedSupervisor(supervisor);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("supervisor/" + selectedSupervisor.id);
      setConfirmModalState(false);
      refreshSupervisors();
      setSelectedSupervisor(null);
      toast.success("Supervisor deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshSupervisors();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedSupervisor(null);
  };

  const handleChange = useCallback(
    (input) => {
      let input_list = { ...inputs };
      input_list[input.key] = input;

      if (input.data === "") {
        setFilteredData(supervisors);
      } else {
        const data = supervisors.filter((item) => {
          const lowerCaseTitle = item.title.toLowerCase();
          const lowerCaseInputData = input.data.toLowerCase();
          return lowerCaseTitle.includes(lowerCaseInputData);
        });

        setFilteredData(data);
      }

      setInputs(input_list);
    },
    [inputs, supervisors]
  );

  const refreshSupervisors = async () => {
    try {
      setIsLoading(true);
      const supervisors = await apiService.get("supervisor");
      setSupervisors(supervisors);
      setFilteredData(supervisors);
      setIsLoading(false);
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshSupervisors();
  }, []);

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`supervisor/${item.id}`);
            refreshSupervisors();
          } catch (e) {
            console.log(e);
          }
        }}
        className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (supervisor) => {
    return (
      <div className="flex">
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupervisor(supervisor);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupervisor(supervisor);
            handleEdit(supervisor);
          }}
        />
      </div>
    );
  };

  //   const specifications = (specs) => {
  //     const classes =
  //       "text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg";

  //     return (
  //       <div className="flex flex-col gap-2 items-start">
  //         {specs.thickness && (
  //           <p className={classes}>Thickness: {specs.thickness} mm</p>
  //         )}
  //         {specs.length && <p className={classes}>Length: {specs.length} ft</p>}
  //         {specs.width && <p className={classes}>Width: {specs.width} ft</p>}
  //         {specs.diameter && (
  //           <p className={classes}>Diameter: {specs.diameter} in</p>
  //         )}
  //         {specs.weight && <p className={classes}>Weight: {specs.weight} kg</p>}
  //       </div>
  //     );
  //   };
  //   const stockCount = (supervisor) => {
  //     if (supervisor.priceCalcMethod === "weight-based") {
  //       return supervisor.stock + " kg";
  //     } else if (supervisor.priceCalcMethod === "quantity-based") {
  //       return supervisor.stock;
  //     } else if (supervisor.priceCalcMethod === "length-based") {
  //       const lengthPerQuantity = supervisor.specifications.length;
  //       const fullCount = Math.floor(supervisor.stock / lengthPerQuantity);
  //       const remainingCount = supervisor.stock % lengthPerQuantity;

  //       return (
  //         <div className="flex flex-col items-start">
  //           <p>{"Full : " + fullCount}</p>
  //           <p>{"Remaining : " + remainingCount + " ft"}</p>
  //         </div>
  //       );
  //     }
  //   };

  const columns = [
    {
      title: "ID",
      dataIndex: "supervisorId",
      key: "supervisorId",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.supervisorId.localeCompare(b.supervisorId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      width: "20%",
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "20%",
    //     dataIndex: "remarks",
    //     key: "remarks",
    // },
    {
      title: "Phone Number",
      align: "center",
      width: "20%",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      align: "center",
      width: "20%",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <SupervisorModal
          selectedSupervisor={selectedSupervisor}
          handleClose={handleCloseModal}
          visible={dataModalState}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"Add New Supervisor"}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
      )}
    </section>
  );
};

export default Supervisors;
