import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import { format } from "date-fns";

import apiService from "../../../../http";
import GeneralExpenceModel from "./popovermodels/generalExpenceModel";
import LaborChargesModel from "./popovermodels/laborChargesModel";
import SalaryDataModel from "./popovermodels/salaryDataModel";
import { Button } from "../../../../components/ui";
import { useSelector } from "react-redux";

function ExpenceData() {
  const { id } = useParams();

  const [generalExpenceModel, setGeneralExpenceModel] = useState(false);
  const [laborChargesModel, setLaborChargesModel] = useState(false);
  const [salaryExpenceModel, setSalaryExpenceModel] = useState(false);

  const [expenceData, setExpenceData] = useState(null);
  const [expenceTypes, setExpenceTypes] = useState(null);

  const user = useSelector((state) => state.user);

  const isSupervisor = user.data.role === "supervisor";
  const isOffice = user.data.role === "office";

  const handleClose = () => {
    setGeneralExpenceModel(false);
    setLaborChargesModel(false);
    setSalaryExpenceModel(false);
    getData();
  };

  const getData = async () => {
    try {
      const data = await apiService.get(`expence/${id}`);
      const typesData = await apiService.get("expense-type");

      setExpenceData(data);
      setExpenceTypes(typesData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewBtns = (args) => {
    const dataArray = args?.expenceAttachments;
    const data = dataArray[0];

    return (
      <div className="flex items-center">
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() =>
            window.open(
              `${process.env.REACT_APP_BACKEND_URL}public/expence/${data?.file}`
            )
          }
        />
      </div>
    );
  };

  const status = (status) => {
    const selectedType = expenceTypes?.find((item) => {
      return item.expenseTypeId === status.expenceType;
    });

    return (
      <span className="badge bg-green-500 p-4 textarea-md text-white">
        {selectedType?.title}
      </span>
    );
  };

  const formatDate = (date) => {
    return format(new Date(date), "yyyy-MM-dd");
  };

  const generalExpenceColumns = [
    {
      title: "Expence Type",
      key: "expence_type",
      align: "center",
      width: "20%",
      render: (args) => status(args),
    },
    {
      title: "Expence Amount (LKR)",
      key: "expence_amount",
      dataIndex: "expenceAmount",
      align: "center",
      width: "20%",
    },
    {
      title: "Date Issued",
      key: "date_issued",
      dataIndex: "expenceDate",
      align: "center",
      width: "20%",
      render: (args) => formatDate(args),
    },
    {
      title: "View Attachment",
      key: "view Attachment",
      align: "center",
      width: "20%",
      render: (args) => viewBtns(args),
    },
  ];

  const LabourChargesColumns = [
    {
      title: "Inquery Id",
      key: "inqueryId",
      dataIndex: "InquiryNumber",
      align: "center",
      width: "10%",
    },
    {
      title: "Expence Amount (LKR)",
      key: "expence_amount",
      dataIndex: "expenceAmount",
      align: "center",
      width: "20%",
    },
    {
      title: "Date Issued",
      key: "expence_date",
      dataIndex: "expenceDate",
      align: "center",
      width: "20%",
      render: (args) => formatDate(args),
    },
    {
      title: "View Attachment",
      key: "view_details",
      align: "center",
      width: "20%",
      render: (args) => viewBtns(args),
    },
  ];
  const SalaryExpencesColumns = [
    {
      title: "EmpoyId Id",
      key: "inqueryId",
      dataIndex: "employeeId",
      align: "center",
      width: "10%",
    },
    {
      title: "Total Salary Received (LKR)",
      key: "total_salary",
      dataIndex: "totalSalary",
      align: "center",
      width: "20%",
    },
    {
      title: "Commisioned Amount (LKR)",
      key: "total_commisioned",
      dataIndex: "totalCommision",
      align: "center",
      width: "20%",
    },
    {
      title: "Total Deductions (LKR)",
      key: "total_deduct",
      dataIndex: "totalDeductions",
      align: "center",
      width: "20%",
    },
    {
      title: "Net Amount (LKR)",
      key: "net_amount",
      align: "center",
      width: "20%",
      render: (args) => {
        const total = Number.parseFloat(args.totalSalary);
        const commisioned = Number.parseFloat(args.totalCommision);
        const deductions = Number.parseFloat(args.totalDeductions);

        const netValue = Number.parseFloat(total + commisioned - deductions);
        return (
          <span className="badge bg-green-500 p-4 textarea-md text-white">
            {netValue}
          </span>
        );
      },
    },
    {
      title: "Date Issued",
      key: "expence_date",
      dataIndex: "updatedDate",
      align: "center",
      width: "20%",
      render: (args) => formatDate(args),
    },
  ];

  const generalExpenceData = expenceData && expenceData["generalExpences"];
  const labourChargesData = expenceData && expenceData["LabourExpences"];
  const salaryExpenceData = expenceData && expenceData["SalaryExpences"];

  const tBodyGeneral = generalExpenceData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tBodyLabour = labourChargesData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tBodySalary = salaryExpenceData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  if (!expenceData) return null;

  return (
    <section className="w-full mt-6 p-2">
      {generalExpenceModel && (
        <GeneralExpenceModel
          handleClose={handleClose}
          id={id}
          visible={generalExpenceModel}
        />
      )}
      {laborChargesModel && (
        <LaborChargesModel
          handleClose={handleClose}
          id={id}
          visible={laborChargesModel}
        />
      )}
      {salaryExpenceModel && (
        <SalaryDataModel
          handleClose={handleClose}
          id={id}
          visible={salaryExpenceModel}
        />
      )}

      <div className="flex flex-col gap-2 items-center">
        <div className="collapse collapse-arrow bg-base-200">
          <input type="radio" name="my-accordion-2" />
          <div className="collapse-title text-xl font-medium">
            General Expences
          </div>
          <div className="collapse-content overflow-x-auto">
            <div className="gap-2 flex-col items-start">
              <Button
                text={"Add General Expence"}
                disabled={!(isOffice || isSupervisor) ? false : true}
                handleClick={() => setGeneralExpenceModel(true)}
              />
              <div>
                {/* <Input
                  input={inputs.searchString}
                  handleChange={handleChange}
                /> */}
              </div>
              <Table
                columns={generalExpenceColumns}
                dataSource={tBodyGeneral}
                style={{ width: "80%" }}
              />
            </div>
          </div>
        </div>
        <div className="collapse collapse-arrow bg-base-200">
          <input type="radio" name="my-accordion-2" />
          <div className="collapse-title text-xl font-medium">
            Labour Charges Expences
          </div>
          <div className="collapse-content overflow-x-auto">
            <div className="gap-2 flex-col items-start">
              <Button
                text={"Add Labour Charges"}
                disabled={!(isOffice || isSupervisor) ? false : true}
                handleClick={() => setLaborChargesModel(true)}
              />
              <div>
                {/* <Input
                  input={inputs.searchString}
                  handleChange={handleChange}
                /> */}
              </div>
              <Table
                columns={LabourChargesColumns}
                dataSource={tBodyLabour}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="flex flex-col items-start p-6">
        <Button
          text={"Add Salary Expence"}
          disabled={!(isOffice || isSupervisor) ? false : true}
          handleClick={() => setSalaryExpenceModel(true)}
        />
        <Table
          columns={SalaryExpencesColumns}
          dataSource={tBodySalary}
          style={{ width: "100%" }}
        />
      </div>
    </section>
  );
}

export default ExpenceData;
