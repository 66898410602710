import React, { Fragment, useEffect, useRef, useState } from "react";
import apiService from "../../../../http";
import { useReactToPrint } from "react-to-print";
import LoadingAnimation from "../../../../components/Loader";
import Logo from "../../../../components/logo/Logo";

const inputDataStructure = {
  material: {
    key: "material",
    label: "Select Material",
    optTitle: "",
    data: "",
    optList: [],
    required: true,
    type: "text",
    error: null,
  },
};

const metadata = {
  ALL: "All",
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable",
  FILTERBYPO: "Filter by Purchsing Orders",
};

const FilteredOptions = ({ options, selectedOption, handleChange }) => {
  return (
    <div className="flex gap-2 items-center justify-center">
      {options.map((item, index) => (
        <div className="form-control" key={index}>
          <label className="label cursor-pointer gap-2">
            <span className="label-text">{item}</span>
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-primary"
              checked={selectedOption === item}
              onChange={() => handleChange(item)}
            />
          </label>
        </div>
      ))}
    </div>
  );
};

const radioBtnOptions = [
  metadata.ALL,
  metadata.AVAILABLE,
  metadata.UNAVAILABLE,
  metadata.FILTERBYPO,
];

const TableData = ({ dataSource }) => {
  const TableHeader = () => {
    return (
      <div className=" flex items-center justify-between gap-2">
        <h2 className="font-black text-black text-xl">
          IGRID HOLDINGS (PVT) LTD
        </h2>
        <div className="items-start font-bold">Stock Sheet</div>
      </div>
    );
  };

  const HandleTHeaderData = () => {
    return (
      <thead className="bg-slate-200 font-bold">
        <tr>
          <th>Id</th>
          <th>description</th>
          <th>Unit</th>
          <th>Quantity</th>
        </tr>
      </thead>
    );
  };

  const HandleTBodyData = () => {
    return (
      <tbody className="text-wrap items-center">
        {dataSource.map((itemObj) => {
          return (
            <Fragment key={itemObj.key}>
              <tr>
                <td>{itemObj.materialId}</td>
                <td>{itemObj.title}</td>
                <td>{itemObj.unit}</td>
                <td>{itemObj.stockCount}</td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    );
  };

  const HandleTableBodyData = () => {
    return (
      <div className="content">
        <table className="w-[700px]">
          <HandleTHeaderData />
          <HandleTBodyData />
        </table>
      </div>
    );
  };

  return (
    <table className="table w-[700px]">
      <thead>
        <tr>
          <th>
            <TableHeader />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <HandleTableBodyData />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
function StockReport() {
  const [inputs, setInputs] = useState(inputDataStructure);
  const [filteredData, setFilteredData] = useState([]);

  const [materials, setMaterials] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();

  const [filterType, setFilterType] = useState(metadata.ALL);

  const refreshInquiries = async () => {
    try {
      setIsLoading(true);
      const materialsData = await apiService.get("material");

      let input_list = { ...inputs };

      const matOptList = materialsData.map((mat) => {
        return {
          id: mat.materialId,
          title: `${mat.title}`,
        };
      });

      input_list.material.optList = matOptList;
      setInputs(input_list);
      setMaterials(materialsData);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshInquiries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handlePrintData = useReactToPrint({
    content: () => ref.current,
  });

  const handleViewData = () => {
    if (filterType === metadata.ALL) {
      setFilteredData(materials);
    }

    if (filterType === metadata.AVAILABLE) {
      const updatedMaterials = materials.filter(
        (mat) => parseFloat(mat.stockCount) > 0
      );

      setFilteredData(updatedMaterials);
    }

    if (filterType === metadata.UNAVAILABLE) {
      const updatedData = materials.filter(
        (mat) => parseFloat(mat.stockCount) <= 0
      );

      setFilteredData(updatedData);
    }
  };

  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  const HandleFilterOption = () => {
    return <div></div>;
  };

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="flex flex-col gap-4 w-full">
          <div className="rounded-md border-2 p-6 mx-2">
            <div className="flex flex-col items-start">
              <p className="font-bold text-lg">STOCK REPORT</p>
              <div className="mt-4">
                {/* radio buttons goes here */}
                <FilteredOptions
                  options={radioBtnOptions}
                  selectedOption={filterType}
                  handleChange={handleRadioClick}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                {/* product and supplier Selection Section */}

                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handlePrintData}
                  >
                    PRINT
                  </button>
                  <button
                    className="btn bg-primary text-white btn-wide"
                    onClick={handleViewData}
                  >
                    VIEW
                  </button>
                </div>

                <div className="flex gap-2">
                  <HandleFilterOption />
                </div>

                {/* Logo Component */}
                <div className="flex flex-col">
                  <Logo />
                </div>
              </div>
            </div>
          </div>

          <div
            ref={ref}
            className="rounded-md border-2 p-6 flex items-center justify-center flex-col w-full overflow-x-auto"
          >
            <TableData dataSource={tBody} />
          </div>
        </div>
      )}
    </section>
  );
}

export default StockReport;
