import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../../../http";
import LoadingAnimation from "../../../../components/Loader";
import { Table } from "antd";
import { Button } from "../../../../components/ui";

function MaintanceData() {
  const { id } = useParams();

  const [maintanceData, setMaintanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await apiService.get(`inquiry/${id}`);
      setIsLoading(false);
      setMaintanceData(data);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tBody = maintanceData?.maintance?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });
  const tBody2 = maintanceData?.maintanceExpences?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleViewDocuments = (data) => {
    const fileName = data.docData;

    const filePath = `${process.env.REACT_APP_BACKEND_URL}public/maintance/${fileName}`;

    return (
      <div>
        <Button
          text="View"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            window.open(filePath);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Visit Date",
      dataIndex: "visitDate",
      key: "visitDate",
      align: "center",
      width: "5%",
      render: (args) => {
        const date = new Date(args);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
    },
    {
      title: "Next Visit Date",
      dataIndex: "nextVisitDate",
      key: "nextVisitDate",
      align: "center",
      width: "10%",
      render: (args) => {
        const date = new Date(args);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "numeric",
        };
        return <div>{date.toLocaleString("en-US", options)}</div>;
      },
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "10%",
      // render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Document",
      key: "description",
      align: "center",
      width: "10%",
      render: (args) => handleViewDocuments(args),
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
  ];

  const columns2 = [
    {
      title: "Expacnce Type",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.inquiryId.localeCompare(b.inquiryId),
    },
    {
      title: "Amount (LKR)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "10%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "10%",
      // render: (args) => args.name,
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
  ];

  return (
    <section className="w-full mt-6">
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="px-2 py-4 gap-2 flex flex-col">
            <div className="collapse collapse-arrow bg-blue-200">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Job Maintance Details
              </div>
              <div className="collapse-content flex flex-col gap-2">
                <Table
                  columns={columns}
                  dataSource={tBody}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="collapse collapse-arrow bg-blue-200">
              <input type="radio" name="my-accordion-2" />
              <div className="collapse-title text-xl font-medium">
                Maintance Expences Details
              </div>
              <div className="collapse-content flex flex-col gap-2">
                <Table
                  columns={columns2}
                  dataSource={tBody2}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default MaintanceData;
