import React, { useCallback, useEffect, useRef, useState } from "react";
import apiService from "../../../../../../http";

import { toast } from "react-toastify";
import {
  Button,
  FileUpload,
  ImgUpload,
  Input,
} from "../../../../../../components/ui";
import { Table } from "antd";

import { IoTrashBin } from "react-icons/io5";

function WarrantyModel({ handleClose, otherJob, visible }) {
  const inputDataStructure = {
    timePeriod: {
      key: "timePeriod",
      label: "Warranty (Years)",
      data: "",
      type: "number",
      min: 0,
      error: null,
    },
    units: {
      key: "units",
      label: "units",
      data: "",
      type: "number",
      min: 0,
      error: null,
    },
    title: {
      key: "title",
      label: "Title",
      placeholder: "title",
      data: "",
      type: "text",
      error: null,
    },
    description: {
      key: "description",
      label: "Description",
      placeholder: "description",
      data: "",
      type: "text",
      error: null,
    },
    serialNumber: {
      key: "serialNumber",
      label: "Serial Number Doc",
      data: [],
      name: "serialNumber",
      error: null,
      required: true,
    },
    file: {
      key: "file",
      label: "Warranty File",
      data: [],
      name: "file",
      error: null,
      required: true,
    },
    issuedDate: {
      key: "issuedDate",
      label: "Issued Date",
      data: "",
      type: "date",
      error: null,
    },
  };

  const modalRef = useRef(null);

  const handleCloseModel = useCallback(() => {
    modalRef.current.close();
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    visible ? modalRef.current.showModal() : handleCloseModel();
  }, [handleCloseModel, visible]);

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const [clear, setClear] = useState(false);

  const [counter, setCounter] = useState(0);

  const [filteredData, setFilteredData] = useState(null);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleClearData = () => {
    inputs.timePeriod.data = "";
    inputs.units.data = "";
    inputs.description.data = "";
    inputs.title.data = "";

    setClear(!clear);
  };

  const handleAddData = () => {
    let timePeriod = inputs.timePeriod.data;
    let noOfUnits = inputs.units.data;
    let titleData = inputs.title.data;
    let descriptionData = inputs.description.data;

    if (
      timePeriod !== "" ||
      noOfUnits !== "" ||
      titleData !== "" ||
      descriptionData !== ""
    ) {
      const warrantyData = {
        count: counter,
        timePeriod: timePeriod,
        noOfUnits: noOfUnits,
        titleData: titleData,
        descriptionData: descriptionData,
      };

      if (filteredData != null) {
        setFilteredData((prv) => [...prv, warrantyData]);
      } else {
        setFilteredData([warrantyData]);
      }

      // clear inputs now
      handleClearData();
      setCounter((prv) => prv + 1);
    }
  };

  const handleAction = (data) => {
    const handleDelete = () => {
      const updatedFilterData = filteredData.filter(
        (item) => item.count !== data.count
      );
      setFilteredData(updatedFilterData);
    };

    return (
      <button className="btn btn-ghost" onClick={handleDelete}>
        <IoTrashBin className="text-red-700" />
      </button>
    );
  };

  const columns = [
    {
      title: "title",
      key: "title",
      width: "100%",
      render: (arg) => <p>{arg.titleData}</p>,
    },
    {
      title: "units",
      key: "units",
      width: "100%",
      render: (arg) => <p>{arg.noOfUnits}</p>,
    },
    {
      title: "Warranty(in Years)",
      key: "warranty",
      width: "100%",
      render: (arg) => <p>{arg.timePeriod}</p>,
    },
    {
      title: "",
      key: "action",
      render: (arg) => handleAction(arg),
    },
  ];

  const tBody = filteredData?.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const data = new FormData();

    data.append("id", otherJob._id);
    data.append("warrantyData", JSON.stringify(filteredData));
    data.append("issuedDate", inputs.issuedDate.data);

    if (inputs.file.data[0]) {
      data.append("file", inputs.file.data[0].data);
    } else {
      toast.error("Warranty file is required");
      return;
    }

    if (inputs.serialNumber.data[0]) {
      data.append("serialNumber", inputs.serialNumber.data[0].data);
    } else {
      toast.error("Serial file is required");
      return;
    }

    try {
      setIsLoading(true);
      await apiService.patch("other-jobs/warranty", data);
      toast.success("Warranty updated successfully!");
      setIsLoading(false);
      handleClose();
    } catch (e) {
      toast.error(e);
      setIsLoading(false);
    }
  };

  const handlethis = (e) => {
    e.preventDefault();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleCloseModel();
  };

  const handleDisabled = isLoading ? true : false;

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      id="my_modal_1"
      aria-labelledby="modal-title"
      ref={modalRef}
      onCancel={handleESC}
    >
      <div className="modal-box shadow-xl transition-all mx-auto  rounded-lg bg-white">
        <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
          <h3 className="mx-auto py-1">Warranty Period</h3>
          <span
            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
            onClick={handleClose}
          >
            X
          </span>
        </div>
        <div className="mt-2 mx-4">
          <form onSubmit={handlethis} encType="multipart/form-data">
            <div className="flex flex-col gap-2 items-center">
              <Input input={inputs.title} handleChange={handleChange} />
              <Input input={inputs.description} handleChange={handleChange} />
              <Input input={inputs.units} handleChange={handleChange} />
              <Input input={inputs.timePeriod} handleChange={handleChange} />
              <Button
                handleClick={handleAddData}
                className="mt-6"
                text={"Import"}
              />
            </div>
            <br />
            <div className="w-full p-6 rounded-md border-2">
              <Table
                columns={columns}
                dataSource={tBody}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <Input input={inputs.issuedDate} handleChange={handleChange} />
              <FileUpload
                input={inputs.serialNumber}
                handleChange={handleChange}
              />

              <ImgUpload input={inputs.file} handleChange={handleChange} />

              <Button
                handleClick={handleSubmit}
                className="mt-6"
                text={"Submit"}
                type="submit"
                isLoading={isLoading}
                disabled={handleDisabled}
              />
            </div>
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* <button onClick={handleClose}>close</button> */}
      </form>
    </dialog>
  );
}

export default WarrantyModel;
