import React from "react";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer footer-center p-2 bg-primary text-base-content">
      <aside>
        <p className="text-white font-bold">
          Copyright © {year} - Developed by{" "}
          <a href={"https://www.aldtan.com/"}>ALDTAN (PVT) Ltd</a>
        </p>
      </aside>
    </footer>
  );
}

export default Footer;
